.glowing-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10101010;
  @include flexCenter(column);

  .contents {
    @include flexCenter(column);
    width: 60%;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25),
      0px 0px 24px rgba(255, 255, 255, 0.25),
      0px 0px 36px rgba(255, 255, 255, 0.25),
      0px 0px 72px rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 5px;
    @media screen and (max-width: 1200px) {
      width: 80%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }

    // Animation
    @include _scaleVert();

    .close {
      width: 30px;
      height: 30px;
      align-self: flex-end;
      cursor: pointer;
      @include flexCenter(column);
      border-radius: 50%;
      transition: all 300ms ease-in-out;

      &:hover {
        background-color: #eee;
      }
    }

    input {
      @include inputs();
      border-bottom: 1px solid #bbb;
    }
    p {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
    }
    button,
    a {
      width: 100%;
      margin-top: 12px;
      @include buttons();
      background-color: $darkRed;
      color: #fff;
      padding: 12px;
      text-decoration: none;
    }
    .row-btns {
      @include flexCenter(row);
      width: 100%;

      button {
        margin: 6px;
        cursor: pointer;
        @include flexCenter(row);
        i {
          margin-left: 6px;
        }
      }
      .red {
        background-color: $err;
        border: 3px solid $err;
        &:hover {
          background-color: $errHover;
        }
      }
      .green {
        background-color: $success;
        border: 3px solid $success;
        &:hover {
          background-color: $successHover;
        }
      }
    }

    // Types of buttons
    .dry {
      background-color: transparent;
      border: 1px solid #bbb;
      color: #777;
      padding: 12px;
      border-radius: 5px;
      &:hover {
        color: #777;
        text-decoration: none;
        @include shadows();
      }
    }
  }
}
