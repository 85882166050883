.cashout-cont {
  margin-top: 20px;
  @include flexCenter(row);
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;

  @media screen and (max-width: 600px) {
    @include flexCenter(column);
    padding: 0px;
  }

  .cashout-card {
    background-color: #fff;
    @include cardShadow();
    @include flexCenter(column);
    justify-content: flex-start;
    width: 300px;
    margin: 4px;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 4px 0px 10px 0px;
    }

    .top-row {
      @include flexCenter(row);
      justify-content: flex-start;
      border-bottom: 1px solid #ddd;
      width: 100%;
      padding: 10px;
      p {
        flex: 1;
        color: #777;
      }
      .delete {
        @include flexCenter(row);
        background-color: $err;
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .bottom-row {
      padding: 10px;
      width: 100%;
      h1 {
        font-weight: 500;
        font-size: 36px;
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h2 {
        font-size: 16px;
        font-weight: 500;
        span {
          font-weight: bolder;
          color: $success;
        }
      }
    }
  }
}
