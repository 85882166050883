// Install
.install {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  @include flexCenter(column);
  z-index: 100;

  .add-button {
    font-size: 20px;
    text-decoration: none;
    color: #fff;
    background-color: $darkRed;
    padding: 12px 100px;
    border-radius: 5px;
    cursor: pointer;

    @media screen and (max-width: 400px) {
      padding: 12px 80px;
    }
    @media screen and (max-width: 280px) {
      padding: 12px 50px;
    }
  }

  button {
    color: #fff;
    font-size: 40px;
    margin-top: 20px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
