// Top nav for buttons
.btn-holder {
  @include mainWidth();
  border-bottom: 2px solid #ddd;
  @include flexCenter(row);
  align-items: flex-end;
  justify-content: flex-end;
  padding: 6px;
  position: relative;

  h2 {
    font-size: 20px;
    color: #777;
    flex: 1;
    justify-self: flex-start;
    align-self: flex-start;
    text-align: left;
    margin-right: 10px;
    width: 90%;
    padding: 5px;
  }

  button {
    margin-right: 5px;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    padding: 8px 10px;
    background-color: transparent;
    color: #777;
    font-size: 16px;
  }

  .delete-all {
    background-color: $err;
    color: #fff;
    margin-left: 5px;
    border: 1px solid #bbb;

    &:hover {
      background-color: $errHover;
    }
  }

  // Search popup
  @import "./search-popup";

  select {
    color: #fff;
    border: 1px solid #bbb;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    padding: 8px 10px;
    background-color: transparent;
    color: #777;
    &:hover {
      background-color: transparent;
    }
  }
}
