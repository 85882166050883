// Updating profile-pic
.img-upload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10101010;
  @include flexCenter(column);

  .contents {
    @include flexCenter(column);
    width: 60%;
    height: 80%;
    background-color: #fff;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25),
      0px 0px 24px rgba(255, 255, 255, 0.25),
      0px 0px 36px rgba(255, 255, 255, 0.25),
      0px 0px 72px rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 5px;
    @media screen and (max-width: 1200px) {
      width: 80%;
      height: 80%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      height: 80%;
    }
    @media screen and (max-height: 300px) {
      height: 100%;
    }

    .img-container {
      width: 500px;
      height: 500px;
      @include flexCenter(column);
      border-radius: 50%;
      position: relative;

      @media screen and (max-width: 1500px) {
        width: 380px;
        height: 380px;
      }
      @media screen and (max-width: 900px) {
        width: 300px;
        height: 300px;
      }
      @media screen and (max-width: 350px) {
        width: 200px;
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        filter: blur(0px);
      }
      .square-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        filter: blur(1px) invert(40%);
      }
    }

    .buttons {
      @include flexCenter(row);
      width: 100%;

      button {
        @include buttons();
        @include buttonHover();
        @include cardShadow();
        background-color: $darkRed;
        width: 50%;
        @include flexCenter(row);
        margin: 40px 6px 0px 6px;
        cursor: pointer;
      }
      .cancel {
        background-color: transparent;
        border: 1px solid #bbb;
        color: #777;

        &:hover {
          color: #777;
        }
      }
    }
  }
}
