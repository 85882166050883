.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 11111111111;
  animation: animate-links 0.3s linear 1;
  @keyframes animate-links {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @include flexCenter(row);

  .popup {
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.35);
    border-radius: 36px;
    width: 30%;
    height: fit-content;
    margin-top: 30px;
    margin-bottom: 40px;
    @include shadows();
    display: block;
    @media screen and (max-width: 1300px) {
      width: 40%;
    }
    @media screen and (max-width: 900px) {
      width: 60%;
    }
    @media screen and (max-width: 700px) {
      width: 70%;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
    }
    //   @media screen and (max-width: 480px) {
    //     width: 100%;
    //   }
    @media screen and (max-width: 260px) {
      padding: 15px;
    }

    overflow: auto;

    .err {
      color: $err;
    }
    .success {
      color: $success;
    }
    .warn {
      color: $warn;
    }
    h2 {
      font-size: 24px;
      font-weight: 500;
      border-bottom: 1px solid #ddd;
      text-align: center;
      @include flexCenter(row);
      padding-bottom: 10px;
    }
    p {
      height: 40px;
      color: #777;
      text-align: center;
      @include flexCenter(row);
      padding: 36px 0px;
      font-size: 18px;
    }
    .btn-err {
      @include button($err);
    }
    .btn-success {
      @include button($success);
    }
    .btn-warn {
      @include button($warn);
    }
    .btn-raw {
      @include button(#fff);
      border: 1px solid #ddd;
      color: #777;
    }
    .sec-btn {
      margin-top: 10px;
    }
    button {
      color: #fff;
      background-color: $darkRed;
      cursor: pointer;
      @include buttonHover();
      @include flexCenter(column);
      transition: all 300ms ease-in-out;
    }
  }
}
