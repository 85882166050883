.sub {
  width: 100%;
  background-color: $superDarkRed;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    padding: 10px;
  }

  p {
    font-size: 16px;
    padding: 0px 12px;
    color: #fff;
    @media screen and (max-width: 550px) {
      text-align: center;
    }
  }

  button {
    border: none;
    border-radius: 5px;
    background-color: $darkRed;
    padding: 12px 48px;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    cursor: pointer;

    @media screen and (max-width: 550px) {
      margin-top: 10px;
      text-align: center;
      padding: 10px 30px;
    }
  }
}
