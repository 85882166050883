// Install
@import "./install";

// Welcome
.welcome {
  position: relative;
  top: 0;
  width: 100%;
  min-height: 50vh;
  height: fit-content;
  background-color: $superLightRed;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  z-index: 99;
  @media screen and (max-width: 1000px) {
    background-size: 100% 100%;
  }
  // @media screen and (max-width: 800px) {
  //   min-height: 40vh;
  // }
  @media screen and (min-height: 800px) {
    background-size: 100% 100%;
  }
  @media screen and (max-width: 570px) {
    min-height: 0;
  }

  .imgs {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    height: 50%;

    @media screen and (max-width: 800px) {
      height: 30%;
    }
    @media screen and (max-width: 570px) {
      height: 150px;
    }

    img {
      position: relative;
      left: 0;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 570px) {
        background-size: cover;
      }
    }

    // img {
    //   position: relative;
    //   left: 0;
    //   float: left;
    //   width: 25%;
    //   height: 100%;
    //   animation: slide 20s alternate infinite;
    // }

    // @keyframes slide {
    //   0% {
    //     left: 0;
    //   }
    //   20% {
    //     left: 0;
    //   }
    //   25% {
    //     left: -25%;
    //   }
    //   45% {
    //     left: -25%;
    //   }
    //   50% {
    //     left: -50%;
    //   }
    //   70% {
    //     left: -50%;
    //   }
    //   75% {
    //     left: -75%;
    //   }
    //   // 95% {
    //   //   left: -75%;
    //   // }
    //   100% {
    //     left: -75%;
    //   }
    // }
  }

  .user-location{
    @include flexCenter(row);
    padding: 10px;
    width: 100%;
    z-index: 1000000;
    margin-top: -112px;

    .glass-container{
      background: rgba(255, 255, 255, 0.15); /* translucent white background */
      backdrop-filter: blur(3px); /* backdrop filter for blur effect */
      border-radius: 10px; /* rounded corners */
      padding: 20px; /* padding around the content */
      width: 80%; /* set your preferred width */
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1); /* box shadow for depth */

      @media screen and (max-width: 980px) {
        width: 100%;
      }

      p{
        color: #fff;
      }
      .search-container{
        display: flex;
        background-color: transparent;
        position: relative;

        input{
          @include inputs();
          padding: 12px 12px 12px 36px;
          border-radius: 6px;

          @media screen and (max-width: 350px) {
            padding-left: 30px;
          }
        }
        .search-icon{
          position: absolute;
          z-index: 1010;
          margin-top: 24px;
          margin-left: 10px;
          color: #bbb;
          font-size: 16px;

          @media screen and (max-width: 350px) {
            font-size: 13px;
          }
          // left: 0;
          // top: -10px;
        }
      }
    }
  }

  // Spinner
  @import "../assets/in-spinner";

  .link-to-shops {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    height: 88%;
  }

  .btn-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent;
    @include flexCenter(row);

    span {
      width: 15px;
      height: 15px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.557);
      margin: 10px;
      cursor: pointer;
    }
  }

  .shopping-process-container{
    position: relative;
    bottom: 0;
    height: 50%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 450px;
    padding: 0 20px 0 20px;

    
    @media screen and (max-width: 1570px) {
      width: 90%;
    }
    @media screen and (max-width: 1280px) {
      grid-template-rows: 350px;
    }
    @media screen and (max-width: 1500px) {
      width: 100%;
    }
    @media screen and (max-width: 980px) {
      grid-template-rows: 220px;
    }
    @media screen and (max-width: 570px) {
      display: none;
    }

    .shopping-process-card{
      background-color: #fff;
      @include cardShadow();
      padding: 40px;
      border-radius: 12px;
      margin-right: 30px;
      margin-top: 40px;
      height: 70%;
      @include flexCenter(column);
      justify-content: flex-start;
      align-items: flex-start;
      // width: 33%;

      &:last-of-type{
        margin-right: 0;
      }

      @media screen and (max-width: 980px) {
        padding: 15px;
        margin-top: 20px;
        margin-right: 15px;
      }

      .step{
        font-size: 60px;
        margin-bottom: 20px;
        font-weight: bolder;
        color: $darkRed;

        @media screen and (max-width: 1280px) {
          font-size: 40px;
        }
        @media screen and (max-width: 980px) {
          font-size: 24px;
        }
        @media screen and (max-width: 650px) {
          font-size: 16px;
        }
      }

      .desc{
        font-size: 30px;
        font-weight: 600;
        flex: 1;
        color: $superDarkRed;

        @media screen and (max-width: 1280px) {
          font-size: 20px;
        }
        @media screen and (max-width: 980px) {
          font-size: 14px;
          font-weight: 500;
        }
        @media screen and (max-width: 650px) {
          font-weight: 400;
        }
      }

      .line{
        width: 30px;
        height: 2px;
        background-color: $darkRed;
        margin-top: 20px;

        @media screen and (max-width: 980px) {
          margin-top: 0px;
        }
      }
    }
  }
}

// Home page content container on scroll
.content-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    @media screen and (max-width: 1570px) {
      width: 90%;
    }
    @media screen and (max-width: 1500px) {
      width: 100%;
    }

    // Top products
    @import "./top-products";

    // Product categories
    @import "./product-categories";

    // Top shops
    @import "./top-shops";

    // Pricing
    @import "./pricing";
  }
}
