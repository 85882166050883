.form-popup-container {
  position: fixed;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  background-size: cover;
  display: none;
  z-index: 100000000;
  height: 100%;
  animation: animate-links 0.3s linear 1;
  overflow: auto;
  @keyframes animate-links {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .form {
    position: absolute;
    @include flexCenter(column);
    width: 100%;
    height: auto;
    animation: animateHamMenu 0.3s linear 1;
    transform-origin: left;

    // Form section
    form {
      @include flexCenter(column);
      overflow: auto;
      width: 30%;
      @include shadows();
      background-color: #fff;
      padding: 10px 15px;

      @media screen and (max-width: 1300px) {
        width: 40%;
      }
      @media screen and (max-width: 900px) {
        width: 60%;
      }
      @media screen and (max-width: 700px) {
        width: 70%;
      }
      @media screen and (max-width: 600px) {
        width: 90%;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
        border-radius: 0px;
      }

      .heading {
        @include flexCenter(column);

        h3 {
          font-size: 24px;
          font-weight: 520;
          margin: 18px 0px 0px 0px;
          @media screen and (max-width: 720px) {
            font-size: 21px;
          }
        }
      }

      // Banner Messages
      @import "../../assets/msg";

      .day-card {
        width: 100%;
        .inp {
          width: 100%;
          input {
            @include inputs();
          }
          textarea {
            @include inputs();
            border: 3px solid $darkRed;
            resize: none;
            height: 100px;
          }
          select {
            @include inputs();
          }
          label {
            i {
              margin-right: 8px;
            }
          }
        }
      }

      button {
        @include buttons();
        color: #fff;
        background-color: $darkRed;
        cursor: pointer;
        @include buttonHover();
        @include flexCenter(column);
        transition: all 300ms ease-in-out;
      }
      // Button animation on submit
      .btn-animate {
        background-color: #ddd;
        color: #bbb;
        animation: animate 1.25s alternate infinite;
        border: 3px solid #ddd;
        pointer-events: none;

        @keyframes animate {
          0% {
            border: 3px solid #ccc;
            background-color: #ccc;
            color: #aaa;
          }
          100% {
            border: 3px solid #ddd;
            background-color: #ddd;
          }
        }
      }

      p {
        width: 100%;
        font-size: 13px;
        text-align: center;
        transition: all 120ms ease-in-out;
        font-weight: bold;
        @media screen and (max-width: 350px) {
          font-size: 11px;
        }
        padding: 5px;
      }
      #err {
        background-color: $errLight;
      }

      .cancel {
        width: 100%;
        background-color: transparent;
        border: 1px solid #bbb;
        color: #777;
        padding: 12px;
        border-radius: 5px;
        &:hover {
          color: #777;
          text-decoration: none;
        }
        margin-bottom: 20px;
      }
    }
  }
}
