// Top shops
.top-shops {
  width: 100%;
  padding-top: 40px;
  @media screen and (min-width: 800px) {
    padding-top: 120px;
  }
  background-color: transparent;
  display: flex;
  flex-direction: column;

  .heading {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    span {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 0px);
      width: 120px;
      background-color: $lightRed;
      height: 10px;
    }
    h1 {
      font-size: 60px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 1300px) {
        font-size: 48px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 40px;
        text-align: center;
      }

      @media screen and (max-width: 650px) {
        font-size: 30px;
      }
      @media screen and (max-width: 260px) {
        font-size: 20px;
      }
    }
  }

  .slide-msg {
    margin-top: 20px;
    color: #bbb;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    i {
      margin: auto 10px;
    }
  }

  .embla {
    width: 60%;
    align-self: center;
    @media screen and (max-width: 900px) {
      width: 80%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .shops-card-container {
    margin-top: 40px;
    padding: 20px;
    @media screen and (min-width: 800px) {
      margin-top: 60px;
    }
    @media screen and (max-width: 800px) {
      &::-webkit-scrollbar {
        display: none;
      }
      padding: 0px;
    }

    // Shop card
    @import "../assets/shop-card";
  }
}
