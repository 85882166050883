footer {
  position: relative;
  width: 100%;
  padding: 0px 8px;
  background: #181210;
  @include flexCenter(column);
  z-index: 99;
  // margin-top: 60px;
  z-index: 100;

  .footer-card-container {
    @include flexCenter(row);
    width: 100%;
    margin-top: 60px;
    @media screen and (max-width: 630px) {
      flex-direction: column;
    }

    .footer-card {
      width: 40%;
      background-color: transparent;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      @media screen and (max-width: 630px) {
        margin-top: 30px;
      }

      h2 {
        color: #ddd;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
      }

      a.button {
        margin-top: 20px;
        margin-bottom: 20px;
        @include links();
        @include buttonHover();
        padding: 12px 40px;
        text-align: center;
        background-color: $darkRed;
        color: #fff;
        font-size: 16px;

        @media screen and (max-width: 900px) {
          padding: 12px 36px;
        }

        @include cardShadow();
      }

      a.link {
        text-decoration: none;
        font-size: 16px;
        color: #bbb;
        margin-top: 10px;
        transition: all 300ms ease-in-out;
        text-align: center;

        &:hover {
          color: $darkRed;
        }

        i {
          font-size: 20px;
          margin-right: 6px;
        }
      }
    }
  }

  .copyright {
    margin-top: 60px;
    font-size: 16px;
    color: #ddd;
    text-align: center;
    @media screen and (max-width: 500px) {
      font-size: 13px;
    }
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;
    padding: 10px;

    .logos {
      width: 100%;
      @include flexCenter(row);

      img {
        width: 60px;
        height: 30px;
        margin: 6px;

        @media screen and (max-width: 400px) {
          width: 40px;
          height: 20px;
        }

        &:nth-child(1) {
          width: 90px;
          height: 40px;
          @media screen and (max-width: 400px) {
            height: 30px;
          }
        }
      }
    }
  }
  .raise {
    @media screen and (max-width: 900px) {
      margin-bottom: 40px;
    }
  }
}

.in-app {
  padding: 0px 8px 60px 8px;
}
