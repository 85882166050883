.error {
  @include flexCenter(column);
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 10px;
  min-height: 94vh;
  width: 100%;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }

  .error-container {
    // Image
    .img {
      width: 100%;
      @include flexCenter(row);

      img {
        @media screen and (max-height: 700px) {
          height: 240px;
        }
      }
    }

    h2 {
      font-size: 6em;
      letter-spacing: 10px;
      text-align: center;
      @media screen and (max-width: 900px) {
        font-size: 4em;
      }
      @media screen and (max-width: 600px) {
        font-size: 3em;
      }
      @media screen and (max-width: 300px) {
        font-size: 2em;
      }
    }
    p {
      margin-top: 10px;
      font-size: 24px;
      color: #777;
      text-align: center;

      span {
        font-size: 16px;
      }
    }
    a {
      text-decoration: none;
      color: #fff;
      background-color: $darkRed;
      padding: 10px 24px;
      border-radius: 5px;
      @include flexCenter(column);
      @include cardShadow();
      margin-top: 10px;

      &:last-of-type {
        margin-bottom: 50px;
      }
    }
  }
}
