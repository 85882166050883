.about {
  @include flexCenter(column);
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 15px;
  min-height: 94vh;
  width: 100%;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
    margin-top: 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }

  .about-container {
    width: 60%;
    height: 100%;
    @include flexCenter(column);
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    @media screen and (max-width: 1500px) {
      width: 70%;
    }
    @media screen and (max-width: 900px) {
      width: 85%;
    }
    @media screen and (max-width: 800px) {
      width: 90%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }

    .about-app {
      align-self: center;
      @include flexCenter(column);

      img {
        width: 200px;
        height: 200px;
        @media screen and (max-width: 280px) {
          width: 160px;
          height: 160px;
        }
      }
      h1 {
        font-size: 3em;
        text-align: center;
        margin-top: 20px;
        @media screen and (max-width: 600px) {
          font-size: 30px;
        }
      }
      p {
        margin-top: 10px;
        text-align: center;
        span {
          color: #777;
        }
        font-size: 24px;
        @media screen and (max-width: 600px) {
          font-size: 20px;
        }
      }
    }

    // More info
    .more-info {
      width: 100%;
      padding: 10px;
      @include flexCenter(column);
      align-items: flex-start;
      margin-top: 10px;
      background-color: transparent;
      @media screen and (max-width: 600px) {
        padding: 0px;
      }
      overflow: hidden;

      .title {
        color: #777;
        margin-top: 10px;
      }
      span {
        color: #777;
        margin-top: 10px;
      }
      h2 {
        display: flex;
        background-color: $darkRed;
        padding: 6px;
        width: 100%;
        height: fit-content;
        cursor: pointer;

        p {
          flex: 1;
          color: #fff;
          font-size: 24px;
          @media screen and (max-width: 600px) {
            font-size: 20px;
          }
          pointer-events: none;
        }
        i {
          color: #fff;
          font-size: 24px;
          @media screen and (max-width: 600px) {
            font-size: 20px;
          }
          pointer-events: none;
        }
      }
      .hide {
        background-color: #fff;
        animation: scale 0.3s linear 1;
        padding: 5px;
        width: 100%;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
        transform-origin: top;

        @keyframes scale {
          0% {
            transform: scaleY(0);
          }
          100% {
            transform: scaleY(1);
          }
        }
      }

      .founder {
        width: 100%;
        height: 100%;
        @include flexCenter(column);

        .img {
          width: 150px;
          height: 150px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        p {
          text-align: center;
        }
      }
      p {
        font-size: 18px;
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      a {
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $darkRed;
      }
    }
  }
}
