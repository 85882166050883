.prod-card-container {
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;

  @media screen and (max-width: 400px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 350px) {
    grid-template-columns: repeat(1, 1fr);
  }

  // Product card
  .card {
    position: relative;
    border-radius: 5px;
    text-decoration: none;
    margin: 10px;
    background-color: #fff;
    // @include cardShadow();
    overflow: hidden;
    // box-shadow: 0px 1px 18px rgba(124, 107, 101, 0.25);

    @media screen and (max-width: 351px) {
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      margin: 5px;
      padding: 0px;
      box-shadow: none;
      border: 1px solid #ddd;
    }
    @media screen and (max-width: 500px) {
      margin: 0px;
      box-shadow: none;
      border-radius: 0px;
      border: 1px solid #ddd;
      &:hover {
        box-shadow: none;
      }
    }

    // Product image
    .prod-img {
      height: 350px;
      width: 100%;
      background-color: #ddd;
      @include flexCenter(row);
      border-radius: 5px 5px 0px 0px;
      @media screen and (max-width: 1200px) {
        height: 300px;
      }
      @media screen and (max-width: 500px) {
        height: 220px;
        border-radius: 0px;
      }

      @media screen and (max-width: 350px) {
        height: 300px;
      }
      @media screen and (max-width: 270px) {
        height: 200px;
      }

      img {
        border-radius: 5px 5px 0px 0px;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 500px) {
          border-radius: 0px;
        }
      }
      p {
        color: #777;
      }
    }

    // The product loader Animation
    @import "./prod-loader";

    // Product info
    .prod-info {
      width: 100%;
      background-color: transparent;
      padding: 10px;
      margin-top: 0px;

      p {
        color: #333;
        display: -webkit-box;
        max-height: calc(2 * 1.6rem);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        font-weight: bold;
        letter-spacing: normal;
        font-size: 18px;
        line-height: 1.4rem;
        max-height: 4.4rem;
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }

      // Product name
      .prod-name {
        height: 48px;
      }

      // Shop name
      .shop-name {
        color: #777;
        font-size: 14px;
        font-weight: normal;
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;

        span {
          font-style: italic;
        }

        // Verified badge
        .verified {
          color: #fff;
          background: $darkRed;
          padding: 2px;
          border-radius: 50%;
          font-size: 8px;
          margin-left: 2px;
          position: absolute;
          top: 0;
        }
      }
      .price-likes {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 16px;
        h3 {
          flex: 1;
          font-size: 18px;
          font-weight: bolder;
          color: $darkRed;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 600px) {
            font-size: 16px;
          }
        }
        p.likes {
          position: relative;
          color: #777;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          padding: 0px 0px 0px 4px;
          i{
            &:hover{
              color: $darkRed;
            }
            &.active{
              color: $darkRed;
            }
          }
          span.dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $darkRed;
            margin-left: 5px;
            margin-right: 5px;
          }
          span.stat.active{
            color: $darkRed;
          }
        }
      }

      form {
        margin-top: 6px;
        @include flexCenter(row);
        width: 100%;

        button {
          padding: 8px 20px;
          width: 100%;
          margin-left: 0px;
          cursor: pointer;
          background-color: $darkRed;
          border: none;
          outline: none;
          color: #fff;
          border-radius: 5px;
          transition: all 120ms ease-in-out;
          font-size: 14px;
          @media screen and (max-width: 600px) {
            padding: 8px 12px;
          }

          &:hover {
            background-color: $lightRed;
            color: #222;
          }
        }
        .unlike {
          background-color: transparent;
          color: #777;
          border: 1px solid #bbb;
          &:hover {
            background-color: #ddd;
            color: #777;
          }
        }
      }
    }
  }
}
