.chat-box {
  position: fixed;
  bottom: 100px;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  @include flexCenter(row);
  z-index: 100;
  padding: 0px 24px;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
    display: none;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }

  .chat-box-container {
    @include mainWidth();
    @include flexCenter(row);
    justify-content: flex-end;

    a {
      font-size: 24px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $darkRed;
      border-radius: 50px;
      text-decoration: none;
      color: #fff;
      box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
        0 1px 3px 1px rgba(63, 51, 48, 0.15);
    }
  }
}
