.account {
  width: 100%;
  // height: 86vh;
  margin-top: 20px;
  @include flexCenter(column);
  justify-content: flex-start;
  position: relative;
  min-height: 90vh;

  @import "../assets/btn-holder";

  .msg {
    @include flexCenter(column);
    .success-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(200, 255, 200);
      border-left: 3px solid green;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: green;
      }
    }
  }
  .msg {
    @include flexCenter(column);
    .error-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 206, 206);
      border-left: 3px solid red;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: red;
      }
    }
  }

  // Next back buttons
  @import "../assets/nextback";

  .account-container {
    height: fit-content;
    @include flexCenter(column);
    align-items: flex-start;
    @include mainWidth();

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .table {
      margin-top: 15px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      @include cardShadow();
      background-color: #fff;
      @media screen and (max-width: 900px) {
        box-shadow: none;
      }

      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 20px;
      }

      form.main-form {
        @include flexCenter(row);
        flex-wrap: wrap;
        width: 100%;
        margin-top: 24px;

        &:first-child {
          margin-top: 0px;
        }

        .heading {
          width: 100%;
          background-color: $lightRed;
          grid-template-columns: repeat(1, 1fr);
          overflow: hidden;

          h2 {
            font-size: 20px;
            padding: 10px;
            font-weight: 500;
            display: block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              color: #fff;
              padding: 5px 12px;
              font-size: 14px;
              font-weight: bold;
              border-radius: 999px;
            }
            .paid {
              background-color: $success;
            }
            .unpaid {
              background-color: $err;
            }
          }

          .content {
            background-color: #fff;
            border-top: 1px solid #bbb;
            flex: 1;

            &:first-child {
              border-top: none;
            }
            div {
              @include flexCenter(row);
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              padding: 5px 0px;
              transition: all 300ms ease-in-out;
              display: flex;
              &:hover {
                background-color: #eee;
              }

              p {
                display: block;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 16.5px;
                background-color: transparent;
                border: none;
                outline: none;
                margin-left: 4px;
              }
              .edit {
                width: fit-content;
                justify-self: flex-end;
                color: $darkRed;
                cursor: pointer;
                padding-left: 6px;
              }
            }
          }
        }
        button {
          width: 100%;
          background-color: $darkGreen;
          padding: 10px;
          border: none;
          outline: none;
          color: #fff;
          font-size: 16.5px;
          transition: all 300ms ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: rgb(14, 136, 14);
            color: #fff;
          }
        }
      }
      form.delete-form {
        @include flexCenter(row);
        width: 100%;
        &:last-of-type {
          margin-bottom: 50px;
        }
        button {
          width: 100%;
          padding: 10px;
          border: none;
          outline: none;
          color: #fff;
          font-size: 16.5px;
          transition: all 300ms ease-in-out;
          cursor: pointer;

          background-color: $darkRed;

          &:hover {
            background-color: rgba(255, 43, 43, 0.835);
          }
        }
      }
    }
  }
}

.buyer {
  margin-bottom: 40px;
  .msg {
    @include flexCenter(column);
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }
    .success-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(200, 255, 200);
      border-left: 3px solid green;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: green;
      }
    }
  }
  .msg {
    @include flexCenter(column);
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }
    .error-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 206, 206);
      border-left: 3px solid red;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: red;
      }
    }
  }
  .buyer-acc-cont {
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }

    .account-info-container {
      h2 {
        text-align: center;
      }
      h3 {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}

// Glowing form
@import "../assets/glowing-form";
