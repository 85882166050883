.help {
  @include flexCenter(column);
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 12px;
  min-height: 94vh;
  width: 100%;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px;
  }

  .help-container {
    width: 60%;
    height: 100%;
    @include flexCenter(column);
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;
    @media screen and (max-width: 1500px) {
      width: 70%;
    }
    @media screen and (max-width: 900px) {
      width: 85%;
    }
    @media screen and (max-width: 800px) {
      width: 90%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
    }

    .heading {
      width: 100%;

      @media screen and (max-width: 600px) {
        padding: 0px 10px;
      }

      h1 {
        font-size: 20px;
        color: #555;
        padding: 4px;
        font-weight: 600;
      }
    }

    .help-card {
      text-decoration: none;
      color: #000;
      width: 100%;
      height: fit-content;
      padding: 8px 12px;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
        0 1px 3px 1px rgba(63, 51, 48, 0.15);
      border-radius: 5px;
      margin-top: 15px;
      transition: all 120ms ease-in-out;
      word-wrap: break-word;

      &:last-child {
        margin-bottom: 50px;
      }
      @media screen and (max-width: 600px) {
        padding: 8px 10px;
        box-shadow: none;
        border-radius: 0px;
      }

      h1 {
        font-size: 20px;
        font-weight: bolder;
        @media screen and (max-width: 720px) {
          font-size: 16px;
        }
        display: block;
      }
      p {
        display: block;
        margin-top: 6px;
        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
      }
      .dry {
        font-style: italic;
        color: #777;
        font-size: 15px;

        @media screen and (max-width: 500px) {
          font-size: 14px;
        }
      }
      a {
        color: $darkRed;
        margin: 0px 5px;
      }
    }
  }
}
