.account {
  width: 100%;
  // height: 86vh;
  margin-top: 20px;
  @include flexCenter(column);
  justify-content: flex-start;
  position: relative;

  min-height: 90vh;

  .account-container {
    height: fit-content;
    @include flexCenter(row);
    align-items: flex-start;

    @include mainWidth();

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .stats {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 10px;
      justify-content: center;
      align-items: center;

      //   grid-template-rows: 50px 50px 50px 50px 50px;
      background-color: transparent;

      .mobile-view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .btn {
          padding: 12px 48px;
          font-size: 16px;
          font-weight: bold;
          @include button($success);
          margin-top: 20px;
          @include shadows();
          color: #fff;
          cursor: pointer;
        }

        .total {
          font-size: 24px;
          font-weight: 500;

          span {
            font-weight: bold;
          }
        }

        .heading {
          padding: 18px 10px;
          width: 100%;
          border: 1px solid rgb(219, 217, 217);
          // background: $lightRed;
          margin-top: 20px;

          @media screen and (max-width: 600px) {
            padding: 10px;
          }
          &:first-child {
            margin-top: 0px;
          }
          h2 {
            position: relative;
            color: #777;
            font-weight: 500;
            font-size: 24px;
            text-align: left;

            @media screen and (max-width: 600px) {
              font-size: 18px;
            }
          }
        }

        // Menu Cards
        .card-menu {
          display: flex;
          background-color: $darkRed;
          padding: 6px;
          width: 100%;
          height: fit-content;
          cursor: pointer;
          margin-top: 12px;

          .text {
            flex: 1;
            display: flex;
            flex-direction: column;
            p {
              color: #fff;
              font-size: 15px;
              pointer-events: none;
            }
            h2 {
              font-weight: 500;
              font-size: 24px;
              @media screen and (max-width: 600px) {
                font-size: 20px;
              }
              color: #fff;
              pointer-events: none;
            }
          }
          .icon {
            pointer-events: none;
            margin-left: 6px;
            i {
              color: #fff;
              font-size: 24px;
              @media screen and (max-width: 600px) {
                font-size: 20px;
              }
              pointer-events: none;
            }
          }
        }
        .card-content {
          background-color: #fff;
          animation: scale 0.3s linear 1;
          padding: 5px;
          width: 100%;
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
          transform-origin: top;
          display: none;

          @keyframes scale {
            0% {
              transform: scaleY(0);
            }
            100% {
              transform: scaleY(1);
            }
          }

          p {
            font-size: 20px;
            font-weight: bold;
            color: $darkRed;
            display: block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              font-weight: 500;
              color: #000;
            }
          }
        }

        .users-container {
          background-color: #fff;
          padding: 30px 30px;
          width: fit-content;
          @include cardShadow();
          margin-top: 12px;
          box-shadow: 0 20px 50px rgba(0, 0, 0, 0.4);
          overflow: hidden;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            bottom: 2px;
            width: 50px;
            background: rgba(255, 255, 255, 0.05);
          }
          span:nth-child(1) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(to right, $superDarkRed, $darkRed);
            animation: animate1 2s linear infinite;
          }
          @keyframes animate1 {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }
          span:nth-child(2) {
            position: absolute;
            top: 0;
            right: 0;
            width: 2px;
            height: 100%;
            background: linear-gradient(to bottom, $superDarkRed, $darkRed);
            animation: animate2 2s linear infinite;
            animation-delay: 1s;
          }
          @keyframes animate2 {
            0% {
              transform: translateY(-100%);
            }
            100% {
              transform: translateY(100%);
            }
          }
          span:nth-child(3) {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(to left, $superDarkRed, $darkRed);
            animation: animate3 2s linear infinite;
          }
          @keyframes animate3 {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
          span:nth-child(4) {
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background: linear-gradient(to top, $superDarkRed, $darkRed);
            animation: animate4 2s linear infinite;
            animation-delay: 1s;
          }
          @keyframes animate4 {
            0% {
              transform: translateY(100%);
            }
            100% {
              transform: translateY(-100%);
            }
          }

          h1 {
            font-size: 2em;
            font-weight: 500;
            @media screen and (max-width: 600px) {
              font-size: 26px;
            }
          }
          h2 {
            font-size: 4em;
            @media screen and (max-width: 600px) {
              font-size: 3em;
            }
          }
          p {
            color: $darkRed;
          }
        }
      }
    }
  }
}
