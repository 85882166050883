// Mixins

// Flex box
@mixin flexCenter($dir) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $dir;
}

// links and buttons
@mixin links() {
  text-decoration: none;
  color: #000;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  font-weight: 300;
  padding: 10px;
  &:hover {
    color: $darkRed;
  }
}
@mixin buttonHover {
  background-color: $darkRed;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  &:hover {
    box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
    color: #fff;
  }
}

// Shadows
@mixin navShadow {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
@mixin shadows {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
}
@mixin prodCardShadow {
  box-shadow: 2px 2px 0 rgba(77, 77, 79, 0.08), 0 0 2px 0 rgba(77, 77, 79, 0.16);
  transition: all 300ms ease-in-out;
  &:hover{
   box-shadow: 0 4px 16px 2px rgba(77, 77, 79, 0.08), 0 8px 12px 2px rgba(77, 77, 79, 0.16);
  }
}
@mixin cardShadow {
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
    0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  &:hover {
    box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
  }
}

// Inputs
@mixin inputs() {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  // border: 3px solid $darkRed;
  border-bottom: 3px solid $darkRed;
  // border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
  @media screen and (max-width: 350px) {
    font-size: 13px;
  }
}

// Account Buttons
@mixin buttons() {
  @include flexCenter(row);
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid $darkRed;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}
@mixin button($color) {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: $color;
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}

// Width rules
@mixin mainWidth {
  width: 80%;

  @media screen and (max-width: 1400px) {
    width: 92%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}
@mixin adminWidth {
  width: 80%;
  @media screen and (max-width: 1400px) {
    width: 92%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}
@mixin screenWidth {
  @media screen and (max-width: 650px) {
    width: 100%;
  }
}
@mixin shopsWidth {
  width: 50%;
  @media screen and (max-width: 1000px) {
    width: 70%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

// Font family
@mixin fontFamily {
  font-family: "Montserrat", sans-serif;
}

// Animations
@mixin _slideUp {
  animation: slide 0.3s linear 1;
  transform-origin: bottom;

  @keyframes slide {
    0% {
      transform: translateY(100%);
      box-shadow: none;
    }
    100% {
      transform: translateY(0%);
    }
  }
}
@mixin _slideDown {
  animation: slide 0.3s linear 1;
  transform-origin: top;

  @keyframes slide {
    0% {
      transform: translateY(-100%);
      box-shadow: none;
    }
    100% {
      transform: translateY(0%);
    }
  }
}
@mixin _scaleVert {
  animation: scale 0.3s linear 1;
  transform-origin: top;

  @keyframes scale {
    0% {
      transform: scaleY(0);
      box-shadow: none;
    }
    100% {
      transform: scaleY(1);
    }
  }
}
@mixin _scaleHori {
  animation: scale 0.3s linear 1;
  transform-origin: top;

  @keyframes scale {
    0% {
      transform: scaleX(0);
      box-shadow: none;
    }
    100% {
      transform: scaleX(1);
    }
  }
}
