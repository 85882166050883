.decoy-cont {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.36);
  @include flexCenter(row);
  z-index: 10;
  animation: fade 0.25s linear 1;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .search-cont {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    background-color: #fff;
    @include flexCenter(row);
    z-index: 1010;

    button {
      border: none;
      outline: none;
      font-size: 16px;
      background-color: transparent;
      padding: 8px;
      width: 5%;
      color: #777;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        width: 5.25%;
      }
      @media screen and (max-width: 380px) {
        width: 10%;
      }
    }

    input {
      width: 90%;
      padding: 10px;
      border: none;
      border-left: 1px solid #ddd;
      background-color: #fff;
      outline: none;
      @media screen and (max-width: 600px) {
        width: 85%;
        border-left: none;
      }
      @media screen and (max-width: 380px) {
        width: 80%;
      }
    }
  }
}
