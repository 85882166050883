* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: linear-gradient(113.36deg, #eee 0%, rgba(255, 122, 90, 0.1) 112.98%);
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#root {
  width: 100%;
  height: 100%;
}
#root .container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px;
}
@media screen and (max-width: 650px) {
  #root .container {
    width: 100%;
  }
}
#root .container .in-spinner {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
#root .container .in-spinner div {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
}
#root .container .in-spinner .one {
  animation: spin2 1.2s linear infinite;
  border-top-color: #ff7a5a;
}
#root .container .in-spinner .two {
  animation: spin3 1.2s linear infinite;
  border-bottom-color: #ff7a5a;
}
@keyframes spin2 {
  0% {
    border-width: 8px;
    transform: rotate(0deg);
  }
  50% {
    border-width: 3px;
    transform: rotate(180deg);
  }
  100% {
    border-width: 8px;
    transform: rotate(360deg);
  }
}
@keyframes spin3 {
  0% {
    border-width: 3px;
    transform: rotate(0deg);
  }
  50% {
    border-width: 8px;
    transform: rotate(180deg);
  }
  100% {
    border-width: 3px;
    transform: rotate(360deg);
  }
}
#root .container .loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .loader .circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 4px solid #53281d;
  border-top-color: #ff7a5a;
  border-radius: 50%;
  animation: spin1 1.2s linear infinite;
}
@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#root .container .popup-container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  z-index: 11111111111;
  animation: animate-links 0.3s linear 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@keyframes animate-links {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .popup-container .popup {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.35);
  border-radius: 36px;
  width: 30%;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 30px;
  margin-bottom: 40px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
  display: block;
  overflow: auto;
}
@media screen and (max-width: 1300px) {
  #root .container .popup-container .popup {
    width: 40%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .popup-container .popup {
    width: 60%;
  }
}
@media screen and (max-width: 700px) {
  #root .container .popup-container .popup {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .popup-container .popup {
    width: 90%;
  }
}
@media screen and (max-width: 260px) {
  #root .container .popup-container .popup {
    padding: 15px;
  }
}
#root .container .popup-container .popup .err {
  color: rgb(255, 43, 43);
}
#root .container .popup-container .popup .success {
  color: rgb(14, 136, 14);
}
#root .container .popup-container .popup .warn {
  color: rgb(185, 127, 0);
}
#root .container .popup-container .popup h2 {
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
}
#root .container .popup-container .popup p {
  height: 40px;
  color: #777;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 36px 0px;
  font-size: 18px;
}
#root .container .popup-container .popup .btn-err {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: rgb(255, 43, 43);
}
@media screen and (max-width: 500px) {
  #root .container .popup-container .popup .btn-err {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .popup-container .popup .btn-err {
    font-size: 12px;
  }
}
#root .container .popup-container .popup .btn-success {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: rgb(14, 136, 14);
}
@media screen and (max-width: 500px) {
  #root .container .popup-container .popup .btn-success {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .popup-container .popup .btn-success {
    font-size: 12px;
  }
}
#root .container .popup-container .popup .btn-warn {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: rgb(185, 127, 0);
}
@media screen and (max-width: 500px) {
  #root .container .popup-container .popup .btn-warn {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .popup-container .popup .btn-warn {
    font-size: 12px;
  }
}
#root .container .popup-container .popup .btn-raw {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #777;
}
@media screen and (max-width: 500px) {
  #root .container .popup-container .popup .btn-raw {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .popup-container .popup .btn-raw {
    font-size: 12px;
  }
}
#root .container .popup-container .popup .sec-btn {
  margin-top: 10px;
}
#root .container .popup-container .popup button {
  color: #fff;
  background-color: #ff7a5a;
  cursor: pointer;
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 300ms ease-in-out;
}
#root .container .popup-container .popup button:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
#root .container .slide-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11111111111;
  height: -moz-fit-content;
  height: fit-content;
  animation: scaling 0.3s linear 1;
  transform-origin: top;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@keyframes scaling {
  0% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(1);
  }
}
#root .container .slide-popup .content {
  padding: 12px 16px;
  background: #53281d;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 30%;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
  display: block;
  overflow: auto;
}
@media screen and (max-width: 1300px) {
  #root .container .slide-popup .content {
    width: 40%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .slide-popup .content {
    width: 60%;
  }
}
@media screen and (max-width: 700px) {
  #root .container .slide-popup .content {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .slide-popup .content {
    width: 90%;
  }
}
@media screen and (max-width: 260px) {
  #root .container .slide-popup .content {
    padding: 15px;
  }
}
#root .container .slide-popup .content h2 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #fff;
}
#root .container .slide-popup .content h2 i {
  margin-right: 10px;
  color: #ff7a5a;
  font-size: 26px;
}
#root .container .slide-popup .close {
  padding: 12px 20px;
  background-color: green;
}
#root .container .slide-popup .close span {
  color: #fff;
  background-color: #ff7a5a;
  cursor: pointer;
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 300ms ease-in-out;
}
#root .container .slide-popup .close span:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
#root .container .sub {
  width: 100%;
  background-color: #53281d;
}
@media screen and (max-width: 550px) {
  #root .container .sub {
    flex-direction: column;
    padding: 10px;
  }
}
#root .container .sub p {
  font-size: 16px;
  padding: 0px 12px;
  color: #fff;
}
@media screen and (max-width: 550px) {
  #root .container .sub p {
    text-align: center;
  }
}
#root .container .sub button {
  border: none;
  border-radius: 5px;
  background-color: #ff7a5a;
  padding: 12px 48px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 550px) {
  #root .container .sub button {
    margin-top: 10px;
    text-align: center;
    padding: 10px 30px;
  }
}
#root .container .msg {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  margin-bottom: 12px;
}
#root .container .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  margin-bottom: 12px;
}
#root .container .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  margin-bottom: 12px;
}
#root .container .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 10px;
  min-height: 94vh;
  width: 100%;
}
@media screen and (max-width: 900px) {
  #root .container .error {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .error {
    padding: 0px 10px;
  }
}
#root .container .error .error-container .img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media screen and (max-height: 700px) {
  #root .container .error .error-container .img img {
    height: 240px;
  }
}
#root .container .error .error-container h2 {
  font-size: 6em;
  letter-spacing: 10px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  #root .container .error .error-container h2 {
    font-size: 4em;
  }
}
@media screen and (max-width: 600px) {
  #root .container .error .error-container h2 {
    font-size: 3em;
  }
}
@media screen and (max-width: 300px) {
  #root .container .error .error-container h2 {
    font-size: 2em;
  }
}
#root .container .error .error-container p {
  margin-top: 10px;
  font-size: 24px;
  color: #777;
  text-align: center;
}
#root .container .error .error-container p span {
  font-size: 16px;
}
#root .container .error .error-container a {
  text-decoration: none;
  color: #fff;
  background-color: #ff7a5a;
  padding: 10px 24px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  margin-top: 10px;
}
#root .container .error .error-container a:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .error .error-container a:last-of-type {
  margin-bottom: 50px;
}
#root .container nav {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0px 24px;
  z-index: 1000000;
}
@media screen and (max-width: 900px) {
  #root .container nav {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 600px) {
  #root .container nav {
    padding: 0px 10px;
  }
}
#root .container nav .desktop-nav {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
}
@media screen and (max-width: 900px) {
  #root .container nav .desktop-nav {
    display: none;
  }
}
@media screen and (max-width: 1400px) {
  #root .container nav .desktop-nav {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container nav .desktop-nav {
    width: 100%;
  }
}
#root .container nav .desktop-nav .logo {
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
#root .container nav .desktop-nav .logo a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 30px;
  font-weight: 300;
}
#root .container nav .desktop-nav .logo a span {
  margin-left: 4px;
  color: #000;
}
@media screen and (max-width: 1200px) {
  #root .container nav .desktop-nav .logo a span {
    display: none;
  }
}
#root .container nav .desktop-nav .logo a img {
  width: 60px;
  height: 50px;
}
#root .container nav .desktop-nav .links {
  flex: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
#root .container nav .desktop-nav .links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
#root .container nav .desktop-nav .links ul li {
  display: inline-flex;
  margin: 10px 0px;
}
#root .container nav .desktop-nav .links ul li span {
  margin: 10px 4px;
}
#root .container nav .desktop-nav .links ul li a {
  position: relative;
  text-decoration: none;
  text-decoration: none;
  color: #000;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  font-weight: 300;
  padding: 10px;
}
#root .container nav .desktop-nav .links ul li a:hover {
  color: #ff7a5a;
}
#root .container nav .desktop-nav .links ul li .inbox {
  background-color: #ff7a5a;
  border-radius: 50px;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 8px;
  color: #fff;
}
#root .container nav .desktop-nav .links ul li .search {
  color: #ff7a5a;
}
#root .container nav .desktop-nav .links ul li .login-navLink {
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
}
#root .container nav .desktop-nav .links ul li .login-navLink:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
@media screen and (max-width: 1000px) {
  #root .container nav .desktop-nav .links ul li .login-navLink {
    padding: 10px 24px;
  }
}
#root .container nav .mobile-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: none;
}
@media screen and (max-width: 650px) {
  #root .container nav .mobile-nav {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  #root .container nav .mobile-nav {
    display: flex;
  }
}
#root .container nav .mobile-nav .ham-nav {
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
#root .container nav .mobile-nav .ham-nav .openNav {
  display: inline-block;
  cursor: pointer;
}
#root .container nav .mobile-nav .ham-nav .openNav .bar1,
#root .container nav .mobile-nav .ham-nav .openNav .bar2,
#root .container nav .mobile-nav .ham-nav .openNav .bar3 {
  background: #ff7a5a;
  width: 35px;
  height: 5px;
  margin: 6px 0;
  transition: 0.4s;
}
#root .container nav .mobile-nav .ham-nav .closeNav {
  display: block;
  cursor: pointer;
}
#root .container nav .mobile-nav .ham-nav .closeNav .bar1,
#root .container nav .mobile-nav .ham-nav .closeNav .bar2,
#root .container nav .mobile-nav .ham-nav .closeNav .bar3 {
  width: 35px;
  height: 5px;
  margin: 6px 0;
  transition: 0.4s;
}
#root .container nav .mobile-nav .ham-nav .closeNav .bar1 {
  background: #ff7a5a;
  transform: rotate(-45deg) translate(-9px, 6px);
}
#root .container nav .mobile-nav .ham-nav .closeNav .bar2 {
  background: #ff7a5a;
  opacity: 0;
}
#root .container nav .mobile-nav .ham-nav .closeNav .bar3 {
  background: #ff7a5a;
  transform: rotate(45deg) translate(-8px, -8px);
}
#root .container nav .mobile-nav .logo {
  width: 33.3%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container nav .mobile-nav .logo a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  color: #000;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  font-weight: 300;
  padding: 10px;
}
#root .container nav .mobile-nav .logo a:hover {
  color: #ff7a5a;
}
@media screen and (max-width: 600px) {
  #root .container nav .mobile-nav .logo a {
    font-size: 24px;
  }
}
#root .container nav .mobile-nav .logo a span {
  margin-left: 4px;
  color: #000;
}
#root .container nav .mobile-nav .logo a img {
  width: 60px;
  height: 50px;
}
@media screen and (max-width: 600px) {
  #root .container nav .mobile-nav .logo a img {
    width: 40px;
    height: 40px;
  }
}
#root .container nav .mobile-nav .search {
  background-color: transparent;
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
#root .container nav .mobile-nav .search button {
  border: none;
  outline: none;
  color: #ff7a5a;
  padding: 5px;
  font-size: 24px;
  background-color: transparent;
  cursor: pointer;
}
#root .container nav .mobile-nav .search-form {
  position: absolute;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0px 10px;
  display: none;
  animation: scaling 0.1s linear 1;
}
@keyframes scaling {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
#root .container nav .mobile-nav .search-form .close-search {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 10%;
  padding: 10px;
  outline: none;
  border: none;
  color: #777;
  font-weight: bolder;
  background-color: #fff;
  font-size: 16px;
  cursor: pointer;
}
#root .container nav .mobile-nav .search-form form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container nav .mobile-nav .search-form form input {
  width: 100%;
  padding: 10px 35px 10px 10px;
  background-color: #eee;
  outline: none;
  border: none;
  border-radius: 99px;
  font-size: 15px;
}
#root .container nav .mobile-nav .search-form form button {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px 8px;
  outline: none;
  border: none;
  color: #777;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
}
#root .container .search-bar {
  position: fixed;
  top: 63px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: -moz-fit-content;
  height: fit-content;
  background-color: transparent;
  padding: 0px 24px;
  z-index: 100;
}
@media screen and (max-width: 1000px) {
  #root .container .search-bar {
    top: 59px;
  }
}
@media screen and (max-width: 900px) {
  #root .container .search-bar {
    padding: 0px 16px;
    top: 70px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .search-bar {
    padding: 0px 10px;
    top: 60px;
  }
}
@media screen and (max-width: 900px) {
  #root .container .search-bar {
    display: none;
  }
}
#root .container .search-bar .search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  width: 80%;
}
@media screen and (max-width: 1400px) {
  #root .container .search-bar .search-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .search-bar .search-container {
    width: 100%;
  }
}
#root .container .search-bar .search-container form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
#root .container .search-bar .search-container form input {
  width: 50%;
  padding: 10px;
  border: none;
  outline: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
  border: 2px solid #ff7a5a;
  font-size: 16px;
}
@media screen and (max-width: 1000px) {
  #root .container .search-bar .search-container form input {
    width: 60%;
  }
}
@media screen and (max-width: 500px) {
  #root .container .search-bar .search-container form input {
    width: 70%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .search-bar .search-container form input {
    width: 75%;
  }
}
@media screen and (max-width: 300px) {
  #root .container .search-bar .search-container form input {
    width: 80%;
  }
}
#root .container .search-bar .search-container form button {
  width: 8%;
  padding: 10px;
  background-color: #ff7a5a;
  border: none;
  border: 2px solid #ff7a5a;
  cursor: pointer;
  font-size: 16px;
  outline: none;
}
@media screen and (max-width: 900px) {
  #root .container .search-bar .search-container form button {
    width: 10%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .search-bar .search-container form button {
    width: 14%;
  }
}
@media screen and (max-width: 300px) {
  #root .container .search-bar .search-container form button {
    width: 16%;
  }
}
#root .container .search-bar .search-container form button i {
  color: #fff;
}
#root .container .register-login {
  width: 100%;
  min-height: 93vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
}
#root .container .register-login .form {
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.35);
  border-radius: 36px;
  width: 30%;
  margin-top: 10px;
  margin-bottom: 40px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1500px) {
  #root .container .register-login .form {
    width: 40%;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .register-login .form {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .register-login .form {
    width: 60%;
  }
}
@media screen and (max-width: 700px) {
  #root .container .register-login .form {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .register-login .form {
    width: 90%;
  }
}
@media screen and (max-width: 480px) {
  #root .container .register-login .form {
    width: 100%;
  }
}
@media screen and (max-width: 260px) {
  #root .container .register-login .form {
    padding: 15px;
  }
}
#root .container .register-login .form img {
  width: 200px;
  width: 200px;
  margin-top: 12px;
}
#root .container .register-login .form .account-nav {
  width: 100%;
  height: 45px;
  background: #ffffff;
  box-shadow: 1px 3px 24px rgba(0, 0, 0, 0.3);
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}
#root .container .register-login .form .account-nav a {
  width: 50%;
  height: 45px;
  background: #ffffff;
  border-radius: 36px;
}
#root .container .register-login .form .account-nav a:hover {
  background-color: #eee;
  text-decoration: none;
  color: #ff7a5a;
}
#root .container .register-login .form .account-nav .active {
  background-color: #ff7a5a;
  color: #fff;
}
#root .container .register-login .form .account-nav .active:hover {
  color: #ff7a5a;
}
#root .container .register-login .form .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form .heading .avatar {
  width: 50px;
  height: 50px;
  margin-bottom: 12px;
}
#root .container .register-login .form .heading .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .register-login .form .heading h1 {
  font-size: 24px;
  font-weight: 520;
  margin-bottom: 18px;
}
@media screen and (max-width: 720px) {
  #root .container .register-login .form .heading h1 {
    font-size: 21px;
  }
}
#root .container .register-login .form form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form form .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form form .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .register-login .form form .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .register-login .form form .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form form .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .register-login .form form .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .register-login .form form .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form form .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .register-login .form form .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .register-login .form form input {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 500px) {
  #root .container .register-login .form form input {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .register-login .form form input {
    font-size: 13px;
  }
}
#root .container .register-login .form form select {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 500px) {
  #root .container .register-login .form form select {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .register-login .form form select {
    font-size: 13px;
  }
}
#root .container .register-login .form form label {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px;
}
#root .container .register-login .form form label span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid #ff7a5a;
  border-radius: 5px;
  padding: 3px;
  margin: 3px;
  color: #ff7a5a;
}
#root .container .register-login .form form label .delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  background-color: rgb(255, 43, 43);
  color: #fff;
}
#root .container .register-login .form form label i {
  margin-right: 8px;
}
#root .container .register-login .form form .drag-drop {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: #eee;
  border: 3px dotted #ff7a5a;
  width: 100%;
  padding: 10px;
}
#root .container .register-login .form form .drag-drop i {
  margin-bottom: 10px;
  font-size: 3em;
  color: #ff7a5a;
  pointer-events: none;
}
#root .container .register-login .form form .autocomplete {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
}
#root .container .register-login .form form .autocomplete ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
}
#root .container .register-login .form form .autocomplete ul li {
  width: 100%;
  list-style: none;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
#root .container .register-login .form form .autocomplete ul li:last-child {
  border-bottom: none;
}
#root .container .register-login .form form .autocomplete ul li:hover {
  background-color: rgb(255, 223, 214);
}
#root .container .register-login .form form .passwords {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .register-login .form form .passwords input {
  width: 100%;
  flex: 1;
}
#root .container .register-login .form form .passwords i {
  width: 0%;
  transform: translate(-24px);
  font-size: 16px;
  color: #999;
}
#root .container .register-login .form form .passwords i:hover {
  color: #ff7a5a;
}
#root .container .register-login .form form button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff7a5a;
  cursor: pointer;
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 300ms ease-in-out;
}
@media screen and (max-width: 500px) {
  #root .container .register-login .form form button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .register-login .form form button {
    font-size: 12px;
  }
}
#root .container .register-login .form form button:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
#root .container .register-login .form form .btn-animate {
  background-color: #ddd;
  color: #bbb;
  animation: animate 1.25s alternate infinite;
  border: 3px solid #ddd;
  pointer-events: none;
}
@keyframes animate {
  0% {
    border: 3px solid #ccc;
    background-color: #ccc;
    color: #aaa;
  }
  100% {
    border: 3px solid #ddd;
    background-color: #ddd;
  }
}
#root .container .register-login .form form .dry {
  width: 100%;
  background-color: transparent;
  border: 1px solid #bbb;
  color: #777;
  padding: 12px;
  border-radius: 5px;
}
#root .container .register-login .form form .dry:hover {
  color: #777;
  text-decoration: none;
}
#root .container .register-login .form form #success {
  background-color: rgb(14, 136, 14);
  border: 3px solid rgb(14, 136, 14);
}
#root .container .register-login .form form #success:hover {
  background-color: rgba(14, 136, 14, 0.835);
}
#root .container .register-login .form form p {
  width: 100%;
  font-size: 13px;
  text-align: center;
  transition: all 120ms ease-in-out;
  font-weight: bold;
  padding: 5px;
}
@media screen and (max-width: 350px) {
  #root .container .register-login .form form p {
    font-size: 11px;
  }
}
#root .container .register-login .form form #err {
  background-color: rgb(255, 206, 206);
}
#root .container .register-login .form form .confirm-no {
  width: 100%;
  background-color: transparent;
  border: 1px solid #bbb;
  color: #777;
  padding: 12px;
  border-radius: 5px;
}
#root .container .register-login .form form .confirm-no:hover {
  color: #777;
  background-color: #eee;
  text-decoration: none;
}
#root .container .register-login .form .expand {
  height: 500px;
}
@media screen and (max-width: 1500px) {
  #root .container .register-login .form .expand {
    height: 400px;
  }
}
@media screen and (max-width: 900px) {
  #root .container .register-login .form .expand {
    height: 300px;
  }
}
#root .container .register-login .form .expand img {
  width: 100%;
  height: 60%;
}
@media screen and (max-width: 900px) {
  #root .container .register-login .form .expand img {
    height: 50%;
  }
}
#root .container .register-login .form p {
  font-size: 15px;
  color: #555;
  text-align: center;
}
#root .container .register-login .form a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  color: #ff7a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 500px) {
  #root .container .register-login .form a {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .register-login .form a {
    font-size: 12px;
  }
}
#root .container .register-login .form a:hover {
  color: #ff7a5a;
  text-decoration: underline;
}
#root .container .register-login .form .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .register-login .form .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .register-login .form .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .register-login .form .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .register-login .form .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .register-login .form .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .register-login .form .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .register-login .two-col {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
}
#root .container .register-login .two-col .heading {
  width: 100%;
  padding: 10px;
  background-color: #ddd;
  font-size: 14px;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;
  margin-top: 10px;
}
#root .container .register-login .two-col h4 {
  font-size: 14px;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 300;
  color: #555;
  margin-top: 5px;
}
#root .container .login {
  margin-top: 10px;
}
#root .container .help {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 12px;
  min-height: 94vh;
  width: 100%;
}
@media screen and (max-width: 900px) {
  #root .container .help {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .help {
    padding: 0px;
  }
}
#root .container .help .help-container {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
}
@media screen and (max-width: 1500px) {
  #root .container .help .help-container {
    width: 70%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .help .help-container {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .help .help-container {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .help .help-container {
    width: 100%;
  }
}
#root .container .help .help-container .heading {
  width: 100%;
}
@media screen and (max-width: 600px) {
  #root .container .help .help-container .heading {
    padding: 0px 10px;
  }
}
#root .container .help .help-container .heading h1 {
  font-size: 20px;
  color: #555;
  padding: 4px;
  font-weight: 600;
}
#root .container .help .help-container .help-card {
  text-decoration: none;
  color: #000;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  border-radius: 5px;
  margin-top: 15px;
  transition: all 120ms ease-in-out;
  word-wrap: break-word;
}
#root .container .help .help-container .help-card:last-child {
  margin-bottom: 50px;
}
@media screen and (max-width: 600px) {
  #root .container .help .help-container .help-card {
    padding: 8px 10px;
    box-shadow: none;
    border-radius: 0px;
  }
}
#root .container .help .help-container .help-card h1 {
  font-size: 20px;
  font-weight: bolder;
  display: block;
}
@media screen and (max-width: 720px) {
  #root .container .help .help-container .help-card h1 {
    font-size: 16px;
  }
}
#root .container .help .help-container .help-card p {
  display: block;
  margin-top: 6px;
}
@media screen and (max-width: 500px) {
  #root .container .help .help-container .help-card p {
    font-size: 15px;
  }
}
#root .container .help .help-container .help-card .dry {
  font-style: italic;
  color: #777;
  font-size: 15px;
}
@media screen and (max-width: 500px) {
  #root .container .help .help-container .help-card .dry {
    font-size: 14px;
  }
}
#root .container .help .help-container .help-card a {
  color: #ff7a5a;
  margin: 0px 5px;
}
#root .container .install {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}
#root .container .install .add-button {
  font-size: 20px;
  text-decoration: none;
  color: #fff;
  background-color: #ff7a5a;
  padding: 12px 100px;
  border-radius: 5px;
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  #root .container .install .add-button {
    padding: 12px 80px;
  }
}
@media screen and (max-width: 280px) {
  #root .container .install .add-button {
    padding: 12px 50px;
  }
}
#root .container .install button {
  color: #fff;
  font-size: 40px;
  margin-top: 20px;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
#root .container .welcome {
  position: relative;
  top: 0;
  width: 100%;
  min-height: 50vh;
  height: -moz-fit-content;
  height: fit-content;
  background-color: rgb(245, 236, 234);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 99;
}
@media screen and (max-width: 1000px) {
  #root .container .welcome {
    background-size: 100% 100%;
  }
}
@media screen and (min-height: 800px) {
  #root .container .welcome {
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 570px) {
  #root .container .welcome {
    min-height: 0;
  }
}
#root .container .welcome .imgs {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  height: 50%;
}
@media screen and (max-width: 800px) {
  #root .container .welcome .imgs {
    height: 30%;
  }
}
@media screen and (max-width: 570px) {
  #root .container .welcome .imgs {
    height: 150px;
  }
}
#root .container .welcome .imgs img {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 570px) {
  #root .container .welcome .imgs img {
    background-size: cover;
  }
}
#root .container .welcome .user-location {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  width: 100%;
  z-index: 1000000;
  margin-top: -112px;
}
#root .container .welcome .user-location .glass-container {
  background: rgba(255, 255, 255, 0.15); /* translucent white background */
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px); /* backdrop filter for blur effect */
  border-radius: 10px; /* rounded corners */
  padding: 20px; /* padding around the content */
  width: 80%; /* set your preferred width */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1); /* box shadow for depth */
}
@media screen and (max-width: 980px) {
  #root .container .welcome .user-location .glass-container {
    width: 100%;
  }
}
#root .container .welcome .user-location .glass-container p {
  color: #fff;
}
#root .container .welcome .user-location .glass-container .search-container {
  display: flex;
  background-color: transparent;
  position: relative;
}
#root .container .welcome .user-location .glass-container .search-container input {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  padding: 12px 12px 12px 36px;
  border-radius: 6px;
}
@media screen and (max-width: 500px) {
  #root .container .welcome .user-location .glass-container .search-container input {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .welcome .user-location .glass-container .search-container input {
    font-size: 13px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .welcome .user-location .glass-container .search-container input {
    padding-left: 30px;
  }
}
#root .container .welcome .user-location .glass-container .search-container .search-icon {
  position: absolute;
  z-index: 1010;
  margin-top: 24px;
  margin-left: 10px;
  color: #bbb;
  font-size: 16px;
}
@media screen and (max-width: 350px) {
  #root .container .welcome .user-location .glass-container .search-container .search-icon {
    font-size: 13px;
  }
}
#root .container .welcome .in-spinner {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
#root .container .welcome .in-spinner div {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
}
#root .container .welcome .in-spinner .one {
  animation: spin2 1.2s linear infinite;
  border-top-color: #ff7a5a;
}
#root .container .welcome .in-spinner .two {
  animation: spin3 1.2s linear infinite;
  border-bottom-color: #ff7a5a;
}
@keyframes spin2 {
  0% {
    border-width: 8px;
    transform: rotate(0deg);
  }
  50% {
    border-width: 3px;
    transform: rotate(180deg);
  }
  100% {
    border-width: 8px;
    transform: rotate(360deg);
  }
}
@keyframes spin3 {
  0% {
    border-width: 3px;
    transform: rotate(0deg);
  }
  50% {
    border-width: 8px;
    transform: rotate(180deg);
  }
  100% {
    border-width: 3px;
    transform: rotate(360deg);
  }
}
#root .container .welcome .link-to-shops {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  height: 88%;
}
#root .container .welcome .btn-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .welcome .btn-container span {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.557);
  margin: 10px;
  cursor: pointer;
}
#root .container .welcome .shopping-process-container {
  position: relative;
  bottom: 0;
  height: 50%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 450px;
  padding: 0 20px 0 20px;
}
@media screen and (max-width: 1570px) {
  #root .container .welcome .shopping-process-container {
    width: 90%;
  }
}
@media screen and (max-width: 1280px) {
  #root .container .welcome .shopping-process-container {
    grid-template-rows: 350px;
  }
}
@media screen and (max-width: 1500px) {
  #root .container .welcome .shopping-process-container {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  #root .container .welcome .shopping-process-container {
    grid-template-rows: 220px;
  }
}
@media screen and (max-width: 570px) {
  #root .container .welcome .shopping-process-container {
    display: none;
  }
}
#root .container .welcome .shopping-process-container .shopping-process-card {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  padding: 40px;
  border-radius: 12px;
  margin-right: 30px;
  margin-top: 40px;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
#root .container .welcome .shopping-process-container .shopping-process-card:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .welcome .shopping-process-container .shopping-process-card:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 980px) {
  #root .container .welcome .shopping-process-container .shopping-process-card {
    padding: 15px;
    margin-top: 20px;
    margin-right: 15px;
  }
}
#root .container .welcome .shopping-process-container .shopping-process-card .step {
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: bolder;
  color: #ff7a5a;
}
@media screen and (max-width: 1280px) {
  #root .container .welcome .shopping-process-container .shopping-process-card .step {
    font-size: 40px;
  }
}
@media screen and (max-width: 980px) {
  #root .container .welcome .shopping-process-container .shopping-process-card .step {
    font-size: 24px;
  }
}
@media screen and (max-width: 650px) {
  #root .container .welcome .shopping-process-container .shopping-process-card .step {
    font-size: 16px;
  }
}
#root .container .welcome .shopping-process-container .shopping-process-card .desc {
  font-size: 30px;
  font-weight: 600;
  flex: 1;
  color: #53281d;
}
@media screen and (max-width: 1280px) {
  #root .container .welcome .shopping-process-container .shopping-process-card .desc {
    font-size: 20px;
  }
}
@media screen and (max-width: 980px) {
  #root .container .welcome .shopping-process-container .shopping-process-card .desc {
    font-size: 14px;
    font-weight: 500;
  }
}
@media screen and (max-width: 650px) {
  #root .container .welcome .shopping-process-container .shopping-process-card .desc {
    font-weight: 400;
  }
}
#root .container .welcome .shopping-process-container .shopping-process-card .line {
  width: 30px;
  height: 2px;
  background-color: #ff7a5a;
  margin-top: 20px;
}
@media screen and (max-width: 980px) {
  #root .container .welcome .shopping-process-container .shopping-process-card .line {
    margin-top: 0px;
  }
}
#root .container .content-container {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
#root .container .content-container .main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
}
@media screen and (max-width: 1570px) {
  #root .container .content-container .main-container {
    width: 90%;
  }
}
@media screen and (max-width: 1500px) {
  #root .container .content-container .main-container {
    width: 100%;
  }
}
#root .container .content-container .main-container .top-products {
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 570px) {
  #root .container .content-container .main-container .top-products {
    padding-top: 24px;
  }
}
#root .container .content-container .main-container .top-products .heading {
  position: relative;
  display: flex;
  justify-content: center;
}
#root .container .content-container .main-container .top-products .heading span {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 120px;
  background-color: rgb(255, 223, 214);
  height: 10px;
}
#root .container .content-container .main-container .top-products .heading h1 {
  font-size: 60px;
  font-weight: 500;
  text-align: left;
}
@media screen and (max-width: 1300px) {
  #root .container .content-container .main-container .top-products .heading h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .top-products .heading h1 {
    font-size: 40px;
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
  #root .container .content-container .main-container .top-products .heading h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 260px) {
  #root .container .content-container .main-container .top-products .heading h1 {
    font-size: 20px;
  }
}
#root .container .content-container .main-container .top-products .prods-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
  margin-top: 40px;
}
@media screen and (min-width: 800px) {
  #root .container .content-container .main-container .top-products .prods-container {
    margin-top: 60px;
  }
}
#root .container .content-container .main-container .top-products .prods-container .heading {
  padding: 18px 10px;
  width: 100%;
  border: 1px solid rgb(233, 229, 228);
}
#root .container .content-container .main-container .top-products .prods-container .heading h2 {
  color: #777;
  font-weight: 500;
  font-size: 24px;
}
#root .container .content-container .main-container .top-products .prods-container .more {
  border: 1px solid #bbb;
  color: #ff7a5a;
  padding: 10px 36px;
  margin-top: 30px;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  margin: 30px auto auto auto;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container {
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}
@media screen and (max-width: 400px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 800px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 350px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card {
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  margin: 10px;
  background-color: #fff;
  overflow: hidden;
}
@media screen and (max-width: 351px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card {
    margin: 5px;
    padding: 0px;
    box-shadow: none;
    border: 1px solid #ddd;
  }
}
@media screen and (max-width: 500px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card {
    margin: 0px;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid #ddd;
  }
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card:hover {
    box-shadow: none;
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img {
  height: 350px;
  width: 100%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 5px 5px 0px 0px;
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img {
    height: 220px;
    border-radius: 0px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 270px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img {
    height: 200px;
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 500px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img img {
    border-radius: 0px;
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-img p {
  color: #777;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .load {
  background-color: #ddd;
  color: #ddd;
  margin-top: 5px;
  overflow: hidden;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .load:first-child {
  margin-top: 0px;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .load span {
  color: #ddd;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .load:nth-child(2) .load:nth-child(2) {
  width: 80%;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) {
  width: 40%;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) h3 {
  color: #ddd;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .load:nth-child(2) form button {
  width: 100%;
  padding: 10px 24px;
  background-color: #ddd;
  border: none;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .slide-anime {
  background-color: rgba(255, 255, 255, 0.513);
  box-shadow: 24px 3px 36px rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8%;
  animation: light-slide 2.5s linear infinite;
}
@keyframes light-slide {
  0% {
    left: 0%;
    width: 8%;
    background-color: rgba(255, 255, 255, 0.513);
  }
  50% {
    left: 100%;
    width: 5%;
    background-color: rgb(255, 255, 255);
  }
  70% {
    width: 2%;
  }
  100% {
    left: 100%;
    width: 2%;
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info {
  width: 100%;
  background-color: transparent;
  padding: 10px;
  margin-top: 0px;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info p {
  color: #333;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.4rem;
  max-height: 4.4rem;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info p {
    font-size: 16px;
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .prod-name {
  height: 48px;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .shop-name {
  color: #777;
  font-size: 14px;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .shop-name span {
  font-style: italic;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .shop-name .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes h3 {
  flex: 1;
  font-size: 18px;
  font-weight: bolder;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes h3 {
    font-size: 16px;
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes p.likes {
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 0px 4px;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes p.likes i:hover {
  color: #ff7a5a;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes p.likes i.active {
  color: #ff7a5a;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes p.likes span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info .price-likes p.likes span.stat.active {
  color: #ff7a5a;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info form {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info form button {
    padding: 8px 12px;
  }
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info form .unlike {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .content-container .main-container .top-products .prods-container .prod-card-container .card .prod-info form .unlike:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .content-container .main-container .product-categories {
  width: 100%;
  padding-top: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 800px) {
  #root .container .content-container .main-container .product-categories {
    padding-top: 120px;
  }
}
@media screen and (min-width: 800px) {
  #root .container .content-container .main-container .product-categories {
    padding-top: 120px;
  }
}
#root .container .content-container .main-container .product-categories .heading {
  position: relative;
  display: flex;
  justify-content: center;
}
#root .container .content-container .main-container .product-categories .heading span {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 120px;
  background-color: rgb(255, 223, 214);
  height: 10px;
}
#root .container .content-container .main-container .product-categories .heading h1 {
  font-size: 60px;
  font-weight: 500;
  text-align: left;
}
@media screen and (max-width: 1300px) {
  #root .container .content-container .main-container .product-categories .heading h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .product-categories .heading h1 {
    font-size: 40px;
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
  #root .container .content-container .main-container .product-categories .heading h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 260px) {
  #root .container .content-container .main-container .product-categories .heading h1 {
    font-size: 20px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
#root .container .content-container .main-container .product-categories .prods-container .heading {
  padding: 18px 10px;
  width: 100%;
  border: 1px solid rgb(219, 217, 217);
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .product-categories .prods-container .heading {
    padding: 10px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .heading:first-child {
  margin-top: 0px;
}
#root .container .content-container .main-container .product-categories .prods-container .heading h2 {
  position: relative;
  color: #777;
  font-weight: 500;
  font-size: 24px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .product-categories .prods-container .heading h2 {
    font-size: 18px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .more {
  border: 1px solid #bbb;
  color: #ff7a5a;
  padding: 10px 36px;
  margin-top: 30px;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  margin: 30px auto auto auto;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container {
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}
@media screen and (max-width: 400px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 800px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 350px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card {
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  margin: 10px;
  background-color: #fff;
  overflow: hidden;
}
@media screen and (max-width: 351px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card {
    margin: 5px;
    padding: 0px;
    box-shadow: none;
    border: 1px solid #ddd;
  }
}
@media screen and (max-width: 500px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card {
    margin: 0px;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid #ddd;
  }
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card:hover {
    box-shadow: none;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img {
  height: 350px;
  width: 100%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 5px 5px 0px 0px;
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img {
    height: 220px;
    border-radius: 0px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 270px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img {
    height: 200px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 500px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img img {
    border-radius: 0px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-img p {
  color: #777;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .load {
  background-color: #ddd;
  color: #ddd;
  margin-top: 5px;
  overflow: hidden;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .load:first-child {
  margin-top: 0px;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .load span {
  color: #ddd;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .load:nth-child(2) .load:nth-child(2) {
  width: 80%;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) {
  width: 40%;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) h3 {
  color: #ddd;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .load:nth-child(2) form button {
  width: 100%;
  padding: 10px 24px;
  background-color: #ddd;
  border: none;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .slide-anime {
  background-color: rgba(255, 255, 255, 0.513);
  box-shadow: 24px 3px 36px rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8%;
  animation: light-slide 2.5s linear infinite;
}
@keyframes light-slide {
  0% {
    left: 0%;
    width: 8%;
    background-color: rgba(255, 255, 255, 0.513);
  }
  50% {
    left: 100%;
    width: 5%;
    background-color: rgb(255, 255, 255);
  }
  70% {
    width: 2%;
  }
  100% {
    left: 100%;
    width: 2%;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info {
  width: 100%;
  background-color: transparent;
  padding: 10px;
  margin-top: 0px;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info p {
  color: #333;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.4rem;
  max-height: 4.4rem;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info p {
    font-size: 16px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .prod-name {
  height: 48px;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .shop-name {
  color: #777;
  font-size: 14px;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .shop-name span {
  font-style: italic;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .shop-name .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes h3 {
  flex: 1;
  font-size: 18px;
  font-weight: bolder;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes h3 {
    font-size: 16px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes p.likes {
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 0px 4px;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes p.likes i:hover {
  color: #ff7a5a;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes p.likes i.active {
  color: #ff7a5a;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes p.likes span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info .price-likes p.likes span.stat.active {
  color: #ff7a5a;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info form {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info form button {
    padding: 8px 12px;
  }
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info form .unlike {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .content-container .main-container .product-categories .prods-container .prod-card-container .card .prod-info form .unlike:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .content-container .main-container .top-shops {
  width: 100%;
  padding-top: 40px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 800px) {
  #root .container .content-container .main-container .top-shops {
    padding-top: 120px;
  }
}
#root .container .content-container .main-container .top-shops .heading {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
#root .container .content-container .main-container .top-shops .heading span {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 120px;
  background-color: rgb(255, 223, 214);
  height: 10px;
}
#root .container .content-container .main-container .top-shops .heading h1 {
  font-size: 60px;
  font-weight: 500;
  text-align: left;
}
@media screen and (max-width: 1300px) {
  #root .container .content-container .main-container .top-shops .heading h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .top-shops .heading h1 {
    font-size: 40px;
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
  #root .container .content-container .main-container .top-shops .heading h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 260px) {
  #root .container .content-container .main-container .top-shops .heading h1 {
    font-size: 20px;
  }
}
#root .container .content-container .main-container .top-shops .slide-msg {
  margin-top: 20px;
  color: #bbb;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#root .container .content-container .main-container .top-shops .slide-msg i {
  margin: auto 10px;
}
#root .container .content-container .main-container .top-shops .embla {
  width: 60%;
  align-self: center;
}
@media screen and (max-width: 900px) {
  #root .container .content-container .main-container .top-shops .embla {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-shops .embla {
    width: 100%;
  }
}
#root .container .content-container .main-container .top-shops .shops-card-container {
  margin-top: 40px;
  padding: 20px;
}
@media screen and (min-width: 800px) {
  #root .container .content-container .main-container .top-shops .shops-card-container {
    margin-top: 60px;
  }
}
@media screen and (max-width: 800px) {
  #root .container .content-container .main-container .top-shops .shops-card-container {
    padding: 0px;
  }
  #root .container .content-container .main-container .top-shops .shops-card-container::-webkit-scrollbar {
    display: none;
  }
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card {
  text-decoration: none;
  color: #000;
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  margin: 8px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  display: inline-block;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 1000px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card {
    width: 100%;
    margin: 5px;
  }
}
@media screen and (max-width: 400px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card {
    padding: 8px 10px;
    box-shadow: none;
    border-radius: 0px;
    border-left: none;
  }
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 {
  flex: 1;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row {
  display: flex;
  flex-direction: row;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .img {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background-color: #ddd;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-left: 10px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .text h1 {
  font-size: 20px;
  font-weight: bolder;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 720px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .text h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .text h1 {
    font-size: 16px;
  }
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .text .location {
  color: #777;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  font-size: 15px;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .top-row .text .location {
    font-size: 14px;
  }
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .bottom-row {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .bottom-row p.aboutShop {
  color: #000;
  font-weight: 500;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4rem;
  max-height: 4.4rem;
  font-size: 20px;
}
@media screen and (max-width: 720px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .bottom-row p.aboutShop {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .bottom-row p.aboutShop {
    font-size: 16px;
  }
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 4px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .open {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: rgb(14, 136, 14);
  font-size: 14px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .open i {
  margin-right: 6px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .open .text {
  color: #000;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .open .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .closed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: rgb(255, 43, 43);
  font-size: 14px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .closed i {
  margin-right: 6px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .closed .text {
  color: #000;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .whs .closed .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .stats {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .stats p {
  position: relative;
  font-size: 14px;
  color: #777;
  display: flex;
  align-items: center;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .stats p span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-1 .stats .visits {
  flex: 1;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  padding: 0px;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-2 form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-2 form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  #root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-2 form button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-2 form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-2 form .unfollow {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .content-container .main-container .top-shops .shops-card-container .shop-card .col-2 form .unfollow:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .content-container .main-container .pricing-container {
  padding: 100px 10px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 800px) {
  #root .container .content-container .main-container .pricing-container {
    padding: 60px 10px;
  }
}
#root .container .content-container .main-container .pricing-container #intro-head {
  font-size: 20px;
  font-weight: 500;
  color: #555;
  margin-bottom: 10px;
}
#root .container .content-container .main-container .pricing-container .heading {
  position: relative;
  display: flex;
  justify-content: center;
}
#root .container .content-container .main-container .pricing-container .heading span {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0px);
  width: 120px;
  background-color: rgb(255, 223, 214);
  height: 10px;
}
#root .container .content-container .main-container .pricing-container .heading h1 {
  font-size: 60px;
  font-weight: 500;
  text-align: left;
}
@media screen and (max-width: 1300px) {
  #root .container .content-container .main-container .pricing-container .heading h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .content-container .main-container .pricing-container .heading h1 {
    font-size: 40px;
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
  #root .container .content-container .main-container .pricing-container .heading h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 260px) {
  #root .container .content-container .main-container .pricing-container .heading h1 {
    font-size: 20px;
  }
}
#root .container .content-container .main-container .pricing-container .pricing-content {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  #root .container .content-container .main-container .pricing-container .pricing-content {
    margin-top: 40px;
  }
}
#root .container .content-container .main-container .pricing-container .pricing-content .card {
  width: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
  box-shadow: 0px 1px 3px rgba(124, 107, 101, 0.5);
  border-radius: 5px;
  background-color: #fff;
}
@media screen and (max-width: 800px) {
  #root .container .content-container .main-container .pricing-container .pricing-content .card {
    margin: 10px;
  }
}
@media screen and (max-width: 330px) {
  #root .container .content-container .main-container .pricing-container .pricing-content .card {
    width: 100%;
  }
}
#root .container .content-container .main-container .pricing-container .pricing-content .card h2 {
  font-size: 36px;
  font-weight: 500;
  align-self: center;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card p {
  font-style: 16px;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card .price {
  margin-top: 10px;
  color: #ff7a5a;
  align-self: center;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card .includes {
  margin-top: 20px;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card ul li {
  margin-left: 20px;
  color: #777;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card ul li:first-child {
  margin-top: 10px;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card a {
  margin-top: 50px;
  text-decoration: none;
  color: #000;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  font-weight: 300;
  padding: 10px;
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  padding: 12px 40px;
  text-align: center;
  background-color: #ff7a5a;
  color: #fff;
  font-size: 16px;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card a:hover {
  color: #ff7a5a;
}
#root .container .content-container .main-container .pricing-container .pricing-content .card a:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
@media screen and (max-width: 900px) {
  #root .container .content-container .main-container .pricing-container .pricing-content .card a {
    padding: 12px 36px;
  }
}
#root .container .content-container .main-container .pricing-container .pricing-content .card a:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 15px;
  min-height: 94vh;
  width: 100%;
}
@media screen and (max-width: 900px) {
  #root .container .about {
    padding: 0px 16px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .about {
    padding: 0px 10px;
  }
}
#root .container .about .about-container {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
}
@media screen and (max-width: 1500px) {
  #root .container .about .about-container {
    width: 70%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .about .about-container {
    width: 85%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .about .about-container {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .about .about-container {
    width: 100%;
  }
}
#root .container .about .about-container .about-app {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .about .about-container .about-app img {
  width: 200px;
  height: 200px;
}
@media screen and (max-width: 280px) {
  #root .container .about .about-container .about-app img {
    width: 160px;
    height: 160px;
  }
}
#root .container .about .about-container .about-app h1 {
  font-size: 3em;
  text-align: center;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  #root .container .about .about-container .about-app h1 {
    font-size: 30px;
  }
}
#root .container .about .about-container .about-app p {
  margin-top: 10px;
  text-align: center;
  font-size: 24px;
}
#root .container .about .about-container .about-app p span {
  color: #777;
}
@media screen and (max-width: 600px) {
  #root .container .about .about-container .about-app p {
    font-size: 20px;
  }
}
#root .container .about .about-container .more-info {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  background-color: transparent;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  #root .container .about .about-container .more-info {
    padding: 0px;
  }
}
#root .container .about .about-container .more-info .title {
  color: #777;
  margin-top: 10px;
}
#root .container .about .about-container .more-info span {
  color: #777;
  margin-top: 10px;
}
#root .container .about .about-container .more-info h2 {
  display: flex;
  background-color: #ff7a5a;
  padding: 6px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}
#root .container .about .about-container .more-info h2 p {
  flex: 1;
  color: #fff;
  font-size: 24px;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  #root .container .about .about-container .more-info h2 p {
    font-size: 20px;
  }
}
#root .container .about .about-container .more-info h2 i {
  color: #fff;
  font-size: 24px;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  #root .container .about .about-container .more-info h2 i {
    font-size: 20px;
  }
}
#root .container .about .about-container .more-info .hide {
  background-color: #fff;
  animation: scale 0.3s linear 1;
  padding: 5px;
  width: 100%;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  transform-origin: top;
}
@keyframes scale {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
#root .container .about .about-container .more-info .founder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .about .about-container .more-info .founder .img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
#root .container .about .about-container .more-info .founder .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .about .about-container .more-info .founder p {
  text-align: center;
}
#root .container .about .about-container .more-info p {
  font-size: 18px;
}
@media screen and (max-width: 600px) {
  #root .container .about .about-container .more-info p {
    font-size: 16px;
  }
}
#root .container .about .about-container .more-info a {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ff7a5a;
}
#root .container footer {
  position: relative;
  width: 100%;
  padding: 0px 8px;
  background: #181210;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99;
  z-index: 100;
}
#root .container footer .footer-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 60px;
}
@media screen and (max-width: 630px) {
  #root .container footer .footer-card-container {
    flex-direction: column;
  }
}
#root .container footer .footer-card-container .footer-card {
  width: 40%;
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
@media screen and (max-width: 630px) {
  #root .container footer .footer-card-container .footer-card {
    margin-top: 30px;
  }
}
#root .container footer .footer-card-container .footer-card h2 {
  color: #ddd;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
#root .container footer .footer-card-container .footer-card a.button {
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: none;
  color: #000;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  font-weight: 300;
  padding: 10px;
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  padding: 12px 40px;
  text-align: center;
  background-color: #ff7a5a;
  color: #fff;
  font-size: 16px;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
}
#root .container footer .footer-card-container .footer-card a.button:hover {
  color: #ff7a5a;
}
#root .container footer .footer-card-container .footer-card a.button:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
@media screen and (max-width: 900px) {
  #root .container footer .footer-card-container .footer-card a.button {
    padding: 12px 36px;
  }
}
#root .container footer .footer-card-container .footer-card a.button:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container footer .footer-card-container .footer-card a.link {
  text-decoration: none;
  font-size: 16px;
  color: #bbb;
  margin-top: 10px;
  transition: all 300ms ease-in-out;
  text-align: center;
}
#root .container footer .footer-card-container .footer-card a.link:hover {
  color: #ff7a5a;
}
#root .container footer .footer-card-container .footer-card a.link i {
  font-size: 20px;
  margin-right: 6px;
}
#root .container footer .copyright {
  margin-top: 60px;
  font-size: 16px;
  color: #ddd;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 10px;
}
@media screen and (max-width: 500px) {
  #root .container footer .copyright {
    font-size: 13px;
  }
}
#root .container footer .copyright .logos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container footer .copyright .logos img {
  width: 60px;
  height: 30px;
  margin: 6px;
}
@media screen and (max-width: 400px) {
  #root .container footer .copyright .logos img {
    width: 40px;
    height: 20px;
  }
}
#root .container footer .copyright .logos img:nth-child(1) {
  width: 90px;
  height: 40px;
}
@media screen and (max-width: 400px) {
  #root .container footer .copyright .logos img:nth-child(1) {
    height: 30px;
  }
}
@media screen and (max-width: 900px) {
  #root .container footer .raise {
    margin-bottom: 40px;
  }
}
#root .container .in-app {
  padding: 0px 8px 60px 8px;
}
#root .container .shops {
  width: 100%;
  min-height: 94vh;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 10px;
  justify-content: flex-start;
}
@media screen and (max-width: 400px) {
  #root .container .shops {
    padding: 0px;
  }
}
@media screen and (max-width: 900px) {
  #root .container .shops {
    margin-top: 0px;
  }
}
#root .container .shops .heading {
  margin-top: 12px;
  width: 100%;
  background-color: rgb(255, 223, 214);
  padding: 5px;
  border-radius: 5px;
  border-left: 5px solid #ff7a5a;
}
@media screen and (max-width: 600px) {
  #root .container .shops .heading {
    border-radius: 0px;
  }
}
#root .container .shops .heading h1 {
  font-size: 20px;
  color: #ff7a5a;
  padding: 4px;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 720px) {
  #root .container .shops .heading h1 {
    font-size: 18px;
  }
}
#root .container .shops .heading {
  width: 50%;
}
@media screen and (max-width: 1000px) {
  #root .container .shops .heading {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .shops .heading {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shops .heading {
    width: 100%;
  }
}
#root .container .shops .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .shops .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .shops .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .shops .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .shops .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
  margin-top: 10px;
}
#root .container .shops .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .shops .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .shops .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .shops .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .shops .shop-card {
  text-decoration: none;
  color: #000;
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
}
#root .container .shops .shop-card:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .shops .shop-card:last-child {
  margin-bottom: 50px;
}
@media screen and (max-width: 1000px) {
  #root .container .shops .shop-card {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .shops .shop-card {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shops .shop-card {
    width: 100%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 400px) {
  #root .container .shops .shop-card {
    margin: 0px;
    margin-top: 15px;
    padding: 8px 10px;
    box-shadow: none;
    border-radius: 0px;
    border-left: none;
  }
}
#root .container .shops .shop-card .col-1 {
  flex: 1;
}
#root .container .shops .shop-card .col-1 .top-row {
  display: flex;
  flex-direction: row;
}
#root .container .shops .shop-card .col-1 .top-row .img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #ddd;
}
#root .container .shops .shop-card .col-1 .top-row .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .shops .shop-card .col-1 .top-row .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-left: 10px;
}
#root .container .shops .shop-card .col-1 .top-row .text h1 {
  font-size: 20px;
  font-weight: bolder;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
@media screen and (max-width: 720px) {
  #root .container .shops .shop-card .col-1 .top-row .text h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shops .shop-card .col-1 .top-row .text h1 {
    font-size: 16px;
  }
}
#root .container .shops .shop-card .col-1 .top-row .text h1 .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .shops .shop-card .col-1 .top-row .text .location {
  color: #777;
  font-size: 15px;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
}
@media screen and (max-width: 600px) {
  #root .container .shops .shop-card .col-1 .top-row .text .location {
    font-size: 14px;
  }
}
#root .container .shops .shop-card .col-1 .bottom-row {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}
#root .container .shops .shop-card .col-1 .bottom-row p.aboutShop {
  color: #000;
  font-weight: 500;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: 400;
  letter-spacing: normal;
  font-weight: 500;
  line-height: 1.4rem;
  max-height: 4.4rem;
  font-size: 20px;
}
@media screen and (max-width: 720px) {
  #root .container .shops .shop-card .col-1 .bottom-row p.aboutShop {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shops .shop-card .col-1 .bottom-row p.aboutShop {
    font-size: 16px;
  }
}
#root .container .shops .shop-card .col-1 .whs {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 4px;
}
#root .container .shops .shop-card .col-1 .whs .open {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: rgb(14, 136, 14);
  font-size: 14px;
}
#root .container .shops .shop-card .col-1 .whs .open i {
  margin-right: 6px;
}
#root .container .shops .shop-card .col-1 .whs .open .text {
  color: #000;
}
#root .container .shops .shop-card .col-1 .whs .open .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .shops .shop-card .col-1 .whs .closed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: rgb(255, 43, 43);
  font-size: 14px;
}
#root .container .shops .shop-card .col-1 .whs .closed i {
  margin-right: 6px;
}
#root .container .shops .shop-card .col-1 .whs .closed .text {
  color: #000;
}
#root .container .shops .shop-card .col-1 .whs .closed .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .shops .shop-card .col-1 .stats {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
}
#root .container .shops .shop-card .col-1 .stats p {
  position: relative;
  font-size: 14px;
  color: #777;
  display: flex;
  align-items: center;
}
#root .container .shops .shop-card .col-1 .stats p span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .shops .shop-card .col-1 .stats .visits {
  flex: 1;
}
#root .container .shops .shop-card .col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  padding: 0px;
}
#root .container .shops .shop-card .col-2 form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .shops .shop-card .col-2 form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  #root .container .shops .shop-card .col-2 form button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
#root .container .shops .shop-card .col-2 form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .shops .shop-card .col-2 form .unfollow {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .shops .shop-card .col-2 form .unfollow:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .chat-box {
  position: fixed;
  bottom: 100px;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 100;
  padding: 0px 24px;
}
@media screen and (max-width: 900px) {
  #root .container .chat-box {
    padding: 0px 16px;
    display: none;
  }
}
@media screen and (max-width: 600px) {
  #root .container .chat-box {
    padding: 0px 10px;
  }
}
#root .container .chat-box .chat-box-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
@media screen and (max-width: 1400px) {
  #root .container .chat-box .chat-box-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .chat-box .chat-box-container {
    width: 100%;
  }
}
#root .container .chat-box .chat-box-container a {
  font-size: 24px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff7a5a;
  border-radius: 50px;
  text-decoration: none;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
}
#root .container .chat {
  position: sticky;
  top: 0;
  width: 100%;
  background: linear-gradient(to bottom, #eee, rgba(255, 122, 90, 0.5));
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100vh;
  z-index: 100;
  overflow: auto;
}
@media screen and (max-width: 900px) {
  #root .container .chat {
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  #root .container .chat {
    padding: 0px 0px;
  }
}
#root .container .chat .chat-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 80%;
  background-color: #fff;
  width: 50%;
  box-shadow: 0 1px 12px 0 rgba(63, 51, 48, 0.45);
}
@media screen and (max-width: 1600px) {
  #root .container .chat .chat-container {
    height: 90%;
  }
}
@media screen and (max-width: 1500px) {
  #root .container .chat .chat-container {
    width: 70%;
    height: 100%;
    box-shadow: none;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .chat .chat-container {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .chat .chat-container {
    width: 100%;
  }
}
#root .container .chat .chat-container .leaving-room {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 90;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  padding: 10px;
  animation: scaling 0.2s linear 1;
  transform-origin: top;
}
@keyframes scaling {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
#root .container .chat .chat-container .leaving-room h3 {
  justify-self: flex-start;
  color: #fff;
  background-color: #ff7a5a;
  padding: 10px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}
#root .container .chat .chat-container .leaving-room button {
  padding: 10px 24px;
  color: #ff7a5a;
  font-size: 20px;
  border: none;
  background-color: transparent;
  outline: none;
  margin-top: 20px;
  cursor: pointer;
}
#root .container .chat .chat-container .chat-nav {
  position: relative;
  width: 100%;
  background-color: #53281d;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  box-shadow: 0 1px 10px 0 rgba(63, 51, 48, 0.9);
  z-index: 85;
}
#root .container .chat .chat-container .chat-nav .join {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
  z-index: 90;
  animation: scaling 0.2s linear 1;
  transform-origin: top;
  overflow: hidden;
}
@keyframes scaling {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
#root .container .chat .chat-container .chat-nav .join h3 {
  flex: 1;
  font-size: 14px;
  color: #00aaa0;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .container .chat .chat-container .chat-nav .join button {
  font-size: 20px;
  outline: none;
  border: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  padding: 0px 10px;
}
#root .container .chat .chat-container .chat-nav .user-img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #eee;
}
#root .container .chat .chat-container .chat-nav .user-img img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}
#root .container .chat .chat-container .chat-nav .about-user {
  flex: 1;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 10px;
  overflow: hidden;
}
#root .container .chat .chat-container .chat-nav .about-user p {
  font-size: 18px;
  color: #fff;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 450;
}
@media screen and (min-width: 900px) {
  #root .container .chat .chat-container .chat-nav .about-user p {
    font-weight: 550;
  }
}
#root .container .chat .chat-container .chat-nav .about-user .online-offline {
  font-size: 14px;
}
#root .container .chat .chat-container .chat-nav .about-user .offline {
  color: #fff;
}
#root .container .chat .chat-container .chat-nav .about-user .online {
  color: rgb(113, 255, 113);
}
#root .container .chat .chat-container .chat-nav .chat-menu {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}
#root .container .chat .chat-container .chat-nav .chat-menu:hover {
  background-color: #6d4d46;
}
#root .container .chat .chat-container .chat-nav .chat-menu p {
  font-size: 16px;
  color: #fff;
  font-weight: 450;
  pointer-events: none;
}
@media screen and (min-width: 900px) {
  #root .container .chat .chat-container .chat-nav .chat-menu p {
    font-weight: 550;
  }
}
#root .container .chat .chat-container .chat-nav .chat-menu-links {
  position: absolute;
  top: 104%;
  right: 3%;
  background-color: #6d4d46;
  box-shadow: 0px 3px 24px rgba(60, 61, 67, 0.9);
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 90;
  display: none;
  animation: scaling 0.2s linear 1;
  transform-origin: top;
}
@keyframes scaling {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
#root .container .chat .chat-container .chat-nav .chat-menu-links span {
  position: absolute;
  top: -2%;
  right: 4%;
  background-color: #6d4d46;
  width: 30px;
  height: 30px;
  transform: rotate(60deg);
  z-index: 2;
}
#root .container .chat .chat-container .chat-nav .chat-menu-links a {
  padding: 15px 48px 15px 15px;
  color: #eee;
  text-decoration: none;
  background-color: transparent;
  word-wrap: none;
  word-break: keep-all;
  transition: all 300ms ease-in-out;
  z-index: 4;
  text-align: left;
  display: inline-flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
#root .container .chat .chat-container .chat-nav .chat-menu-links a i {
  margin-right: 10px;
}
#root .container .chat .chat-container .chat-nav .chat-menu-links a:hover {
  background-color: #745f5b;
}
#root .container .chat .chat-container .chat-msg-display {
  position: relative;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  z-index: 80;
}
#root .container .chat .chat-container .chat-msg-display:nth-child(2) {
  border-top: 2px solid #ddd;
}
#root .container .chat .chat-container .chat-msg-display::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  margin-right: 12px;
}
#root .container .chat .chat-container .chat-msg-display::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
}
#root .container .chat .chat-container .chat-msg-display::-webkit-scrollbar-thumb {
  background: rgba(112, 102, 100, 0.4196078431);
  width: 2px;
}
#root .container .chat .chat-container .chat-msg-display ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
#root .container .chat .chat-container .chat-msg-display ul li {
  position: relative;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  clear: both;
  color: #eee;
  margin-bottom: 6px;
  word-break: break-word;
  padding: 4px;
  font-size: 14px;
  font-weight: 450;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  letter-spacing: 1px;
}
@media screen and (min-width: 900px) {
  #root .container .chat .chat-container .chat-msg-display ul li {
    font-weight: 550;
  }
}
#root .container .chat .chat-container .chat-msg-display ul li h3 {
  position: relative;
  top: 0;
  left: 0;
  font-size: 15px;
  color: #eee;
  z-index: 4;
  width: 100%;
  margin-bottom: 6px;
  padding: 6px 10px 6px 4px;
  background-color: #6d4d46;
  border-radius: 0px 50px 30px 0px;
  border-left: 4px solid #ff7a5a;
  font-weight: 450;
}
@media screen and (min-width: 900px) {
  #root .container .chat .chat-container .chat-msg-display ul li h3 {
    font-weight: 550;
  }
}
#root .container .chat .chat-container .chat-msg-display ul li p {
  padding: 0px 10px 6px 10px;
}
#root .container .chat .chat-container .chat-msg-display ul li button {
  margin-top: 12px;
  background: red;
  border: none;
  outline: none;
  width: 100%;
  padding: 6px;
  color: #fff;
  border-radius: 5px;
}
#root .container .chat .chat-container .chat-msg-display ul #me {
  float: right;
  background: #ff7a5a;
  border-radius: 30px 0px 30px 30px;
  padding: 10px 14px 10px 14px;
}
#root .container .chat .chat-container .chat-msg-display ul #them {
  float: left;
  background: #53281d;
  border-radius: 0px 30px 30px 30px;
}
#root .container .chat .chat-container .chat-msg-display ul #them + #me {
  border-top-right-radius: 5px;
}
#root .container .chat .chat-container .chat-msg-display ul #me + #me {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
#root .container .chat .chat-container .chat-msg-display ul #me:last-of-type {
  border-bottom-right-radius: 30px;
}
#root .container .chat .chat-container .chat-msg-form {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
}
#root .container .chat .chat-container .chat-msg-form form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .chat .chat-container .chat-msg-form form textarea {
  flex: 1;
  height: 50px;
  padding: 16px;
  border-radius: 99px;
  resize: none;
  outline: none;
  border: none;
  background-color: #53281d;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-weight: 450;
  font-size: 14px;
  letter-spacing: 1px;
}
@media screen and (min-width: 900px) {
  #root .container .chat .chat-container .chat-msg-form form textarea {
    font-weight: 550;
  }
}
#root .container .chat .chat-container .chat-msg-form form textarea::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  margin-right: 12px;
}
#root .container .chat .chat-container .chat-msg-form form textarea::-webkit-scrollbar-track {
  border: none;
  background-color: transparent;
}
#root .container .chat .chat-container .chat-msg-form form textarea::-webkit-scrollbar-thumb {
  background: rgba(112, 102, 100, 0.4196078431);
  width: 2px;
}
#root .container .chat .chat-container .chat-msg-form form button {
  height: 50px;
  width: 50px;
  margin-left: 10px;
  border-radius: 50px;
  outline: none;
  border: none;
  background-color: #ff7a5a;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  color: #fff;
  font-size: 20px;
  font-weight: 550;
  cursor: pointer;
}
@media screen and (min-width: 900px) {
  #root .container .chat .chat-container .chat-msg-form form button {
    font-weight: 550;
  }
}
#root .container .bottom-nav {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: none;
  padding: 0px 24px;
  z-index: 100;
}
@media screen and (max-width: 900px) {
  #root .container .bottom-nav {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .bottom-nav {
    padding: 0px 10px;
  }
}
@media screen and (max-width: 900px) {
  #root .container .bottom-nav {
    display: flex;
  }
}
#root .container .bottom-nav .nav-container {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: transparent;
}
@media screen and (max-width: 280px) {
  #root .container .bottom-nav .nav-container {
    width: 35%;
  }
}
#root .container .bottom-nav .nav-container a {
  text-decoration: none;
  border: none;
  outline: none;
  color: #999;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
}
@media screen and (max-width: 300px) {
  #root .container .bottom-nav .nav-container a {
    padding: 5px 10px;
  }
}
#root .container .bottom-nav .nav-container a p {
  font-size: 10px;
}
#root .container .bottom-nav .nav-container a i {
  font-size: 15px;
  padding: 6px 0px;
}
#root .container .bottom-nav .nav-container .inbox {
  position: absolute;
  top: -12px;
  background-color: #ff7a5a;
  border-radius: 50px;
  font-size: 11px;
  font-weight: bold;
  padding: 4px 8px;
  color: #fff;
}
#root .container .bottom-nav .nav-container .active {
  color: #ff7a5a;
}
#root .container .bottom-nav .mini-menu {
  position: absolute;
  right: 10px;
  top: -80%;
  background-color: #777;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.7);
  width: 200px;
  border-radius: 5px;
  z-index: 100;
  display: none;
  animation: scaling 0.1s linear 1;
}
@keyframes scaling {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
#root .container .bottom-nav .mini-menu a {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #ddd;
  padding: 10px;
  z-index: 100;
  border-bottom: 1px solid #999;
}
#root .container .bottom-nav .mini-menu a:last-of-type {
  border-bottom: none;
}
#root .container .bottom-nav .mini-menu a:hover {
  color: #ff7a5a;
}
#root .container .bottom-nav .mini-menu a i {
  margin-right: 6px;
}
#root .container .bottom-nav .products-results {
  top: -150%;
}
#root .container .bottom-nav .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  flex: 1;
}
#root .container .bottom-nav .button-container .float-button {
  transform: translate(0, -50%);
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
}
@media screen and (max-width: 240px) {
  #root .container .bottom-nav .button-container .float-button {
    padding: 5px 15px;
  }
}
#root .container .bottom-nav .button-container .float-button .cart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: space-evenly;
  justify-content: space-evenly;
}
#root .container .bottom-nav .button-container .float-button .cart i {
  color: #fff;
  font-size: 16px;
}
@media screen and (max-width: 280px) {
  #root .container .bottom-nav .button-container .float-button .cart i {
    font-size: 15px;
  }
}
#root .container .bottom-nav .button-container .float-button .cart .cart-num {
  color: #fff;
  font-size: 11px;
}
#root .container .nav-links {
  position: fixed;
  width: 100%;
  top: 0;
  background-size: cover;
  display: none;
  z-index: 100000;
  height: 100%;
  animation: animate-links 0.3s linear 1;
  overflow: auto;
}
@keyframes animate-links {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .nav-links .nav-links-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 65%;
  background-color: #eee;
  height: auto;
  animation: animateHamMenu 0.3s linear 1;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.8);
  transform-origin: left;
}
@keyframes animateHamMenu {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
@media screen and (max-width: 500px) {
  #root .container .nav-links .nav-links-container {
    width: 80%;
  }
}
#root .container .nav-links .nav-links-container .user-account {
  height: 15%;
  width: 100%;
  background: linear-gradient(113.36deg, #eee 0%, rgba(255, 122, 90, 0.1) 112.98%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  overflow: hidden;
}
#root .container .nav-links .nav-links-container .user-account .img {
  margin-top: 20px;
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
@media screen and (max-width: 600px) {
  #root .container .nav-links .nav-links-container .user-account .img {
    margin-top: 10px;
  }
}
#root .container .nav-links .nav-links-container .user-account .img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
  border: 2px solid #bbb;
}
#root .container .nav-links .nav-links-container .user-account .account-email {
  width: 100%;
  overflow: hidden;
}
#root .container .nav-links .nav-links-container .user-account .account-email p {
  text-decoration: none;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0px;
  margin-top: 2px;
}
#root .container .nav-links .nav-links-container .user-account .account-email p i {
  margin-right: 6px;
}
#root .container .nav-links .nav-links-container .nav-links-wrapper {
  background-color: #fff;
  width: 100%;
  height: auto;
}
#root .container .nav-links .nav-links-container .nav-links-wrapper a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: all 300ms ease-in-out;
  color: #777;
  text-align: left;
  margin-top: 10px 0px;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px 10px;
  border-bottom: 1px solid #ddd;
}
#root .container .nav-links .nav-links-container .nav-links-wrapper a:last-of-type {
  border-bottom: none;
}
#root .container .nav-links .nav-links-container .nav-links-wrapper a:hover {
  background-color: rgb(255, 223, 214);
}
#root .container .nav-links .nav-links-container .nav-links-wrapper a i {
  margin-right: 6px;
}
#root .container .nav-links .nav-links-container .add-button {
  cursor: pointer;
}
#root .container .shopname {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0px 24px;
  min-height: 94vh;
  width: 100%;
}
@media screen and (max-width: 900px) {
  #root .container .shopname {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shopname {
    padding: 0px 0px;
  }
}
#root .container .shopname .shopname-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  height: -moz-fit-content;
  height: fit-content;
  background-color: transparent;
}
@media screen and (max-width: 1400px) {
  #root .container .shopname .shopname-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .shopname .shopname-container {
    width: 100%;
  }
}
#root .container .shopname .shopname-container .shop-description {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 50px;
  width: 100%;
}
#root .container .shopname .shopname-container .shop-description h3 {
  color: #777;
  font-style: 24px;
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .shop-description h3 {
    font-size: 20px;
  }
}
#root .container .shopname .shopname-container .shop-description p {
  margin-top: 8px;
}
#root .container .shopname .shopname-container .shop {
  position: relative;
  background: transparent;
  height: 200px;
  width: 100%;
}
@media screen and (max-width: 900px) {
  #root .container .shopname .shopname-container .shop {
    height: 100px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .shop {
    margin-top: -10px;
  }
}
#root .container .shopname .shopname-container .shop .opacity {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 184, 166, 0.8), rgba(255, 122, 90, 0.8));
  height: 100%;
  width: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 0px 10px 10px;
}
#root .container .shopname .shopname-container .shop .opacity h1 {
  display: block;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 40px;
  color: #fff;
}
@media screen and (max-width: 900px) {
  #root .container .shopname .shopname-container .shop .opacity h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .shop .opacity h1 {
    font-size: 26px;
  }
}
#root .container .shopname .shopname-container .shop .opacity p {
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
  font-size: 20px;
  display: block;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
@media screen and (max-width: 900px) {
  #root .container .shopname .shopname-container .shop .opacity p {
    font-size: 17px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .shop .opacity p {
    font-size: 14px;
    margin-top: 6px;
  }
}
#root .container .shopname .shopname-container .shop .shop-img {
  position: relative;
  width: 100%;
  height: 200px;
  background: transparent;
  border-radius: 50px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .shopname .shopname-container .shop .shop-img img {
  width: 50%;
  height: 100%;
}
#root .container .shopname .shopname-container .shop-info {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(to bottom, rgba(255, 184, 166, 0.8), rgba(255, 122, 90, 0.8));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 0px 0px 99px 99px;
  z-index: 70;
}
@media screen and (max-width: 900px) {
  #root .container .shopname .shopname-container .shop-info {
    border-radius: 0px 0px 50px 50px;
  }
}
#root .container .shopname .shopname-container .shop-info h2 {
  font-size: 18px;
  color: #555;
  display: block;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
#root .container .shopname .shopname-container .shop-info p {
  font-size: 14px;
  margin-top: 5px;
  color: #555;
  display: block;
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
@media screen and (max-width: 900px) {
  #root .container .shopname .shopname-container .shop-info p {
    width: 80%;
  }
}
#root .container .shopname .shopname-container .products-container {
  background-color: transparent;
  min-height: 94vh;
  width: 100%;
  flex: 1;
  padding: 0px;
}
#root .container .shopname .shopname-container .products-container .heading {
  margin-top: 12px;
  width: 100%;
  background-color: rgb(255, 223, 214);
  padding: 5px;
  border-radius: 5px;
  border-left: 5px solid #ff7a5a;
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .products-container .heading {
    border-radius: 0px;
  }
}
#root .container .shopname .shopname-container .products-container .heading h1 {
  font-size: 20px;
  color: #ff7a5a;
  padding: 4px;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 720px) {
  #root .container .shopname .shopname-container .products-container .heading h1 {
    font-size: 18px;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container {
  width: 100%;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .sub-heading {
  font-size: 18px;
  color: #bbb;
  font-weight: 500;
}
#root .container .shopname .shopname-container .products-container .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
#root .container .shopname .shopname-container .products-container .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .shopname .shopname-container .products-container .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .shopname .shopname-container .products-container .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
#root .container .shopname .shopname-container .products-container .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .shopname .shopname-container .products-container .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .shopname .shopname-container .products-container .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
#root .container .shopname .shopname-container .products-container .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .shopname .shopname-container .products-container .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .shopname .shopname-container .products-container .prod-card-container {
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}
@media screen and (max-width: 400px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 800px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 350px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card {
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  margin: 10px;
  background-color: #fff;
  overflow: hidden;
}
@media screen and (max-width: 351px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card {
    margin: 5px;
    padding: 0px;
    box-shadow: none;
    border: 1px solid #ddd;
  }
}
@media screen and (max-width: 500px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card {
    margin: 0px;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid #ddd;
  }
  #root .container .shopname .shopname-container .products-container .prod-card-container .card:hover {
    box-shadow: none;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img {
  height: 350px;
  width: 100%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 5px 5px 0px 0px;
}
@media screen and (max-width: 1200px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img {
    height: 220px;
    border-radius: 0px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 270px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img {
    height: 200px;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 500px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img img {
    border-radius: 0px;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-img p {
  color: #777;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .load {
  background-color: #ddd;
  color: #ddd;
  margin-top: 5px;
  overflow: hidden;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .load:first-child {
  margin-top: 0px;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .load span {
  color: #ddd;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .load:nth-child(2) .load:nth-child(2) {
  width: 80%;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) {
  width: 40%;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) h3 {
  color: #ddd;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .load:nth-child(2) form button {
  width: 100%;
  padding: 10px 24px;
  background-color: #ddd;
  border: none;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .slide-anime {
  background-color: rgba(255, 255, 255, 0.513);
  box-shadow: 24px 3px 36px rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8%;
  animation: light-slide 2.5s linear infinite;
}
@keyframes light-slide {
  0% {
    left: 0%;
    width: 8%;
    background-color: rgba(255, 255, 255, 0.513);
  }
  50% {
    left: 100%;
    width: 5%;
    background-color: rgb(255, 255, 255);
  }
  70% {
    width: 2%;
  }
  100% {
    left: 100%;
    width: 2%;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info {
  width: 100%;
  background-color: transparent;
  padding: 10px;
  margin-top: 0px;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info p {
  color: #333;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.4rem;
  max-height: 4.4rem;
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info p {
    font-size: 16px;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .prod-name {
  height: 48px;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .shop-name {
  color: #777;
  font-size: 14px;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .shop-name span {
  font-style: italic;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .shop-name .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes h3 {
  flex: 1;
  font-size: 18px;
  font-weight: bolder;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes h3 {
    font-size: 16px;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes p.likes {
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 0px 4px;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes p.likes i:hover {
  color: #ff7a5a;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes p.likes i.active {
  color: #ff7a5a;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes p.likes span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info .price-likes p.likes span.stat.active {
  color: #ff7a5a;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info form {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  #root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info form button {
    padding: 8px 12px;
  }
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info form .unlike {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .shopname .shopname-container .products-container .prod-card-container .card .prod-info form .unlike:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .shopname .shopname-container .products-container .no-pagination {
  margin-bottom: 50px;
}
#root .container .shopname .shopname-container .products-container .pagination {
  margin-bottom: 20px;
}
#root .container .shopname .shopname-container .products-container .pageForms {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 50px;
  padding: 10px;
}
#root .container .shopname .shopname-container .products-container .pageForms button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 8px 20px;
  margin: 10px;
  border: 1px solid #bbb;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  color: #777;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
}
@media screen and (max-width: 500px) {
  #root .container .shopname .shopname-container .products-container .pageForms button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .shopname .shopname-container .products-container .pageForms button {
    font-size: 12px;
  }
}
#root .container .shopname .shopname-container .products-container .pageForms button:hover {
  background-color: #ddd;
}
#root .container .shopname .shopname-container .products-container .pageForms button i.back {
  margin-right: 8px;
}
#root .container .shopname .shopname-container .products-container .pageForms button i.next {
  margin-left: 8px;
}
#root .container .shopname .shopname-container .products-container .pageForms button.back {
  justify-self: flex-start;
}
#root .container .shopname .shopname-container .products-container .pageForms button.next {
  background-color: rgb(255, 223, 214);
  color: #777;
}
#root .container .category-float {
  background-color: transparent;
  position: fixed;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: none;
  justify-content: flex-end;
  padding: 0px 24px;
  z-index: 111;
}
@media screen and (max-width: 700px) {
  #root .container .category-float {
    bottom: 16px;
  }
}
@media screen and (max-width: 700px) {
  #root .container .category-float {
    padding: 0px 16px;
    display: flex;
  }
}
@media screen and (max-width: 600px) {
  #root .container .category-float {
    padding: 0px 10px;
    bottom: 10px;
  }
}
#root .container .category-float i {
  color: #fff;
  font-size: 18px;
}
#root .container .category-float .openNav {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
}
#root .container .category-float .openNav span {
  height: 3px;
  width: 12px;
  background-color: #fff;
}
#root .container .products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 24px;
  min-height: 94vh;
  width: 100%;
}
@media screen and (max-width: 900px) {
  #root .container .products {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products {
    padding: 0px;
  }
}
#root .container .products .msg {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .products .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  margin-bottom: 12px;
}
#root .container .products .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .products .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  margin-bottom: 12px;
}
#root .container .products .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .products .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  margin-bottom: 12px;
}
#root .container .products .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .products .product-container, #root .container .products .product-details, #root .container .products .reviews, #root .container .products .related-products {
  text-decoration: none;
  color: #000;
  background-color: #fff;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  margin-top: 15px;
  transition: all 120ms ease-in-out;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
  cursor: default;
  width: 80%;
}
@media screen and (max-width: 1400px) {
  #root .container .products .product-container, #root .container .products .product-details, #root .container .products .reviews, #root .container .products .related-products {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .products .product-container, #root .container .products .product-details, #root .container .products .reviews, #root .container .products .related-products {
    width: 100%;
  }
}
#root .container .products .product-container:last-child, #root .container .products .product-details:last-child, #root .container .products .reviews:last-child, #root .container .products .related-products:last-child {
  margin-bottom: 15px;
}
@media screen and (max-width: 900px) {
  #root .container .products .product-container, #root .container .products .product-details, #root .container .products .reviews, #root .container .products .related-products {
    width: 90%;
    margin-top: 0px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .product-container, #root .container .products .product-details, #root .container .products .reviews, #root .container .products .related-products {
    width: 80%;
    border-radius: 0px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .products .product-container, #root .container .products .product-details, #root .container .products .reviews, #root .container .products .related-products {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .product-container, #root .container .products .product-details, #root .container .products .reviews, #root .container .products .related-products {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
  }
}
#root .container .products .product-container .prod-image, #root .container .products .product-details .prod-image, #root .container .products .reviews .prod-image, #root .container .products .related-products .prod-image {
  position: relative;
  width: 50%;
  height: 500px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 900px) {
  #root .container .products .product-container .prod-image, #root .container .products .product-details .prod-image, #root .container .products .reviews .prod-image, #root .container .products .related-products .prod-image {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .product-container .prod-image, #root .container .products .product-details .prod-image, #root .container .products .reviews .prod-image, #root .container .products .related-products .prod-image {
    height: 400px;
  }
}
@media screen and (max-width: 480px) {
  #root .container .products .product-container .prod-image, #root .container .products .product-details .prod-image, #root .container .products .reviews .prod-image, #root .container .products .related-products .prod-image {
    height: 300px;
  }
}
#root .container .products .product-container .prod-image img, #root .container .products .product-details .prod-image img, #root .container .products .reviews .prod-image img, #root .container .products .related-products .prod-image img {
  width: 100%;
  height: 100%;
}
#root .container .products .product-container .prod-image p, #root .container .products .product-details .prod-image p, #root .container .products .reviews .prod-image p, #root .container .products .related-products .prod-image p {
  color: #777;
}
#root .container .products .product-container .prod-image .icon, #root .container .products .product-details .prod-image .icon, #root .container .products .reviews .prod-image .icon, #root .container .products .related-products .prod-image .icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
#root .container .products .product-container .prod-image .icon i, #root .container .products .product-details .prod-image .icon i, #root .container .products .reviews .prod-image .icon i, #root .container .products .related-products .prod-image .icon i {
  color: #222;
  font-size: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid #bbb;
}
#root .container .products .product-container .prod-info, #root .container .products .product-details .prod-info, #root .container .products .reviews .prod-info, #root .container .products .related-products .prod-info {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 900px) {
  #root .container .products .product-container .prod-info, #root .container .products .product-details .prod-info, #root .container .products .reviews .prod-info, #root .container .products .related-products .prod-info {
    width: 100%;
    padding: 10px;
  }
}
#root .container .products .product-container .prod-info .prod-name, #root .container .products .product-details .prod-info .prod-name, #root .container .products .reviews .prod-info .prod-name, #root .container .products .related-products .prod-info .prod-name {
  font-size: 24px;
  font-weight: bolder;
  color: #222;
}
@media screen and (max-width: 900px) {
  #root .container .products .product-container .prod-info .prod-name, #root .container .products .product-details .prod-info .prod-name, #root .container .products .reviews .prod-info .prod-name, #root .container .products .related-products .prod-info .prod-name {
    display: -webkit-box;
    max-height: 3.2rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    font-weight: bold;
    letter-spacing: normal;
    font-size: 18px;
    line-height: 1.4rem;
    max-height: 4.4rem;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .product-container .prod-info .prod-name, #root .container .products .product-details .prod-info .prod-name, #root .container .products .reviews .prod-info .prod-name, #root .container .products .related-products .prod-info .prod-name {
    font-size: 16px;
  }
}
#root .container .products .product-container .prod-info p, #root .container .products .product-details .prod-info p, #root .container .products .reviews .prod-info p, #root .container .products .related-products .prod-info p {
  font-size: 16px;
  color: #777;
  position: relative;
}
@media screen and (max-width: 500px) {
  #root .container .products .product-container .prod-info p, #root .container .products .product-details .prod-info p, #root .container .products .reviews .prod-info p, #root .container .products .related-products .prod-info p {
    font-size: 15px;
  }
}
#root .container .products .product-container .prod-info p .shop-name-link, #root .container .products .product-details .prod-info p .shop-name-link, #root .container .products .reviews .prod-info p .shop-name-link, #root .container .products .related-products .prod-info p .shop-name-link {
  text-decoration: none;
  color: #777;
}
#root .container .products .product-container .prod-info p .verified, #root .container .products .product-details .prod-info p .verified, #root .container .products .reviews .prod-info p .verified, #root .container .products .related-products .prod-info p .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .products .product-container .prod-info .price-likes, #root .container .products .product-details .prod-info .price-likes, #root .container .products .reviews .prod-info .price-likes, #root .container .products .related-products .prod-info .price-likes {
  display: flex;
  width: 100%;
  margin-top: 8px;
  margin-top: 11px;
}
#root .container .products .product-container .prod-info .price-likes h1, #root .container .products .product-details .prod-info .price-likes h1, #root .container .products .reviews .prod-info .price-likes h1, #root .container .products .related-products .prod-info .price-likes h1 {
  flex: 1;
  font-size: 24px;
  font-weight: bolder;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 900px) {
  #root .container .products .product-container .prod-info .price-likes h1, #root .container .products .product-details .prod-info .price-likes h1, #root .container .products .reviews .prod-info .price-likes h1, #root .container .products .related-products .prod-info .price-likes h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .product-container .prod-info .price-likes h1, #root .container .products .product-details .prod-info .price-likes h1, #root .container .products .reviews .prod-info .price-likes h1, #root .container .products .related-products .prod-info .price-likes h1 {
    font-size: 16px;
  }
}
#root .container .products .product-container .prod-info .price-likes p.likes, #root .container .products .product-details .prod-info .price-likes p.likes, #root .container .products .reviews .prod-info .price-likes p.likes, #root .container .products .related-products .prod-info .price-likes p.likes {
  margin-bottom: 5px;
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 18px;
}
@media screen and (max-width: 900px) {
  #root .container .products .product-container .prod-info .price-likes p.likes, #root .container .products .product-details .prod-info .price-likes p.likes, #root .container .products .reviews .prod-info .price-likes p.likes, #root .container .products .related-products .prod-info .price-likes p.likes {
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .product-container .prod-info .price-likes p.likes, #root .container .products .product-details .prod-info .price-likes p.likes, #root .container .products .reviews .prod-info .price-likes p.likes, #root .container .products .related-products .prod-info .price-likes p.likes {
    font-size: 15px;
  }
}
#root .container .products .product-container .prod-info .price-likes p.likes .dot, #root .container .products .product-details .prod-info .price-likes p.likes .dot, #root .container .products .reviews .prod-info .price-likes p.likes .dot, #root .container .products .related-products .prod-info .price-likes p.likes .dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .products .product-container .prod-info .price-likes p.likes .sep, #root .container .products .product-details .prod-info .price-likes p.likes .sep, #root .container .products .reviews .prod-info .price-likes p.likes .sep, #root .container .products .related-products .prod-info .price-likes p.likes .sep {
  margin-left: 8px;
  margin-right: 8px;
  height: 18px;
  width: 2px;
  background-color: transparent;
}
#root .container .products .product-container .prod-info .line, #root .container .products .product-details .prod-info .line, #root .container .products .reviews .prod-info .line, #root .container .products .related-products .prod-info .line {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin-top: 5px;
}
#root .container .products .product-container .prod-info ul, #root .container .products .product-details .prod-info ul, #root .container .products .reviews .prod-info ul, #root .container .products .related-products .prod-info ul {
  margin-left: 20px;
}
#root .container .products .product-container .prod-info ul li, #root .container .products .product-details .prod-info ul li, #root .container .products .reviews .prod-info ul li, #root .container .products .related-products .prod-info ul li {
  font-size: 16px;
  color: #555;
}
@media screen and (max-width: 220px) {
  #root .container .products .product-container .prod-info ul li, #root .container .products .product-details .prod-info ul li, #root .container .products .reviews .prod-info ul li, #root .container .products .related-products .prod-info ul li {
    display: block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
#root .container .products .product-container .prod-info .options-cont .options, #root .container .products .product-details .prod-info .options-cont .options, #root .container .products .reviews .prod-info .options-cont .options, #root .container .products .related-products .prod-info .options-cont .options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .products .product-container .prod-info .options-cont .options p, #root .container .products .product-details .prod-info .options-cont .options p, #root .container .products .reviews .prod-info .options-cont .options p, #root .container .products .related-products .prod-info .options-cont .options p {
  margin: 2px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #bbb;
  cursor: pointer;
}
#root .container .products .product-container .prod-info .options-cont .options .active, #root .container .products .product-details .prod-info .options-cont .options .active, #root .container .products .reviews .prod-info .options-cont .options .active, #root .container .products .related-products .prod-info .options-cont .options .active {
  background-color: rgb(255, 223, 214);
  border: 1px solid #ff7a5a;
  color: #ff7a5a;
}
#root .container .products .product-container .prod-info form, #root .container .products .product-details .prod-info form, #root .container .products .reviews .prod-info form, #root .container .products .related-products .prod-info form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .products .product-container .prod-info form button, #root .container .products .product-details .prod-info form button, #root .container .products .reviews .prod-info form button, #root .container .products .related-products .prod-info form button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: #ff7a5a;
  color: #fff;
  cursor: pointer;
  text-align: center;
}
@media screen and (max-width: 500px) {
  #root .container .products .product-container .prod-info form button, #root .container .products .product-details .prod-info form button, #root .container .products .reviews .prod-info form button, #root .container .products .related-products .prod-info form button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .products .product-container .prod-info form button, #root .container .products .product-details .prod-info form button, #root .container .products .reviews .prod-info form button, #root .container .products .related-products .prod-info form button {
    font-size: 12px;
  }
}
#root .container .products .product-container .prod-info form button:hover, #root .container .products .product-details .prod-info form button:hover, #root .container .products .reviews .prod-info form button:hover, #root .container .products .related-products .prod-info form button:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
@media screen and (max-width: 350px) {
  #root .container .products .product-container .prod-info form button, #root .container .products .product-details .prod-info form button, #root .container .products .reviews .prod-info form button, #root .container .products .related-products .prod-info form button {
    font-size: 15px;
  }
}
#root .container .products .check-location {
  width: 80%;
  background-color: transparent;
}
@media screen and (max-width: 1400px) {
  #root .container .products .check-location {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .products .check-location {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .check-location {
    padding: 10px;
  }
}
#root .container .products .check-location .inputs {
  width: 100%;
  display: flex;
}
#root .container .products .check-location .inputs input {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  flex: 1;
}
@media screen and (max-width: 500px) {
  #root .container .products .check-location .inputs input {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .products .check-location .inputs input {
    font-size: 13px;
  }
}
#root .container .products .check-location .inputs button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  width: 100px;
  background-color: #ff7a5a;
  color: #fff;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  #root .container .products .check-location .inputs button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .products .check-location .inputs button {
    font-size: 12px;
  }
}
#root .container .products .check-location h1 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}
#root .container .products .check-location h1 span {
  font-weight: 500;
}
#root .container .products .browse {
  color: #ff7a5a;
}
#root .container .products .related-products {
  flex-direction: column;
  margin-top: 15px;
  padding: 10px;
}
#root .container .products .related-products h2 {
  font-size: 16px;
  font-weight: bolder;
  color: #555;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .container .products .related-products .embla {
  width: 100%;
  align-self: center;
  padding: 10px;
  position: relative;
}
#root .container .products .related-products .embla .prod-card-container {
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}
@media screen and (max-width: 400px) {
  #root .container .products .related-products .embla .prod-card-container::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .products .related-products .embla .prod-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 800px) {
  #root .container .products .related-products .embla .prod-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 350px) {
  #root .container .products .related-products .embla .prod-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
#root .container .products .related-products .embla .prod-card-container .card {
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  margin: 10px;
  background-color: #fff;
  overflow: hidden;
}
@media screen and (max-width: 351px) {
  #root .container .products .related-products .embla .prod-card-container .card {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .products .related-products .embla .prod-card-container .card {
    margin: 5px;
    padding: 0px;
    box-shadow: none;
    border: 1px solid #ddd;
  }
}
@media screen and (max-width: 500px) {
  #root .container .products .related-products .embla .prod-card-container .card {
    margin: 0px;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid #ddd;
  }
  #root .container .products .related-products .embla .prod-card-container .card:hover {
    box-shadow: none;
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-img {
  height: 350px;
  width: 100%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 5px 5px 0px 0px;
}
@media screen and (max-width: 1200px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-img {
    height: 220px;
    border-radius: 0px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 270px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-img {
    height: 200px;
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-img img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 500px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-img img {
    border-radius: 0px;
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-img p {
  color: #777;
}
#root .container .products .related-products .embla .prod-card-container .card .load {
  background-color: #ddd;
  color: #ddd;
  margin-top: 5px;
  overflow: hidden;
}
#root .container .products .related-products .embla .prod-card-container .card .load:first-child {
  margin-top: 0px;
}
#root .container .products .related-products .embla .prod-card-container .card .load span {
  color: #ddd;
}
#root .container .products .related-products .embla .prod-card-container .card .load:nth-child(2) .load:nth-child(2) {
  width: 80%;
}
#root .container .products .related-products .embla .prod-card-container .card .load:nth-child(2) .load:nth-child(3) {
  width: 40%;
}
#root .container .products .related-products .embla .prod-card-container .card .load:nth-child(2) .load:nth-child(3) h3 {
  color: #ddd;
}
#root .container .products .related-products .embla .prod-card-container .card .load:nth-child(2) form button {
  width: 100%;
  padding: 10px 24px;
  background-color: #ddd;
  border: none;
}
#root .container .products .related-products .embla .prod-card-container .card .slide-anime {
  background-color: rgba(255, 255, 255, 0.513);
  box-shadow: 24px 3px 36px rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8%;
  animation: light-slide 2.5s linear infinite;
}
@keyframes light-slide {
  0% {
    left: 0%;
    width: 8%;
    background-color: rgba(255, 255, 255, 0.513);
  }
  50% {
    left: 100%;
    width: 5%;
    background-color: rgb(255, 255, 255);
  }
  70% {
    width: 2%;
  }
  100% {
    left: 100%;
    width: 2%;
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info {
  width: 100%;
  background-color: transparent;
  padding: 10px;
  margin-top: 0px;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info p {
  color: #333;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.4rem;
  max-height: 4.4rem;
}
@media screen and (max-width: 600px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-info p {
    font-size: 16px;
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .prod-name {
  height: 48px;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .shop-name {
  color: #777;
  font-size: 14px;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .shop-name span {
  font-style: italic;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .shop-name .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes h3 {
  flex: 1;
  font-size: 18px;
  font-weight: bolder;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 600px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes h3 {
    font-size: 16px;
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes p.likes {
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 0px 4px;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes p.likes i:hover {
  color: #ff7a5a;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes p.likes i.active {
  color: #ff7a5a;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes p.likes span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info .price-likes p.likes span.stat.active {
  color: #ff7a5a;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info form {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  #root .container .products .related-products .embla .prod-card-container .card .prod-info form button {
    padding: 8px 12px;
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info form .unlike {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .products .related-products .embla .prod-card-container .card .prod-info form .unlike:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .products .related-products .embla .prod-card-container {
  width: 220px;
  margin-top: 0;
  margin-bottom: 10px;
}
#root .container .products .related-products .embla .prod-card-container .card {
  box-shadow: 2px 2px 0 rgba(77, 77, 79, 0.08), 0 0 2px 0 rgba(77, 77, 79, 0.16);
  transition: all 300ms ease-in-out;
}
#root .container .products .related-products .embla .prod-card-container .card:hover {
  box-shadow: 0 4px 16px 2px rgba(77, 77, 79, 0.08), 0 8px 12px 2px rgba(77, 77, 79, 0.16);
}
@media screen and (max-width: 600px) {
  #root .container .products .related-products .embla .prod-card-container .card {
    margin: 6px;
    border: none;
  }
}
@media screen and (max-width: 500px) {
  #root .container .products .related-products .embla .prod-card-container .card {
    box-shadow: 2px 2px 0 rgba(77, 77, 79, 0.08), 0 0 2px 0 rgba(77, 77, 79, 0.16);
    transition: all 300ms ease-in-out;
    border: none;
  }
  #root .container .products .related-products .embla .prod-card-container .card:hover {
    box-shadow: 0 4px 16px 2px rgba(77, 77, 79, 0.08), 0 8px 12px 2px rgba(77, 77, 79, 0.16);
  }
}
#root .container .products .related-products .embla .prod-card-container .card .prod-img {
  height: 150px;
}
#root .container .products .related-products .embla .embla__btns {
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  pointer-events: none;
}
#root .container .products .related-products .embla .embla__btns .btn:first-child {
  flex: 1;
}
#root .container .products .related-products .embla .embla__btns .btn button {
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 36px;
  height: 36px;
  outline: none;
  border: 1px solid #d4d3d3;
  color: #222;
  border-radius: 50%;
  background-color: rgba(241, 240, 240, 0.65);
  cursor: pointer;
  color: #555;
}
#root .container .products .related-products .link-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}
#root .container .products .related-products .link-container .link {
  text-decoration: none;
  color: #000;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  font-weight: 300;
  padding: 10px;
  color: #ff7a5a;
  min-width: 70%;
  max-width: 100%;
  background-color: transparent;
  text-align: center;
  border: 1px solid #ddd;
}
#root .container .products .related-products .link-container .link:hover {
  color: #ff7a5a;
}
#root .container .products .related-products .link-container .link:hover {
  background-color: rgb(255, 223, 214);
}
#root .container .products .product-details, #root .container .products .reviews {
  flex-direction: column;
  margin-top: 15px;
  padding: 10px;
}
#root .container .products .product-details h1, #root .container .products .reviews h1 {
  font-size: 16px;
  font-weight: bolder;
  color: #555;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .container .products .product-details p, #root .container .products .reviews p {
  margin-top: 12px;
  font-size: 14px;
  display: block;
  white-space: pre-line;
}
#root .container .products .reviews {
  margin-bottom: 50px;
}
#root .container .products .reviews .review-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .products .reviews .review-nav h1 {
  flex: 1;
}
#root .container .products .reviews .review-nav button {
  padding: 6px;
  color: #777;
  border: 1px solid #bbb;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
}
#root .container .products .reviews .review-nav button:hover {
  background-color: rgb(255, 223, 214);
}
#root .container .products .reviews .not-found {
  font-size: 16px;
  color: #bbb;
}
#root .container .products .reviews .ratings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}
#root .container .products .reviews .ratings p {
  margin: 6px 10px 0px 0px;
  font-size: 20px;
}
#root .container .products .reviews .ratings p i {
  font-size: 20px;
}
#root .container .products .reviews .ratings .red {
  color: rgb(255, 43, 43);
}
#root .container .products .reviews .ratings .green {
  color: rgb(14, 136, 14);
}
#root .container .products .reviews .review {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
}
#root .container .products .reviews .review .img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ddd;
  border: 1px solid #bbb;
}
#root .container .products .reviews .review .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .products .reviews .review .content {
  width: 90%;
  padding: 0px 10px;
  height: -moz-fit-content;
  height: fit-content;
  flex-wrap: wrap;
  display: block;
}
#root .container .products .reviews .review .content h2 {
  font-size: 14px;
  font-weight: bold;
  color: #ff7a5a;
  color: #777;
  display: block;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .container .products .reviews .review .content p {
  margin-top: 5px;
  color: #777;
  font-size: 14px;
}
#root .container .large-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  display: none;
  animation: slowFade 0.5s linear 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@keyframes slowFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .large-img .close-cont {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
#root .container .large-img .close-cont .close-container {
  font-size: 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 30px;
}
@media screen and (max-width: 1200px) {
  #root .container .large-img .close-cont .close-container {
    margin: 10px;
  }
}
#root .container .large-img .close-cont .close-container:hover {
  color: red;
}
#root .container .large-img img {
  width: 80vw;
  height: 80vh;
}
@media screen and (max-width: 1200px) {
  #root .container .large-img img {
    height: 60vh;
  }
}
@media screen and (max-width: 900px) {
  #root .container .large-img img {
    width: 100vw;
  }
}
#root .container .carts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 50px;
  min-height: 94vh;
  width: 100%;
}
@media screen and (max-width: 900px) {
  #root .container .carts {
    padding: 0px 16px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .carts {
    padding: 0px 10px;
  }
}
#root .container .carts .heading {
  width: 50%;
}
@media screen and (max-width: 1500px) {
  #root .container .carts .heading {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .carts .heading {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .carts .heading {
    width: 70%;
  }
}
@media screen and (max-width: 500px) {
  #root .container .carts .heading {
    width: 80%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .carts .heading {
    width: 90%;
  }
}
@media screen and (max-width: 350px) {
  #root .container .carts .heading {
    width: 100%;
  }
}
#root .container .carts .heading h1 {
  font-size: 20px;
  color: #555;
  padding: 4px;
  font-weight: 600;
}
@media screen and (max-width: 750px) {
  #root .container .carts .heading h1 {
    text-align: center;
  }
}
#root .container .carts .no-items {
  color: #bbb;
  margin-top: 15px;
}
#root .container .carts .confirm-decline {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
#root .container .carts .confirm-decline .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  width: 50%;
}
@media screen and (max-width: 1500px) {
  #root .container .carts .confirm-decline .heading {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .carts .confirm-decline .heading {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .carts .confirm-decline .heading {
    width: 70%;
  }
}
@media screen and (max-width: 500px) {
  #root .container .carts .confirm-decline .heading {
    width: 80%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .carts .confirm-decline .heading {
    width: 90%;
  }
}
@media screen and (max-width: 350px) {
  #root .container .carts .confirm-decline .heading {
    width: 100%;
  }
}
#root .container .carts .confirm-decline .heading h1 {
  font-size: 20px;
  color: #555;
  padding: 4px;
  font-weight: 600;
}
@media screen and (max-width: 750px) {
  #root .container .carts .confirm-decline .heading h1 {
    text-align: center;
  }
}
#root .container .carts .confirm-decline a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  width: 200px;
  height: 40px;
  text-align: center;
  color: #fff;
  background-color: #ff7a5a;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  #root .container .carts .confirm-decline a {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .carts .confirm-decline a {
    font-size: 12px;
  }
}
#root .container .carts .confirm-decline .no {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .carts .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
#root .container .carts .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .carts .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .carts .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
#root .container .carts .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .carts .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .carts .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
#root .container .carts .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .carts .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .carts .prod-cart-container {
  text-decoration: none;
  color: #000;
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  margin-top: 15px;
  transition: all 120ms ease-in-out;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
  cursor: default;
}
#root .container .carts .prod-cart-container:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .carts .prod-cart-container:hover {
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
}
#root .container .carts .prod-cart-container:last-child {
  margin-bottom: 15px;
}
@media screen and (max-width: 1500px) {
  #root .container .carts .prod-cart-container {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .carts .prod-cart-container {
    width: 90%;
  }
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container {
    flex-direction: column;
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .carts .prod-cart-container {
    width: 70%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .carts .prod-cart-container {
    width: 80%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .carts .prod-cart-container {
    width: 90%;
  }
}
@media screen and (max-width: 350px) {
  #root .container .carts .prod-cart-container {
    width: 100%;
  }
}
#root .container .carts .prod-cart-container .prod-image {
  width: 20%;
  height: 120px;
  background-color: #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container .prod-image {
    width: 100%;
    height: 250px;
    border-radius: 5px 5px 0px 0px;
  }
}
#root .container .carts .prod-cart-container .prod-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container .prod-image img {
    border-radius: 5px 5px 0px 0px;
  }
}
#root .container .carts .prod-cart-container .prod-info {
  width: 60%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 15px;
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container .prod-info {
    width: 100%;
    padding: 10px 0px;
  }
}
#root .container .carts .prod-cart-container .prod-info .name-price {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
}
#root .container .carts .prod-cart-container .prod-info .name-price h1 {
  font-size: 18px;
  font-weight: bold;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.4rem;
  max-height: 4.4rem;
  color: #333;
  padding: 0px 5px 0px 0px;
  flex: 2;
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container .prod-info .name-price h1 {
    font-size: 16px;
  }
}
#root .container .carts .prod-cart-container .prod-info .name-price .price {
  font-size: 18px;
  color: #ff7a5a;
  flex: 1;
  text-align: right;
  font-weight: bold;
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container .prod-info .name-price .price {
    font-size: 16px;
  }
}
#root .container .carts .prod-cart-container .prod-info .shopName {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #777;
  font-size: 14px;
}
#root .container .carts .prod-cart-container .prod-edit {
  width: 20%;
  height: auto;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  position: relative;
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container .prod-edit {
    width: 100%;
    flex-direction: column;
    margin-top: 10px;
  }
}
#root .container .carts .prod-cart-container .prod-edit form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .carts .prod-cart-container .prod-edit form p {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 10px;
}
#root .container .carts .prod-cart-container .prod-edit form p .qty-btn {
  border: none;
  margin-left: 10px;
  width: 60%;
  padding: 0px 4px;
  font-size: 20px;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  background-color: #ff7a5a;
}
#root .container .carts .prod-cart-container .prod-edit form p .qty-btn:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 750px) {
  #root .container .carts .prod-cart-container .prod-edit form p .qty-btn {
    width: 40%;
  }
}
#root .container .carts .prod-cart-container .prod-edit form p .qty-btn:hover {
  background-color: #ff7a5a;
  box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.25);
}
#root .container .carts .prod-cart-container .prod-edit form p span {
  padding: 0px 5px;
  color: #777;
}
#root .container .carts .prod-cart-container .prod-edit form button {
  width: 100%;
  padding: 6px;
  background: #fff;
  border: 1px solid #999;
  cursor: pointer;
  border-radius: 5px;
  color: #555;
  transition: all 300ms ease-in-out;
  outline: none;
}
#root .container .carts .prod-cart-container .prod-edit form button:hover {
  background-color: #eee;
}
#root .container .carts .cart-summary {
  text-decoration: none;
  color: #000;
  width: 30%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  margin-top: 15px;
  transition: all 120ms ease-in-out;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;
  margin-bottom: 50px;
}
#root .container .carts .cart-summary:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .carts .cart-summary:hover {
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
}
@media screen and (max-width: 900px) {
  #root .container .carts .cart-summary {
    width: 50%;
  }
}
@media screen and (max-width: 750px) {
  #root .container .carts .cart-summary {
    width: 60%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .carts .cart-summary {
    width: 70%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .carts .cart-summary {
    width: 80%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .carts .cart-summary {
    width: 90%;
  }
}
@media screen and (max-width: 350px) {
  #root .container .carts .cart-summary {
    width: 100%;
  }
}
#root .container .carts .cart-summary h1 {
  font-size: 20px;
  font-weight: bolder;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #555;
}
@media screen and (max-width: 720px) {
  #root .container .carts .cart-summary h1 {
    font-size: 16px;
  }
}
#root .container .carts .cart-summary .total {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
}
#root .container .carts .cart-summary .total p {
  flex: 1;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #555;
}
@media screen and (max-width: 500px) {
  #root .container .carts .cart-summary .total p {
    font-size: 15px;
  }
}
#root .container .carts .cart-summary .total .price {
  font-weight: bolder;
  color: rgb(14, 136, 14);
}
#root .container .carts .cart-summary a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: rgb(14, 136, 14);
  color: #fff;
  cursor: pointer;
  transition: all 120ms ease-in-out;
  border: 3px solid rgb(14, 136, 14);
}
@media screen and (max-width: 500px) {
  #root .container .carts .cart-summary a {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .carts .cart-summary a {
    font-size: 12px;
  }
}
#root .container .carts .cart-summary a:hover {
  box-shadow: 1px 3px 3px rgba(14, 136, 14, 0.4);
  color: #fff;
}
@media screen and (max-width: 500px) {
  #root .container .carts .cart-summary a {
    font-size: 15px;
  }
}
#root .container .admin-nav {
  position: sticky;
  top: 0px;
  background-color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 100;
  border-bottom: 1px solid #ddd;
}
#root .container .admin-nav .nav-container {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  #root .container .admin-nav .nav-container {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .admin-nav .nav-container {
    width: 100%;
  }
}
#root .container .admin-nav .nav-container a {
  flex-basis: 33.3%;
  display: inline-flex;
  margin: 0px 15px;
  text-decoration: none;
  color: #bbb;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  font-size: 24px;
  transition: all 120ms ease-in-out;
}
@media screen and (max-width: 800px) {
  #root .container .admin-nav .nav-container a {
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .admin-nav .nav-container a {
    font-size: 16px;
    font-weight: bold;
  }
}
@media screen and (max-width: 600px) {
  #root .container .admin-nav .nav-container a {
    font-weight: 600;
    padding: 10px 0px;
  }
}
#root .container .admin-nav .nav-container a:hover {
  background-color: rgb(246, 243, 241);
}
#root .container .admin-nav .nav-container .active {
  color: #ff7a5a;
  border-bottom: 3px solid #ff7a5a;
}
#root .container .account {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
@media screen and (max-width: 900px) {
  #root .container .account {
    min-height: 90vh;
  }
}
#root .container .account .search-cont {
  width: 80%;
  background-color: transparent;
  margin-top: 15px;
}
@media screen and (max-width: 1400px) {
  #root .container .account .search-cont {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .search-cont {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .search-cont {
    padding: 10px;
  }
}
#root .container .account .search-cont .inputs {
  width: 100%;
  display: flex;
}
#root .container .account .search-cont .inputs input {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  flex: 1;
}
@media screen and (max-width: 500px) {
  #root .container .account .search-cont .inputs input {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .account .search-cont .inputs input {
    font-size: 13px;
  }
}
#root .container .account .search-cont .inputs button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  width: 100px;
  background-color: #ff7a5a;
  color: #fff;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  #root .container .account .search-cont .inputs button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .account .search-cont .inputs button {
    font-size: 12px;
  }
}
#root .container .account .search-cont .info {
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
}
#root .container .account .search-cont .info:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .account .search-cont .info p span {
  font-weight: bold;
}
#root .container .account .search-cont .info h1 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}
#root .container .account .search-cont .info h1 span {
  font-weight: 500;
  color: #ff7a5a;
}
#root .container .account .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .account .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .account .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .account .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .account .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .account .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .account .cards-container {
  width: 80%;
  display: block;
  overflow: hidden;
}
@media screen and (max-width: 1400px) {
  #root .container .account .cards-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .cards-container {
    width: 100%;
  }
}
#root .container .account .cards-container .list-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}
#root .container .account .cards-container .list-card .list-card:last-child {
  margin-bottom: 20px;
}
#root .container .account .cards-container .list-card .list-card:last-child .info {
  border-bottom: none;
}
#root .container .account .cards-container .list-card:hover {
  background-color: #eee;
}
#root .container .account .cards-container .list-card .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
}
#root .container .account .cards-container .list-card .img p {
  font-size: 20px;
  color: #ff7a5a;
}
#root .container .account .cards-container .list-card .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .account .cards-container .list-card .foll {
  box-shadow: none;
  margin-top: 4px;
}
#root .container .account .cards-container .list-card .info {
  padding: 10px;
  flex: 1;
  border-bottom: 1px solid #bbb;
}
#root .container .account .cards-container .list-card .info h2 {
  font-size: 15px;
  color: #ff7a5a;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  #root .container .account .cards-container .list-card .info h2 {
    margin-top: 0px;
  }
}
@media screen and (max-width: 380px) {
  #root .container .account .cards-container .list-card .info h2 {
    padding: 0px;
  }
}
#root .container .account .cards-container .list-card .info h3 {
  font-size: 12px;
  color: #777;
  font-weight: 500;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
#root .container .account .cards-container .list-card .info h3 .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .account .cards-container .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
#root .container .account .cards-container .buttons button {
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 0% 0% 50% 50%;
  outline: none;
  border: none;
  background-color: rgb(255, 43, 43);
  cursor: pointer;
}
#root .container .account .cards-container .buttons button:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .account .cards-container .unseen {
  background: rgb(255, 223, 214);
}
#root .container .account .cards-container .not-found {
  margin-left: 10px;
  color: #bbb;
}
#root .container .account .account-container {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
}
@media screen and (max-width: 1400px) {
  #root .container .account .account-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
#root .container .account .account-container .stats .mobile-view .fees-cont {
  background-color: transparent;
  width: 100%;
  margin-top: 12px;
}
#root .container .account .account-container .stats .mobile-view .fees-cont h2 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}
#root .container .account .account-container .stats .mobile-view .fees-cont .tile-cont {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
#root .container .account .account-container .stats .mobile-view .fees-cont .tile-cont .tile {
  margin: 10px;
  padding: 15px;
  border: 1px solid #bbb;
  border-radius: 5px;
  background-color: #fff;
}
#root .container .account .account-container .stats .mobile-view .fees-cont .tile-cont .tile:nth-child(1) {
  margin-left: 0px;
}
#root .container .account .account-container .stats .mobile-view .fees-cont .tile-cont .tile p {
  color: #ff7a5a;
  margin-top: 10px;
}
#root .container .account .account-container .stats .mobile-view .fees-cont .tile-cont .tile h3 {
  font-size: 16px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont {
  background-color: transparent;
  width: 100%;
  margin-top: 12px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont:last-child {
  margin-bottom: 40px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .top-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid #bbb;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .top-row p {
  flex: 1;
  color: #777;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .top-row button {
  padding: 8px 11px;
  background-color: rgb(255, 43, 43);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .top-row button:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .account .account-container .stats .mobile-view .transact-cont .transact-no {
  border-bottom: 1px solid #bbb;
  padding: 10px;
  background-color: #fff;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .transact-no h2 {
  font-size: 20px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .bottom-row {
  border-bottom: 1px solid #bbb;
  padding: 10px;
  background-color: #fff;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .bottom-row .items {
  width: 100%;
  background-color: #ddd;
  padding: 5px 10px 10px 10px;
  margin-bottom: 10px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .bottom-row .items .item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  color: #777;
  margin-top: 5px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .bottom-row .items .item p {
  flex: 1;
  font-size: 14px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .bottom-row .items .item span {
  font-size: 14px;
}
#root .container .account .account-container .stats .mobile-view .transact-cont .bottom-row h3 {
  margin-top: 5px;
  font-size: 20px;
  color: #ff7a5a;
}
#root .container .account .account-container .buyer-account {
  margin-top: -20px;
  margin-bottom: 10px;
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .buyer-account {
    margin-top: -30px;
  }
}
#root .container .account .account-container .account-info-container {
  position: relative;
  width: 40%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
  overflow: hidden;
}
@media screen and (max-width: 1400px) {
  #root .container .account .account-container .account-info-container {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .account-info-container {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .account-info-container {
    width: 100%;
  }
}
#root .container .account .account-container .account-info-container .info-img {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #eee;
  z-index: 10;
  border: 2px solid #bbb;
}
@media screen and (max-height: 600px) {
  #root .container .account .account-container .account-info-container .info-img {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .account-info-container .info-img {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 250px) {
  #root .container .account .account-container .account-info-container .info-img {
    width: 160px;
    height: 160px;
  }
}
#root .container .account .account-container .account-info-container .info-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .account .account-container .account-info-container .info-img .upload-btn {
  position: absolute;
  bottom: 22%;
  right: -2%;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .account-info-container .info-img .upload-btn {
    bottom: 13%;
  }
}
#root .container .account .account-container .account-info-container .info-img .upload-btn span {
  font-size: 16px;
  height: 45px;
  width: 45px;
  border-radius: 50px;
  background: #ff7a5a;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 2px solid #bbb;
}
#root .container .account .account-container .account-info-container .info-img .upload-btn span input[type=file] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: none;
}
#root .container .account .account-container .account-info-container .info-img .upload-btn span label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
#root .container .account .account-container .account-info-container .info-text {
  width: 100%;
  overflow: hidden;
  align-self: flex-start;
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .account-info-container .info-text {
    align-self: center;
  }
}
#root .container .account .account-container .account-info-container .info-text .no-shopname {
  color: #bbb;
}
#root .container .account .account-container .account-info-container .info-text h2 {
  margin-top: 16px;
  font-size: 26px;
  color: #111;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  position: relative;
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .account-info-container .info-text h2 {
    font-size: 24px;
  }
}
@media screen and (max-width: 800px) {
  #root .container .account .account-container .account-info-container .info-text h2 {
    font-size: 22px;
  }
}
#root .container .account .account-container .account-info-container .info-text h2 .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .account .account-container .account-info-container .info-text h3 {
  font-size: 18px;
  color: #555;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
@media screen and (max-width: 800px) {
  #root .container .account .account-container .account-info-container .info-text h3 {
    font-size: 16px;
  }
}
#root .container .account .account-container .account-info-container .info-text .followers-whs {
  margin-top: 16px;
  width: 100%;
  display: flex;
}
#root .container .account .account-container .account-info-container .info-text .followers-whs p.followers {
  flex: 1;
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .account-info-container .info-text .followers-whs p.followers {
    text-align: center;
  }
}
#root .container .account .account-container .account-info-container .info-text .followers-whs p.followers .dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .account .account-container .account-info-container .info-text .followers-whs .whs-btn {
  padding: 6px 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: #ff7a5a;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  border: 1px solid #ff7a5a;
  font-weight: bold;
}
#root .container .account .account-container .account-info-container .info-text .followers-whs .whs-btn:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .account .account-container .account-info-container .info-text .followers-whs .whs-btn i {
  margin-right: 6px;
}
#root .container .account .account-container .account-info-container .line {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin-top: 10px;
}
#root .container .account .account-container .account-info-container a {
  width: 80%;
  padding: 10px;
  font-size: 18px;
  color: #222;
  border: 1px solid #bbb;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  margin-top: 10px;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  background-color: #ff7a5a;
  color: #fff;
}
@media screen and (max-width: 800px) {
  #root .container .account .account-container .account-info-container a {
    font-size: 16px;
  }
}
#root .container .account .account-container .account-info-container a:hover {
  background-color: #ff7a5a;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  color: #fff;
}
#root .container .account .account-container .account-info-container .logout {
  color: #777;
  background-color: transparent;
}
#root .container .account .account-container .account-info-container .logout:hover {
  background-color: #ddd;
  box-shadow: none;
  color: #777;
}
#root .container .account .account-container .account-products-container {
  width: 60%;
  height: 85.5vh;
  background-color: transparent;
  padding: 0px 10px;
  overflow: auto;
  display: block;
}
#root .container .account .account-container .account-products-container::-webkit-scrollbar {
  width: 4px;
  background: transparent;
  margin-right: 12px;
}
#root .container .account .account-container .account-products-container::-webkit-scrollbar-track {
  border: none;
  background-color: #ddd;
}
#root .container .account .account-container .account-products-container::-webkit-scrollbar-thumb {
  background: #bbb;
  width: 2px;
}
@media screen and (max-width: 1400px) {
  #root .container .account .account-container .account-products-container {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .account-products-container {
    width: 90%;
    border-left: none;
    align-self: center;
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .account-products-container {
    width: 100%;
  }
}
#root .container .account .account-container .account-products-container .line {
  width: 100%;
  height: 2px;
  background-color: #ddd;
  margin-top: 10px;
  display: none;
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .account-products-container .line {
    display: block;
  }
}
#root .container .account .account-container .account-products-container .no-items {
  margin-top: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #bbb;
}
#root .container .account .account-container .account-products-container h2 {
  font-size: 24px;
  color: #777;
  font-weight: 500;
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .account-products-container h2 {
    margin-top: 20px;
    font-size: 18px;
  }
}
@media screen and (max-width: 800px) {
  #root .container .account .account-container .account-products-container h2 {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container h2 {
    padding: 0px 10px;
  }
}
#root .container .account .account-container .account-products-container .list-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
  transition: all 300ms ease-in-out;
}
#root .container .account .account-container .account-products-container .list-card .list-card:last-child {
  margin-bottom: 20px;
}
#root .container .account .account-container .account-products-container .list-card .list-card:last-child .info {
  border-bottom: none;
}
#root .container .account .account-container .account-products-container .list-card:hover {
  background-color: #eee;
}
#root .container .account .account-container .account-products-container .list-card .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);
}
#root .container .account .account-container .account-products-container .list-card .img p {
  font-size: 20px;
  color: #ff7a5a;
}
#root .container .account .account-container .account-products-container .list-card .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .account .account-container .account-products-container .list-card .foll {
  box-shadow: none;
  margin-top: 4px;
}
#root .container .account .account-container .account-products-container .list-card .info {
  padding: 10px;
  flex: 1;
  border-bottom: 1px solid #bbb;
}
#root .container .account .account-container .account-products-container .list-card .info h2 {
  font-size: 15px;
  color: #ff7a5a;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .account-products-container .list-card .info h2 {
    margin-top: 0px;
  }
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .list-card .info h2 {
    padding: 0px;
  }
}
#root .container .account .account-container .account-products-container .list-card .info h3 {
  font-size: 12px;
  color: #777;
  font-weight: 500;
  margin-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
#root .container .account .account-container .account-products-container .list-card .info h3 .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .account .account-container .account-products-container .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
#root .container .account .account-container .account-products-container .buttons button {
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 0% 0% 50% 50%;
  outline: none;
  border: none;
  background-color: rgb(255, 43, 43);
  cursor: pointer;
}
#root .container .account .account-container .account-products-container .buttons button:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .account .account-container .account-products-container .unseen {
  background: rgb(255, 223, 214);
}
#root .container .account .account-container .account-products-container .card {
  text-decoration: none;
  color: #000;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  margin-top: 15px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
#root .container .account .account-container .account-products-container .card:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .account .account-container .account-products-container .card:hover {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);
}
#root .container .account .account-container .account-products-container .card:last-child {
  margin-bottom: 15px;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .account-products-container .card {
    margin-top: 10px;
  }
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .card {
    margin-top: 15px;
    flex-direction: column;
    padding: 8px 10px;
    border-left: none;
    box-shadow: none;
    border-radius: 0px;
  }
}
#root .container .account .account-container .account-products-container .card .card-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 110px;
  height: 110px;
  background-color: #ddd;
  z-index: 70;
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .card .card-img {
    width: 100%;
    height: 180px;
  }
}
#root .container .account .account-container .account-products-container .card .card-img img {
  width: 100%;
  height: 100%;
}
#root .container .account .account-container .account-products-container .card .card-text {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-start;
  margin-left: 8px;
  overflow: hidden;
  width: 100%;
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .card .card-text {
    margin-left: 0px;
  }
}
#root .container .account .account-container .account-products-container .card .card-text h1 {
  font-size: 20px;
  text-decoration: none;
  color: #222;
  text-align: left;
  width: 100%;
  text-overflow: ellipsis;
  font-weight: 500;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}
@media screen and (max-width: 800px) {
  #root .container .account .account-container .account-products-container .card .card-text h1 {
    font-size: 17px;
  }
}
@media screen and (max-width: 720px) {
  #root .container .account .account-container .account-products-container .card .card-text h1 {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .card .card-text h1 {
    display: -webkit-box;
    max-height: 3.2rem;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: 2;
    letter-spacing: normal;
    line-height: 1.4rem;
    max-height: 4.4rem;
  }
}
#root .container .account .account-container .account-products-container .card .card-text p {
  text-decoration: none;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ff7a5a;
  font-size: 14px;
}
#root .container .account .account-container .account-products-container .card .card-text .price {
  text-decoration: none;
  color: #222;
  font-size: 20px;
  font-weight: bolder;
  margin-top: 10px;
}
@media screen and (max-width: 800px) {
  #root .container .account .account-container .account-products-container .card .card-text .price {
    font-size: 17px;
  }
}
@media screen and (max-width: 720px) {
  #root .container .account .account-container .account-products-container .card .card-text .price {
    font-size: 16px;
  }
}
#root .container .account .account-container .account-products-container .card .card-text .likes-views {
  width: 100%;
  display: flex;
  margin-top: 16px;
}
#root .container .account .account-container .account-products-container .card .card-text .likes-views .likes {
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
  flex: 1;
}
#root .container .account .account-container .account-products-container .card .card-text .likes-views .likes .dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .account .account-container .account-products-container .card .card-text .likes-views .views {
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px;
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .card .card-text .likes-views .views {
    padding: 0px;
  }
}
#root .container .account .account-container .account-products-container .card .card-text .likes-views .views .dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .account .account-container .account-products-container .card form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .card form {
    width: 100%;
  }
}
#root .container .account .account-container .account-products-container .card form button {
  padding: 8px 12px;
  margin-left: 4px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
}
@media screen and (max-width: 380px) {
  #root .container .account .account-container .account-products-container .card form button {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
#root .container .account .account-container .account-products-container .card form button:hover {
  background-color: #ddd;
  color: #222;
}
#root .container .buyer {
  margin-bottom: 40px;
}
#root .container .buyer .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  #root .container .buyer .msg {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .msg {
    margin-top: 20px;
  }
}
#root .container .buyer .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .buyer .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .buyer .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  #root .container .buyer .msg {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .msg {
    margin-top: 20px;
  }
}
#root .container .buyer .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .buyer .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .buyer .buyer-acc-cont {
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  #root .container .buyer .buyer-acc-cont {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .buyer-acc-cont {
    margin-top: 20px;
  }
}
#root .container .buyer .buyer-acc-cont .account-info-container h2 {
  text-align: center;
}
#root .container .buyer .buyer-acc-cont .account-info-container h3 {
  text-align: center;
  margin-bottom: 10px;
}
#root .container .buyer .account-nav {
  width: 80%;
  margin-top: 20px;
  padding: 0px 10px;
  display: flex;
  justify-content: space-evenly;
}
@media screen and (max-width: 1400px) {
  #root .container .buyer .account-nav {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .buyer .account-nav {
    width: 100%;
  }
}
#root .container .buyer .account-nav p {
  font-size: 16px;
  color: #777;
  padding: 12px 40px;
  cursor: pointer;
  width: 50%;
  text-align: left;
  border-bottom: 1px solid #bbb;
  transition: all 300ms ease-in-out;
}
@media screen and (max-width: 600px) {
  #root .container .buyer .account-nav p {
    padding: 10px 20px;
  }
}
@keyframes hoverAnimate {
  0% {
    background-color: #eee;
  }
  100% {
    background-color: #ddd;
  }
}
#root .container .buyer .account-nav p:hover {
  animation: hoverAnimate 0.75s linear 1;
}
#root .container .buyer .account-nav .like {
  text-align: right;
}
#root .container .buyer .account-nav .active {
  color: #222;
  border-bottom: 2px solid #222;
}
#root .container .buyer .following-container {
  width: 80%;
  margin-top: 12px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  animation: animateCont 0.25s linear 1;
}
@media screen and (max-width: 1400px) {
  #root .container .buyer .following-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .buyer .following-container {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .buyer .following-container {
    padding: 0px;
  }
}
@keyframes animateCont {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .buyer .following-container .heading {
  width: 50%;
}
@media screen and (max-width: 1000px) {
  #root .container .buyer .following-container .heading {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .buyer .following-container .heading {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .following-container .heading {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .buyer .following-container .heading {
    padding: 0px 10px;
  }
}
#root .container .buyer .following-container .heading h1 {
  font-size: 20px;
  color: #555;
  padding: 4px;
  font-weight: 600;
}
#root .container .buyer .following-container .shop-card {
  text-decoration: none;
  color: #000;
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 12px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  margin: 8px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  display: inline-block;
}
#root .container .buyer .following-container .shop-card:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 1000px) {
  #root .container .buyer .following-container .shop-card {
    width: 70%;
  }
}
@media screen and (max-width: 800px) {
  #root .container .buyer .following-container .shop-card {
    width: 90%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .following-container .shop-card {
    width: 100%;
    margin: 5px;
  }
}
@media screen and (max-width: 400px) {
  #root .container .buyer .following-container .shop-card {
    padding: 8px 10px;
    box-shadow: none;
    border-radius: 0px;
    border-left: none;
  }
}
#root .container .buyer .following-container .shop-card .col-1 {
  flex: 1;
}
#root .container .buyer .following-container .shop-card .col-1 .top-row {
  display: flex;
  flex-direction: row;
}
#root .container .buyer .following-container .shop-card .col-1 .top-row .img {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background-color: #ddd;
}
#root .container .buyer .following-container .shop-card .col-1 .top-row .img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
#root .container .buyer .following-container .shop-card .col-1 .top-row .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-left: 10px;
}
#root .container .buyer .following-container .shop-card .col-1 .top-row .text h1 {
  font-size: 20px;
  font-weight: bolder;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 720px) {
  #root .container .buyer .following-container .shop-card .col-1 .top-row .text h1 {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .following-container .shop-card .col-1 .top-row .text h1 {
    font-size: 16px;
  }
}
#root .container .buyer .following-container .shop-card .col-1 .top-row .text .location {
  color: #777;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  font-size: 15px;
}
@media screen and (max-width: 600px) {
  #root .container .buyer .following-container .shop-card .col-1 .top-row .text .location {
    font-size: 14px;
  }
}
#root .container .buyer .following-container .shop-card .col-1 .bottom-row {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}
#root .container .buyer .following-container .shop-card .col-1 .bottom-row p.aboutShop {
  color: #000;
  font-weight: 500;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: 400;
  letter-spacing: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4rem;
  max-height: 4.4rem;
  font-size: 20px;
}
@media screen and (max-width: 720px) {
  #root .container .buyer .following-container .shop-card .col-1 .bottom-row p.aboutShop {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .following-container .shop-card .col-1 .bottom-row p.aboutShop {
    font-size: 16px;
  }
}
#root .container .buyer .following-container .shop-card .col-1 .whs {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  padding: 4px;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .open {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: rgb(14, 136, 14);
  font-size: 14px;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .open i {
  margin-right: 6px;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .open .text {
  color: #000;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .open .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .closed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: rgb(255, 43, 43);
  font-size: 14px;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .closed i {
  margin-right: 6px;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .closed .text {
  color: #000;
}
#root .container .buyer .following-container .shop-card .col-1 .whs .closed .dot {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #777;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0px 5px 0px 5px;
}
#root .container .buyer .following-container .shop-card .col-1 .stats {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  width: 100%;
}
#root .container .buyer .following-container .shop-card .col-1 .stats p {
  position: relative;
  font-size: 14px;
  color: #777;
  display: flex;
  align-items: center;
}
#root .container .buyer .following-container .shop-card .col-1 .stats p span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .buyer .following-container .shop-card .col-1 .stats .visits {
  flex: 1;
}
#root .container .buyer .following-container .shop-card .col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  padding: 0px;
}
#root .container .buyer .following-container .shop-card .col-2 form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .buyer .following-container .shop-card .col-2 form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  #root .container .buyer .following-container .shop-card .col-2 form button {
    padding: 8px 12px;
    font-size: 14px;
  }
}
#root .container .buyer .following-container .shop-card .col-2 form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .buyer .following-container .shop-card .col-2 form .unfollow {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .buyer .following-container .shop-card .col-2 form .unfollow:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .buyer .liked-container {
  width: 80%;
  margin-top: 12px;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  animation: animateCont 0.25s linear 1;
  display: none;
}
@media screen and (max-width: 1400px) {
  #root .container .buyer .liked-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .buyer .liked-container {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  #root .container .buyer .liked-container {
    padding: 0px;
  }
}
@keyframes animateCont {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .buyer .liked-container .heading {
  margin-top: -20px;
}
#root .container .buyer .liked-container .heading h1 {
  font-size: 20px;
  color: #555;
  padding: 4px;
  font-weight: 600;
}
#root .container .buyer .liked-container .prod-card-container {
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}
@media screen and (max-width: 400px) {
  #root .container .buyer .liked-container .prod-card-container::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  #root .container .buyer .liked-container .prod-card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 800px) {
  #root .container .buyer .liked-container .prod-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 350px) {
  #root .container .buyer .liked-container .prod-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
#root .container .buyer .liked-container .prod-card-container .card {
  position: relative;
  border-radius: 5px;
  text-decoration: none;
  margin: 10px;
  background-color: #fff;
  overflow: hidden;
}
@media screen and (max-width: 351px) {
  #root .container .buyer .liked-container .prod-card-container .card {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .liked-container .prod-card-container .card {
    margin: 5px;
    padding: 0px;
    box-shadow: none;
    border: 1px solid #ddd;
  }
}
@media screen and (max-width: 500px) {
  #root .container .buyer .liked-container .prod-card-container .card {
    margin: 0px;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid #ddd;
  }
  #root .container .buyer .liked-container .prod-card-container .card:hover {
    box-shadow: none;
  }
}
#root .container .buyer .liked-container .prod-card-container .card .prod-img {
  height: 350px;
  width: 100%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 5px 5px 0px 0px;
}
@media screen and (max-width: 1200px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-img {
    height: 220px;
    border-radius: 0px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-img {
    height: 300px;
  }
}
@media screen and (max-width: 270px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-img {
    height: 200px;
  }
}
#root .container .buyer .liked-container .prod-card-container .card .prod-img img {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 500px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-img img {
    border-radius: 0px;
  }
}
#root .container .buyer .liked-container .prod-card-container .card .prod-img p {
  color: #777;
}
#root .container .buyer .liked-container .prod-card-container .card .load {
  background-color: #ddd;
  color: #ddd;
  margin-top: 5px;
  overflow: hidden;
}
#root .container .buyer .liked-container .prod-card-container .card .load:first-child {
  margin-top: 0px;
}
#root .container .buyer .liked-container .prod-card-container .card .load span {
  color: #ddd;
}
#root .container .buyer .liked-container .prod-card-container .card .load:nth-child(2) .load:nth-child(2) {
  width: 80%;
}
#root .container .buyer .liked-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) {
  width: 40%;
}
#root .container .buyer .liked-container .prod-card-container .card .load:nth-child(2) .load:nth-child(3) h3 {
  color: #ddd;
}
#root .container .buyer .liked-container .prod-card-container .card .load:nth-child(2) form button {
  width: 100%;
  padding: 10px 24px;
  background-color: #ddd;
  border: none;
}
#root .container .buyer .liked-container .prod-card-container .card .slide-anime {
  background-color: rgba(255, 255, 255, 0.513);
  box-shadow: 24px 3px 36px rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8%;
  animation: light-slide 2.5s linear infinite;
}
@keyframes light-slide {
  0% {
    left: 0%;
    width: 8%;
    background-color: rgba(255, 255, 255, 0.513);
  }
  50% {
    left: 100%;
    width: 5%;
    background-color: rgb(255, 255, 255);
  }
  70% {
    width: 2%;
  }
  100% {
    left: 100%;
    width: 2%;
  }
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info {
  width: 100%;
  background-color: transparent;
  padding: 10px;
  margin-top: 0px;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info p {
  color: #333;
  display: -webkit-box;
  max-height: 3.2rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 18px;
  line-height: 1.4rem;
  max-height: 4.4rem;
}
@media screen and (max-width: 600px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-info p {
    font-size: 16px;
  }
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .prod-name {
  height: 48px;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .shop-name {
  color: #777;
  font-size: 14px;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .shop-name span {
  font-style: italic;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .shop-name .verified {
  color: #fff;
  background: #ff7a5a;
  padding: 2px;
  border-radius: 50%;
  font-size: 8px;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes h3 {
  flex: 1;
  font-size: 18px;
  font-weight: bolder;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 600px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes h3 {
    font-size: 16px;
  }
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes p.likes {
  position: relative;
  color: #777;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 0px 0px 4px;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes p.likes i:hover {
  color: #ff7a5a;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes p.likes i.active {
  color: #ff7a5a;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes p.likes span.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ff7a5a;
  margin-left: 5px;
  margin-right: 5px;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info .price-likes p.likes span.stat.active {
  color: #ff7a5a;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info form {
  margin-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info form button {
  padding: 8px 20px;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  background-color: #ff7a5a;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  #root .container .buyer .liked-container .prod-card-container .card .prod-info form button {
    padding: 8px 12px;
  }
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info form button:hover {
  background-color: rgb(255, 223, 214);
  color: #222;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info form .unlike {
  background-color: transparent;
  color: #777;
  border: 1px solid #bbb;
}
#root .container .buyer .liked-container .prod-card-container .card .prod-info form .unlike:hover {
  background-color: #ddd;
  color: #777;
}
#root .container .img-upload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10101010;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .img-upload .contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: 80%;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25), 0px 0px 24px rgba(255, 255, 255, 0.25), 0px 0px 36px rgba(255, 255, 255, 0.25), 0px 0px 72px rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
}
@media screen and (max-width: 1200px) {
  #root .container .img-upload .contents {
    width: 80%;
    height: 80%;
  }
}
@media screen and (max-width: 500px) {
  #root .container .img-upload .contents {
    width: 100%;
    height: 80%;
  }
}
@media screen and (max-height: 300px) {
  #root .container .img-upload .contents {
    height: 100%;
  }
}
#root .container .img-upload .contents .img-container {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  position: relative;
}
@media screen and (max-width: 1500px) {
  #root .container .img-upload .contents .img-container {
    width: 380px;
    height: 380px;
  }
}
@media screen and (max-width: 900px) {
  #root .container .img-upload .contents .img-container {
    width: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .img-upload .contents .img-container {
    width: 200px;
    height: 200px;
  }
}
#root .container .img-upload .contents .img-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(0px);
}
#root .container .img-upload .contents .img-container .square-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  filter: blur(1px) invert(40%);
}
#root .container .img-upload .contents .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .img-upload .contents .buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  background-color: #ff7a5a;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 40px 6px 0px 6px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  #root .container .img-upload .contents .buttons button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .img-upload .contents .buttons button {
    font-size: 12px;
  }
}
#root .container .img-upload .contents .buttons button:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
#root .container .img-upload .contents .buttons button:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .img-upload .contents .buttons .cancel {
  background-color: transparent;
  border: 1px solid #bbb;
  color: #777;
}
#root .container .img-upload .contents .buttons .cancel:hover {
  color: #777;
}
#root .container .form-popup-container {
  position: fixed;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  background-size: cover;
  display: none;
  z-index: 100000000;
  height: 100%;
  animation: animate-links 0.3s linear 1;
  overflow: auto;
}
@keyframes animate-links {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .form-popup-container .form {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  animation: animateHamMenu 0.3s linear 1;
  transform-origin: left;
}
#root .container .form-popup-container .form form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  width: 30%;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px 15px;
}
@media screen and (max-width: 1300px) {
  #root .container .form-popup-container .form form {
    width: 40%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .form-popup-container .form form {
    width: 60%;
  }
}
@media screen and (max-width: 700px) {
  #root .container .form-popup-container .form form {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  #root .container .form-popup-container .form form {
    width: 90%;
  }
}
@media screen and (max-width: 480px) {
  #root .container .form-popup-container .form form {
    width: 100%;
    border-radius: 0px;
  }
}
#root .container .form-popup-container .form form .heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .form-popup-container .form form .heading h3 {
  font-size: 24px;
  font-weight: 520;
  margin: 18px 0px 0px 0px;
}
@media screen and (max-width: 720px) {
  #root .container .form-popup-container .form form .heading h3 {
    font-size: 21px;
  }
}
#root .container .form-popup-container .form form .msg {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .form-popup-container .form form .msg .warning-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 227, 168);
  border-left: 3px solid rgb(185, 127, 0);
  margin-bottom: 12px;
}
#root .container .form-popup-container .form form .msg .warning-msg p {
  font-size: 13px;
  font-weight: bold;
  color: rgb(185, 127, 0);
}
#root .container .form-popup-container .form form .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  margin-bottom: 12px;
}
#root .container .form-popup-container .form form .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .form-popup-container .form form .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  margin-bottom: 12px;
}
#root .container .form-popup-container .form form .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .form-popup-container .form form .day-card {
  width: 100%;
}
#root .container .form-popup-container .form form .day-card .inp {
  width: 100%;
}
#root .container .form-popup-container .form form .day-card .inp input {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 500px) {
  #root .container .form-popup-container .form form .day-card .inp input {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .form-popup-container .form form .day-card .inp input {
    font-size: 13px;
  }
}
#root .container .form-popup-container .form form .day-card .inp textarea {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  border: 3px solid #ff7a5a;
  resize: none;
  height: 100px;
}
@media screen and (max-width: 500px) {
  #root .container .form-popup-container .form form .day-card .inp textarea {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .form-popup-container .form form .day-card .inp textarea {
    font-size: 13px;
  }
}
#root .container .form-popup-container .form form .day-card .inp select {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 500px) {
  #root .container .form-popup-container .form form .day-card .inp select {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .form-popup-container .form form .day-card .inp select {
    font-size: 13px;
  }
}
#root .container .form-popup-container .form form .day-card .inp label i {
  margin-right: 8px;
}
#root .container .form-popup-container .form form button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  background-color: #ff7a5a;
  cursor: pointer;
  background-color: #ff7a5a;
  color: #fff;
  font-weight: bold;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 300ms ease-in-out;
}
@media screen and (max-width: 500px) {
  #root .container .form-popup-container .form form button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .form-popup-container .form form button {
    font-size: 12px;
  }
}
#root .container .form-popup-container .form form button:hover {
  box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
  color: #fff;
}
#root .container .form-popup-container .form form .btn-animate {
  background-color: #ddd;
  color: #bbb;
  animation: animate 1.25s alternate infinite;
  border: 3px solid #ddd;
  pointer-events: none;
}
@keyframes animate {
  0% {
    border: 3px solid #ccc;
    background-color: #ccc;
    color: #aaa;
  }
  100% {
    border: 3px solid #ddd;
    background-color: #ddd;
  }
}
#root .container .form-popup-container .form form p {
  width: 100%;
  font-size: 13px;
  text-align: center;
  transition: all 120ms ease-in-out;
  font-weight: bold;
  padding: 5px;
}
@media screen and (max-width: 350px) {
  #root .container .form-popup-container .form form p {
    font-size: 11px;
  }
}
#root .container .form-popup-container .form form #err {
  background-color: rgb(255, 206, 206);
}
#root .container .form-popup-container .form form .cancel {
  width: 100%;
  background-color: transparent;
  border: 1px solid #bbb;
  color: #777;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 20px;
}
#root .container .form-popup-container .form form .cancel:hover {
  color: #777;
  text-decoration: none;
}
#root .container .orders {
  background-color: transparent;
  width: 100%;
  min-height: 90vh;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
}
#root .container .orders .orders-card-container {
  position: relative;
  width: 60%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
}
@media screen and (max-width: 1400px) {
  #root .container .orders .orders-card-container {
    width: 80%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .orders .orders-card-container {
    width: 100%;
    padding: 0px 10px;
  }
}
@media screen and (max-width: 500px) {
  #root .container .orders .orders-card-container {
    padding: 0px;
  }
}
#root .container .orders .orders-card-container .note {
  width: 100%;
  padding: 10px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .orders .orders-card-container .note p {
  text-align: center;
  color: rgb(185, 127, 0);
}
#root .container .orders .orders-card-container .no-orders {
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  color: #bbb;
}
@media screen and (max-width: 800px) {
  #root .container .orders .orders-card-container .no-orders {
    font-size: 20px;
  }
}
#root .container .orders .orders-card-container .delete-all-form {
  margin-top: 12px;
  width: 100%;
  border-bottom: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 6px;
}
#root .container .orders .orders-card-container .delete-all-form .search {
  margin-right: 5px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  padding: 8px 10px;
  background-color: transparent;
  color: #777;
  font-size: 16px;
}
#root .container .orders .orders-card-container .delete-all-form .decoy-cont {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.36);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 10;
  animation: fade 0.25s linear 1;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .orders .orders-card-container .delete-all-form .decoy-cont .search-cont {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 1010;
}
#root .container .orders .orders-card-container .delete-all-form .decoy-cont .search-cont button {
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
  padding: 8px;
  width: 5%;
  color: #777;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  #root .container .orders .orders-card-container .delete-all-form .decoy-cont .search-cont button {
    width: 5.25%;
  }
}
@media screen and (max-width: 380px) {
  #root .container .orders .orders-card-container .delete-all-form .decoy-cont .search-cont button {
    width: 10%;
  }
}
#root .container .orders .orders-card-container .delete-all-form .decoy-cont .search-cont input {
  width: 90%;
  padding: 10px;
  border: none;
  border-left: 1px solid #ddd;
  background-color: #fff;
  outline: none;
}
@media screen and (max-width: 600px) {
  #root .container .orders .orders-card-container .delete-all-form .decoy-cont .search-cont input {
    width: 85%;
    border-left: none;
  }
}
@media screen and (max-width: 380px) {
  #root .container .orders .orders-card-container .delete-all-form .decoy-cont .search-cont input {
    width: 80%;
  }
}
#root .container .orders .orders-card-container .delete-all-form .delete-all,
#root .container .orders .orders-card-container .delete-all-form select {
  background-color: rgb(255, 43, 43);
  color: #fff;
  border: 1px solid #bbb;
  padding: 10px 20px;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
#root .container .orders .orders-card-container .delete-all-form .delete-all:hover,
#root .container .orders .orders-card-container .delete-all-form select:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .orders .orders-card-container .delete-all-form select {
  padding: 8px 10px;
  background-color: transparent;
  margin-right: 10px;
  color: #777;
}
#root .container .orders .orders-card-container .delete-all-form select:hover {
  background-color: transparent;
}
#root .container .orders .orders-card-container .order-notif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 19990;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  padding: 10px;
  animation: scaling 0.2s linear 1;
  transform-origin: top;
}
@keyframes scaling {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
#root .container .orders .orders-card-container .order-notif h3 {
  justify-self: flex-start;
  color: #fff;
  background-color: #ff7a5a;
  padding: 10px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}
#root .container .orders .orders-card-container .order-notif button {
  padding: 10px 24px;
  color: #ff7a5a;
  font-size: 20px;
  border: none;
  background-color: transparent;
  outline: none;
  margin-top: 20px;
  cursor: pointer;
}
#root .container .orders .orders-card-container .msg {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
#root .container .orders .orders-card-container .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .orders .orders-card-container .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .orders .orders-card-container .msg {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
#root .container .orders .orders-card-container .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .orders .orders-card-container .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .orders .orders-card-container .card {
  text-decoration: none;
  color: #000;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  margin-top: 20px;
  transition: all 120ms ease-in-out;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
}
#root .container .orders .orders-card-container .card:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 500px) {
  #root .container .orders .orders-card-container .card {
    border-radius: 0px;
    box-shadow: none;
  }
  #root .container .orders .orders-card-container .card:hover {
    box-shadow: none;
  }
}
#root .container .orders .orders-card-container .card:hover {
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
}
#root .container .orders .orders-card-container .card:last-child {
  margin-bottom: 15px;
}
#root .container .orders .orders-card-container .card h2 {
  font-size: 20px;
  color: #222;
}
#root .container .orders .orders-card-container .card h2 span {
  color: #ff7a5a;
}
@media screen and (max-width: 800px) {
  #root .container .orders .orders-card-container .card h2 {
    font-size: 17px;
  }
}
#root .container .orders .orders-card-container .card .heading {
  width: 100%;
  padding: 10px;
  background-color: #ddd;
  margin-top: 15px;
}
#root .container .orders .orders-card-container .card .heading h2 {
  font-size: 20px;
  color: #777;
}
#root .container .orders .orders-card-container .card .heading h2 span {
  color: #ff7a5a;
}
#root .container .orders .orders-card-container .card .heading h2 .orderStatus {
  color: #777;
  font-weight: 500;
  font-size: 16px;
}
@media screen and (max-width: 800px) {
  #root .container .orders .orders-card-container .card .heading h2 {
    font-size: 17px;
  }
}
#root .container .orders .orders-card-container .card .heading .order-ti {
  background-color: #fff;
  padding: 10px;
  position: relative;
  margin-top: 10px;
}
#root .container .orders .orders-card-container .card .heading .order-ti .ti {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
#root .container .orders .orders-card-container .card .heading .order-ti .ti:first-child {
  margin-top: 0;
}
#root .container .orders .orders-card-container .card .heading .order-ti .ti .dot {
  width: 15px;
  height: 15px;
  margin-right: 6px;
  border-radius: 50%;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#root .container .orders .orders-card-container .card .heading .order-ti .ti .dot div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
#root .container .orders .orders-card-container .card .heading .order-ti .active {
  color: #ff7a5a;
  font-size: 16px;
}
#root .container .orders .orders-card-container .card .heading .order-ti .active .dot {
  border: 1px solid #ff7a5a;
}
#root .container .orders .orders-card-container .card .heading .order-ti .active .dot div {
  background-color: #ff7a5a;
}
#root .container .orders .orders-card-container .card .heading .order-ti .not-active {
  color: #bbb;
  font-size: 12px;
}
#root .container .orders .orders-card-container .card .heading .order-ti .not-active .dot {
  width: 11px;
  height: 11px;
  border: 1px solid #bbb;
}
#root .container .orders .orders-card-container .card .heading .order-ti .not-active .dot div {
  background-color: #bbb;
}
#root .container .orders .orders-card-container .card .order-details {
  margin-top: 15px;
}
#root .container .orders .orders-card-container .card .order-details p {
  margin-top: 5px;
}
#root .container .orders .orders-card-container .card .order-details p span {
  font-weight: bold;
}
#root .container .orders .orders-card-container .card .order-details .order-address {
  font-weight: normal;
  cursor: pointer;
}
#root .container .orders .orders-card-container .card .order-details .order-address:hover {
  color: #ff7a5a;
  text-decoration: underline;
}
#root .container .orders .orders-card-container .card .items {
  width: 100%;
}
#root .container .orders .orders-card-container .card .items .items-card {
  text-decoration: none;
  color: #000;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin-top: 15px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
#root .container .orders .orders-card-container .card .items .items-card:hover {
  background-color: #eee;
}
#root .container .orders .orders-card-container .card .items .items-card:last-child {
  border-bottom: 0px;
}
@media screen and (max-width: 600px) {
  #root .container .orders .orders-card-container .card .items .items-card {
    margin-top: 10px;
  }
}
#root .container .orders .orders-card-container .card .items .items-card .card-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  height: 90px;
  background-color: #ddd;
}
#root .container .orders .orders-card-container .card .items .items-card .card-img img {
  width: 100%;
  height: 100%;
}
#root .container .orders .orders-card-container .card .items .items-card .card-text {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: flex-start;
  margin-left: 8px;
  overflow: hidden;
}
#root .container .orders .orders-card-container .card .items .items-card .card-text h1 {
  text-decoration: none;
  color: #222;
  font-size: 20px;
  font-weight: 500;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 800px) {
  #root .container .orders .orders-card-container .card .items .items-card .card-text h1 {
    font-size: 17px;
  }
}
@media screen and (max-width: 720px) {
  #root .container .orders .orders-card-container .card .items .items-card .card-text h1 {
    font-size: 16px;
  }
}
#root .container .orders .orders-card-container .card .items .items-card .card-text p {
  text-decoration: none;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ff7a5a;
}
@media screen and (max-width: 500px) {
  #root .container .orders .orders-card-container .card .items .items-card .card-text p {
    font-size: 15px;
  }
}
#root .container .orders .orders-card-container .card .items .items-card .card-text .price {
  text-decoration: none;
  color: #222;
  font-size: 20px;
  font-weight: bolder;
  margin-top: 20px;
}
@media screen and (max-width: 800px) {
  #root .container .orders .orders-card-container .card .items .items-card .card-text .price {
    font-size: 17px;
  }
}
@media screen and (max-width: 720px) {
  #root .container .orders .orders-card-container .card .items .items-card .card-text .price {
    font-size: 16px;
  }
}
#root .container .orders .orders-card-container .card .items .items-card .qty {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5px;
  overflow: hidden;
}
#root .container .orders .orders-card-container .card .items .items-card .qty p {
  color: #ff7a5a;
  font-weight: bold;
}
#root .container .orders .orders-card-container .card .items .items-card .istatus {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  border-radius: 0 0 5px 5px;
  color: #fff;
  font-size: 11px;
}
#root .container .orders .orders-card-container .card .items .items-card .pp {
  background-color: rgb(255, 43, 43);
}
#root .container .orders .orders-card-container .card .items .items-card .pd {
  background-color: rgb(185, 127, 0);
}
#root .container .orders .orders-card-container .card .items .items-card .cmp {
  background-color: rgb(14, 136, 14);
}
#root .container .orders .orders-card-container .card .status-select {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 15px;
  overflow: hidden;
  overflow-x: auto;
}
#root .container .orders .orders-card-container .card .status-select .status-option {
  border: 1px solid #ddd;
  padding: 10px;
  width: 25%;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  color: #777;
  transition: all 250ms ease-in-out;
}
@media screen and (max-width: 570px) {
  #root .container .orders .orders-card-container .card .status-select .status-option {
    min-width: 150px;
  }
}
#root .container .orders .orders-card-container .card .status-select .status-option:hover {
  background-color: #eee;
}
#root .container .orders .orders-card-container .card .status-select .active {
  background-color: rgb(255, 223, 214);
  border: 1px solid #ff7a5a;
  color: #ff7a5a;
}
#root .container .orders .orders-card-container .card .deactivate {
  display: none;
}
#root .container .orders .orders-card-container .card .total {
  font-size: 20px;
  color: #222;
  margin-top: 15px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@media screen and (max-width: 800px) {
  #root .container .orders .orders-card-container .card .total {
    font-size: 17px;
  }
}
#root .container .orders .orders-card-container .card .total span {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  color: #fff;
  border: 1px solid rgb(14, 136, 14);
  border-radius: 50%;
  background-color: rgba(14, 136, 14, 0.835);
  margin-left: 10px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
}
#root .container .orders .orders-card-container .card .total span:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .orders .orders-card-container .card .total .undo {
  border: 1px solid rgb(255, 227, 168);
  background-color: rgb(185, 127, 0);
}
#root .container .orders .orders-card-container .card .warn {
  color: rgb(185, 127, 0);
}
#root .container .orders .orders-card-container .card .success {
  color: rgb(14, 136, 14);
}
#root .container .orders .orders-card-container .card form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .orders .orders-card-container .card form .pay {
  background-color: rgb(14, 136, 14);
  margin-right: 10px;
}
#root .container .orders .orders-card-container .card form .pay:hover {
  background-color: rgba(14, 136, 14, 0.835);
}
#root .container .orders .orders-card-container .card form .delete {
  background-color: rgb(255, 43, 43);
}
#root .container .orders .orders-card-container .card form .delete:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .orders .orders-card-container .card form button {
  outline: none;
  border: none;
  color: #fff;
  padding: 10px;
  font-size: 20px;
  width: 100%;
  background-color: #ff7a5a;
  text-align: center;
  margin-top: 15px;
  border-radius: 5px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  #root .container .orders .orders-card-container .card form button {
    font-size: 17px;
  }
}
#root .container .orders .orders-card-container .buyer-card:last-child {
  margin-bottom: 40px;
}
#root .container .buyer {
  margin-top: 60px;
}
#root .container .register-login form input[type=file] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: none;
}
#root .container .register-login form textarea {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  border: 3px solid #ff7a5a;
  resize: none;
  height: 100px;
}
@media screen and (max-width: 500px) {
  #root .container .register-login form textarea {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .register-login form textarea {
    font-size: 13px;
  }
}
#root .container .register-login form label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  border: 1px solid #bbb;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  color: #777;
  border-radius: 5px;
}
@media screen and (max-width: 500px) {
  #root .container .register-login form label {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .register-login form label {
    font-size: 12px;
  }
}
#root .container .account {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-height: 90vh;
}
#root .container .account .btn-holder {
  width: 80%;
  border-bottom: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 6px;
  position: relative;
}
@media screen and (max-width: 1400px) {
  #root .container .account .btn-holder {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .btn-holder {
    width: 100%;
  }
}
#root .container .account .btn-holder h2 {
  font-size: 20px;
  color: #777;
  flex: 1;
  justify-self: flex-start;
  align-self: flex-start;
  text-align: left;
  margin-right: 10px;
  width: 90%;
  padding: 5px;
}
#root .container .account .btn-holder button {
  margin-right: 5px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  padding: 8px 10px;
  background-color: transparent;
  color: #777;
  font-size: 16px;
}
#root .container .account .btn-holder .delete-all {
  background-color: rgb(255, 43, 43);
  color: #fff;
  margin-left: 5px;
  border: 1px solid #bbb;
}
#root .container .account .btn-holder .delete-all:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .account .btn-holder .decoy-cont {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.36);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 10;
  animation: fade 0.25s linear 1;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#root .container .account .btn-holder .decoy-cont .search-cont {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 1010;
}
#root .container .account .btn-holder .decoy-cont .search-cont button {
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
  padding: 8px;
  width: 5%;
  color: #777;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  #root .container .account .btn-holder .decoy-cont .search-cont button {
    width: 5.25%;
  }
}
@media screen and (max-width: 380px) {
  #root .container .account .btn-holder .decoy-cont .search-cont button {
    width: 10%;
  }
}
#root .container .account .btn-holder .decoy-cont .search-cont input {
  width: 90%;
  padding: 10px;
  border: none;
  border-left: 1px solid #ddd;
  background-color: #fff;
  outline: none;
}
@media screen and (max-width: 600px) {
  #root .container .account .btn-holder .decoy-cont .search-cont input {
    width: 85%;
    border-left: none;
  }
}
@media screen and (max-width: 380px) {
  #root .container .account .btn-holder .decoy-cont .search-cont input {
    width: 80%;
  }
}
#root .container .account .btn-holder select {
  color: #fff;
  border: 1px solid #bbb;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  padding: 8px 10px;
  background-color: transparent;
  color: #777;
}
#root .container .account .btn-holder select:hover {
  background-color: transparent;
}
#root .container .account .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .account .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .account .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .account .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .account .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .account .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .account .pageForms {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 50px;
  padding: 10px;
}
#root .container .account .pageForms button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 8px 20px;
  margin: 10px;
  border: 1px solid #bbb;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  color: #777;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
}
@media screen and (max-width: 500px) {
  #root .container .account .pageForms button {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .account .pageForms button {
    font-size: 12px;
  }
}
#root .container .account .pageForms button:hover {
  background-color: #ddd;
}
#root .container .account .pageForms button i.back {
  margin-right: 8px;
}
#root .container .account .pageForms button i.next {
  margin-left: 8px;
}
#root .container .account .pageForms button.back {
  justify-self: flex-start;
}
#root .container .account .pageForms button.next {
  background-color: rgb(255, 223, 214);
  color: #777;
}
#root .container .account .account-container {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
}
@media screen and (max-width: 1400px) {
  #root .container .account .account-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
#root .container .account .account-container .table {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  background-color: #fff;
}
#root .container .account .account-container .table:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container .table {
    box-shadow: none;
  }
}
#root .container .account .account-container .table:first-child {
  margin-top: 10px;
}
#root .container .account .account-container .table:last-child {
  margin-bottom: 20px;
}
#root .container .account .account-container .table form.main-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
}
#root .container .account .account-container .table form.main-form:first-child {
  margin-top: 0px;
}
#root .container .account .account-container .table form.main-form .heading {
  width: 100%;
  background-color: rgb(255, 223, 214);
  grid-template-columns: repeat(1, 1fr);
  overflow: hidden;
}
#root .container .account .account-container .table form.main-form .heading h2 {
  font-size: 20px;
  padding: 10px;
  font-weight: 500;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .container .account .account-container .table form.main-form .heading h2 span {
  color: #fff;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 999px;
}
#root .container .account .account-container .table form.main-form .heading h2 .paid {
  background-color: rgb(14, 136, 14);
}
#root .container .account .account-container .table form.main-form .heading h2 .unpaid {
  background-color: rgb(255, 43, 43);
}
#root .container .account .account-container .table form.main-form .heading .content {
  background-color: #fff;
  border-top: 1px solid #bbb;
  flex: 1;
}
#root .container .account .account-container .table form.main-form .heading .content:first-child {
  border-top: none;
}
#root .container .account .account-container .table form.main-form .heading .content div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0px;
  transition: all 300ms ease-in-out;
  display: flex;
}
#root .container .account .account-container .table form.main-form .heading .content div:hover {
  background-color: #eee;
}
#root .container .account .account-container .table form.main-form .heading .content div p {
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16.5px;
  background-color: transparent;
  border: none;
  outline: none;
  margin-left: 4px;
}
#root .container .account .account-container .table form.main-form .heading .content div .edit {
  width: -moz-fit-content;
  width: fit-content;
  justify-self: flex-end;
  color: #ff7a5a;
  cursor: pointer;
  padding-left: 6px;
}
#root .container .account .account-container .table form.main-form button {
  width: 100%;
  background-color: #00aaa0;
  padding: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16.5px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}
#root .container .account .account-container .table form.main-form button:hover {
  background-color: rgb(14, 136, 14);
  color: #fff;
}
#root .container .account .account-container .table form.delete-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .account .account-container .table form.delete-form:last-of-type {
  margin-bottom: 50px;
}
#root .container .account .account-container .table form.delete-form button {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16.5px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  background-color: #ff7a5a;
}
#root .container .account .account-container .table form.delete-form button:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .buyer {
  margin-bottom: 40px;
}
#root .container .buyer .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  #root .container .buyer .msg {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .msg {
    margin-top: 20px;
  }
}
#root .container .buyer .msg .success-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(200, 255, 200);
  border-left: 3px solid green;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .buyer .msg .success-msg p {
  font-size: 13px;
  font-weight: bold;
  color: green;
}
#root .container .buyer .msg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  #root .container .buyer .msg {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .msg {
    margin-top: 20px;
  }
}
#root .container .buyer .msg .error-msg {
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 5px;
  background-color: rgb(255, 206, 206);
  border-left: 3px solid red;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 12px;
}
#root .container .buyer .msg .error-msg p {
  font-size: 13px;
  font-weight: bold;
  color: red;
}
#root .container .buyer .buyer-acc-cont {
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  #root .container .buyer .buyer-acc-cont {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  #root .container .buyer .buyer-acc-cont {
    margin-top: 20px;
  }
}
#root .container .buyer .buyer-acc-cont .account-info-container h2 {
  text-align: center;
}
#root .container .buyer .buyer-acc-cont .account-info-container h3 {
  text-align: center;
  margin-bottom: 10px;
}
#root .container .glowing-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10101010;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#root .container .glowing-form .contents {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25), 0px 0px 24px rgba(255, 255, 255, 0.25), 0px 0px 36px rgba(255, 255, 255, 0.25), 0px 0px 72px rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  animation: scale 0.3s linear 1;
  transform-origin: top;
}
@media screen and (max-width: 1200px) {
  #root .container .glowing-form .contents {
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  #root .container .glowing-form .contents {
    width: 100%;
  }
}
@keyframes scale {
  0% {
    transform: scaleY(0);
    box-shadow: none;
  }
  100% {
    transform: scaleY(1);
  }
}
#root .container .glowing-form .contents .close {
  width: 30px;
  height: 30px;
  align-self: flex-end;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
}
#root .container .glowing-form .contents .close:hover {
  background-color: #eee;
}
#root .container .glowing-form .contents input {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-bottom: 3px solid #ff7a5a;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid #bbb;
}
@media screen and (max-width: 500px) {
  #root .container .glowing-form .contents input {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .glowing-form .contents input {
    font-size: 13px;
  }
}
#root .container .glowing-form .contents p {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}
#root .container .glowing-form .contents button,
#root .container .glowing-form .contents a {
  width: 100%;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border: 3px solid #ff7a5a;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: #ff7a5a;
  color: #fff;
  padding: 12px;
  text-decoration: none;
}
@media screen and (max-width: 500px) {
  #root .container .glowing-form .contents button,
  #root .container .glowing-form .contents a {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .glowing-form .contents button,
  #root .container .glowing-form .contents a {
    font-size: 12px;
  }
}
#root .container .glowing-form .contents .row-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
#root .container .glowing-form .contents .row-btns button {
  margin: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .glowing-form .contents .row-btns button i {
  margin-left: 6px;
}
#root .container .glowing-form .contents .row-btns .red {
  background-color: rgb(255, 43, 43);
  border: 3px solid rgb(255, 43, 43);
}
#root .container .glowing-form .contents .row-btns .red:hover {
  background-color: rgba(255, 43, 43, 0.835);
}
#root .container .glowing-form .contents .row-btns .green {
  background-color: rgb(14, 136, 14);
  border: 3px solid rgb(14, 136, 14);
}
#root .container .glowing-form .contents .row-btns .green:hover {
  background-color: rgba(14, 136, 14, 0.835);
}
#root .container .glowing-form .contents .dry {
  background-color: transparent;
  border: 1px solid #bbb;
  color: #777;
  padding: 12px;
  border-radius: 5px;
}
#root .container .glowing-form .contents .dry:hover {
  color: #777;
  text-decoration: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
}
#root .container .account {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-height: 90vh;
}
#root .container .account .account-container {
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
  width: 80%;
}
@media screen and (max-width: 1400px) {
  #root .container .account .account-container {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container {
    width: 100%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .account .account-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
#root .container .account .account-container .stats {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
#root .container .account .account-container .stats .mobile-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#root .container .account .account-container .stats .mobile-view .btn {
  padding: 12px 48px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 6px;
  font-family: "Poppins", sans-serif;
  background-color: rgb(14, 136, 14);
  margin-top: 20px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 10px 30px rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  #root .container .account .account-container .stats .mobile-view .btn {
    font-size: 16px;
  }
}
@media screen and (max-width: 350px) {
  #root .container .account .account-container .stats .mobile-view .btn {
    font-size: 12px;
  }
}
#root .container .account .account-container .stats .mobile-view .total {
  font-size: 24px;
  font-weight: 500;
}
#root .container .account .account-container .stats .mobile-view .total span {
  font-weight: bold;
}
#root .container .account .account-container .stats .mobile-view .heading {
  padding: 18px 10px;
  width: 100%;
  border: 1px solid rgb(219, 217, 217);
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .stats .mobile-view .heading {
    padding: 10px;
  }
}
#root .container .account .account-container .stats .mobile-view .heading:first-child {
  margin-top: 0px;
}
#root .container .account .account-container .stats .mobile-view .heading h2 {
  position: relative;
  color: #777;
  font-weight: 500;
  font-size: 24px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .stats .mobile-view .heading h2 {
    font-size: 18px;
  }
}
#root .container .account .account-container .stats .mobile-view .card-menu {
  display: flex;
  background-color: #ff7a5a;
  padding: 6px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  margin-top: 12px;
}
#root .container .account .account-container .stats .mobile-view .card-menu .text {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#root .container .account .account-container .stats .mobile-view .card-menu .text p {
  color: #fff;
  font-size: 15px;
  pointer-events: none;
}
#root .container .account .account-container .stats .mobile-view .card-menu .text h2 {
  font-weight: 500;
  font-size: 24px;
  color: #fff;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .stats .mobile-view .card-menu .text h2 {
    font-size: 20px;
  }
}
#root .container .account .account-container .stats .mobile-view .card-menu .icon {
  pointer-events: none;
  margin-left: 6px;
}
#root .container .account .account-container .stats .mobile-view .card-menu .icon i {
  color: #fff;
  font-size: 24px;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .stats .mobile-view .card-menu .icon i {
    font-size: 20px;
  }
}
#root .container .account .account-container .stats .mobile-view .card-content {
  background-color: #fff;
  animation: scale 0.3s linear 1;
  padding: 5px;
  width: 100%;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  transform-origin: top;
  display: none;
}
@keyframes scale {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
#root .container .account .account-container .stats .mobile-view .card-content p {
  font-size: 20px;
  font-weight: bold;
  color: #ff7a5a;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .container .account .account-container .stats .mobile-view .card-content p span {
  font-weight: 500;
  color: #000;
}
#root .container .account .account-container .stats .mobile-view .users-container {
  background-color: #fff;
  padding: 30px 30px;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  margin-top: 12px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  position: relative;
}
#root .container .account .account-container .stats .mobile-view .users-container:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
#root .container .account .account-container .stats .mobile-view .users-container::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 50px;
  background: rgba(255, 255, 255, 0.05);
}
#root .container .account .account-container .stats .mobile-view .users-container span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #53281d, #ff7a5a);
  animation: animate1 2s linear infinite;
}
@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
#root .container .account .account-container .stats .mobile-view .users-container span:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, #53281d, #ff7a5a);
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}
@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
#root .container .account .account-container .stats .mobile-view .users-container span:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, #53281d, #ff7a5a);
  animation: animate3 2s linear infinite;
}
@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
#root .container .account .account-container .stats .mobile-view .users-container span:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to top, #53281d, #ff7a5a);
  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}
@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
#root .container .account .account-container .stats .mobile-view .users-container h1 {
  font-size: 2em;
  font-weight: 500;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .stats .mobile-view .users-container h1 {
    font-size: 26px;
  }
}
#root .container .account .account-container .stats .mobile-view .users-container h2 {
  font-size: 4em;
}
@media screen and (max-width: 600px) {
  #root .container .account .account-container .stats .mobile-view .users-container h2 {
    font-size: 3em;
  }
}
#root .container .account .account-container .stats .mobile-view .users-container p {
  color: #ff7a5a;
}
#root .container .cashout-cont {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
}
@media screen and (max-width: 600px) {
  #root .container .cashout-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px;
  }
}
#root .container .cashout-cont .cashout-card {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3), 0 1px 3px 1px rgba(63, 51, 48, 0.15);
  transition: all 300ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px;
  margin: 4px;
}
#root .container .cashout-cont .cashout-card:hover {
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 600px) {
  #root .container .cashout-cont .cashout-card {
    width: 100%;
    margin: 4px 0px 10px 0px;
  }
}
#root .container .cashout-cont .cashout-card .top-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 10px;
}
#root .container .cashout-cont .cashout-card .top-row p {
  flex: 1;
  color: #777;
}
#root .container .cashout-cont .cashout-card .top-row .delete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: rgb(255, 43, 43);
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
#root .container .cashout-cont .cashout-card .bottom-row {
  padding: 10px;
  width: 100%;
}
#root .container .cashout-cont .cashout-card .bottom-row h1 {
  font-weight: 500;
  font-size: 36px;
  display: block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .container .cashout-cont .cashout-card .bottom-row h2 {
  font-size: 16px;
  font-weight: 500;
}
#root .container .cashout-cont .cashout-card .bottom-row h2 span {
  font-weight: bolder;
  color: rgb(14, 136, 14);
}
#root .container .tile-container {
  width: 100%;
  min-height: 83.5vh;
  background-color: transparent;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
#root .container .tile-container .heading {
  margin-top: 12px;
  width: 100%;
  background-color: rgb(255, 223, 214);
  padding: 5px;
  border-radius: 5px;
  border-left: 5px solid #ff7a5a;
}
@media screen and (max-width: 600px) {
  #root .container .tile-container .heading {
    border-radius: 0px;
  }
}
#root .container .tile-container .heading h1 {
  font-size: 20px;
  color: #ff7a5a;
  padding: 4px;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 720px) {
  #root .container .tile-container .heading h1 {
    font-size: 18px;
  }
}
#root .container .tile-container .tile-content {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media screen and (max-width: 1400px) {
  #root .container .tile-container .tile-content {
    width: 92%;
  }
}
@media screen and (max-width: 900px) {
  #root .container .tile-container .tile-content {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  #root .container .tile-container .tile-content {
    justify-content: center;
  }
}
#root .container .tile-container .tile-content .tile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 120px;
  text-decoration: none;
  transition: all 300ms ease-in-out;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
}
@media screen and (max-width: 560px) {
  #root .container .tile-container .tile-content .tile {
    margin: 6px;
  }
}
#root .container .tile-container .tile-content .tile:hover {
  background-color: transparent;
}
#root .container .tile-container .tile-content .tile .img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(255, 223, 214);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#root .container .tile-container .tile-content .tile .img p {
  font-size: 20px;
  color: #ff7a5a;
}
#root .container .tile-container .tile-content .tile .info {
  flex: 1;
  padding: 12px 0px;
}
#root .container .tile-container .tile-content .tile .info h2 {
  font-size: 15px;
  color: #ff7a5a;
  text-align: center;
}/*# sourceMappingURL=index.css.map */