.shop-card {
  text-decoration: none;
  color: #000;
  width: 50%;
  height: fit-content;
  padding: 8px 12px;
  // border-left: 6px solid $darkRed;
  background-color: #fff;
  @include cardShadow();
  border-radius: 5px;
  margin: 8px;
  transition: all 120ms ease-in-out;
  cursor: pointer;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  display: inline-block;

  @media screen and (max-width: 1000px) {
    width: 70%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 5px;
  }
  @media screen and (max-width: 400px) {
    padding: 8px 10px;
    box-shadow: none;
    border-radius: 0px;
    border-left: none;
  }

  .col-1 {
    flex: 1;

    // Top row
    .top-row {
      display: flex;
      flex-direction: row;

      .img {
        border-radius: 50%;
        width: 72px;
        height: 72px;
        background-color: #ddd;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        margin-left: 10px;
        h1 {
          font-size: 20px;
          font-weight: bolder;
          @media screen and (max-width: 720px) {
            font-size: 18px;
          }
          @media screen and (max-width: 600px) {
            font-size: 16px;
          }
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .location {
          color: #777;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 3px;
          font-size: 15px;

          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }
      }
    }

    // Bottom-row
    .bottom-row {
      display: flex;
      flex-direction: column;
      margin-top: 12px;

      p.aboutShop {
        color: #000;
        font-weight: 500;
        display: -webkit-box;
        max-height: calc(2 * 1.6rem);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        font-weight: 400;
        letter-spacing: normal;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.4rem;
        max-height: 4.4rem;
        font-size: 20px;
        @media screen and (max-width: 720px) {
          font-size: 18px;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
    .whs {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      width: 100%;
      border-top: 1px solid #bbb;
      border-bottom: 1px solid #bbb;
      padding: 4px;

      .open {
        @include flexCenter(row);
        color: rgb(14, 136, 14);
        font-size: 14px;
        i {
          margin-right: 6px;
        }
        .text {
          color: #000;
        }
        .dot {
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background: #777;
          @include flexCenter(row);
          margin: 0px 5px 0px 5px;
        }
      }
      .closed {
        @include flexCenter(row);
        color: rgb(255, 43, 43);
        font-size: 14px;
        i {
          margin-right: 6px;
        }
        .text {
          color: #000;
        }
        .dot {
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background: #777;
          @include flexCenter(row);
          margin: 0px 5px 0px 5px;
        }
      }
    }
    .stats {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      width: 100%;

      p {
        position: relative;
        font-size: 14px;
        color: #777;
        display: flex;
        align-items: center;

        span.dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $darkRed;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      .visits {
        flex: 1;
      }
    }
  }

  .col-2 {
    @include flexCenter(row);
    margin-top: 12px;
    padding: 0px;

    form {
      @include flexCenter(row);
      width: 100%;

      button {
        padding: 8px 20px;
        width: 100%;
        margin-left: 0px;
        cursor: pointer;
        background-color: $darkRed;
        border: none;
        outline: none;
        color: #fff;
        border-radius: 5px;
        transition: all 120ms ease-in-out;
        font-size: 16px;
        @media screen and (max-width: 600px) {
          padding: 8px 12px;
          font-size: 14px;
        }

        &:hover {
          background-color: $lightRed;
          color: #222;
        }
      }
      .unfollow {
        background-color: transparent;
        color: #777;
        border: 1px solid #bbb;
        &:hover {
          background-color: #ddd;
          color: #777;
        }
      }
    }
  }
}
