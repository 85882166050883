.list-card {
  @include flexCenter(row);
  justify-content: flex-start;
  width: 100%;
  text-decoration: none;
  transition: all 300ms ease-in-out;

  .list-card:last-child {
    margin-bottom: 20px;
    .info {
      border-bottom: none;
    }
  }

  &:hover {
    background-color: #eee;
  }
  .img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ddd;
    @include flexCenter(row);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);

    p {
      font-size: 20px;
      color: $darkRed;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .foll {
    box-shadow: none;
    margin-top: 4px;
  }
  .info {
    padding: 10px;
    flex: 1;
    border-bottom: 1px solid #bbb;

    h2 {
      font-size: 15px;
      color: $darkRed;
      font-weight: bold;
      @media screen and (max-width: 600px) {
        margin-top: 0px;
      }
      @media screen and (max-width: 380px) {
        padding: 0px;
      }
    }
    h3 {
      font-size: 12px;
      color: #777;
      font-weight: 500;
      margin-top: 2px;
      @include flexCenter(row);
      justify-content: flex-start;
      .dot {
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background: #777;
        @include flexCenter(row);
        margin: 0px 5px 0px 5px;
      }
    }
  }
}

.buttons {
  @include flexCenter(row);
  justify-content: flex-end;
  width: 100%;
  button {
    height: 30px;
    width: 30px;
    color: #fff;
    border-radius: 0% 0% 50% 50%;
    outline: none;
    border: none;
    background-color: $err;
    cursor: pointer;

    &:hover {
      background-color: $errHover;
    }
  }
}

.unseen {
  background: $lightRed;
}
