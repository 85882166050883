.account {
  width: 100%;
  margin-top: 20px;
  @include flexCenter(column);
  justify-content: flex-start;
  position: relative;

  @media screen and (max-width: 900px) {
    min-height: 90vh;
  }

  // Search feature
  .search-cont {
    @include mainWidth();
    background-color: transparent;
    margin-top: 15px;
    @media screen and (max-width: 900px) {
      padding: 10px;
    }

    .inputs {
      width: 100%;
      display: flex;
      input {
        @include inputs();
        flex: 1;
      }
      button {
        @include buttons();
        width: 100px;
        background-color: $darkRed;
        color: #fff;
        border-radius: 0px 5px 5px 0px;
        cursor: pointer;
      }
    }

    .info {
      margin-top: 10px;
      width: 100%;
      background-color: #fff;
      padding: 10px;
      @include cardShadow();
      border-radius: 5px;

      p {
        span {
          font-weight: bold;
        }
      }

      h1 {
        margin-top: 10px;
        font-size: 20px;
        font-weight: bold;
        span {
          font-weight: 500;
          color: $darkRed;
        }
      }
    }
  }

  .msg {
    @include flexCenter(column);
    .success-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(200, 255, 200);
      border-left: 3px solid green;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: green;
      }
    }
  }
  .msg {
    @include flexCenter(column);
    .error-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 206, 206);
      border-left: 3px solid red;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: red;
      }
    }
  }

  // For list cards
  .cards-container {
    @include mainWidth();
    display: block;
    overflow: hidden;

    @import "../assets/list-card";

    .not-found {
      margin-left: 10px;
      color: #bbb;
    }
  }

  .account-container {
    height: fit-content;
    @include flexCenter(row);
    align-items: flex-start;

    @include mainWidth();

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    // Transactions
    .stats {
      .mobile-view {
        .fees-cont {
          background-color: transparent;
          width: 100%;
          margin-top: 12px;

          h2 {
            font-size: 20px;
            font-weight: 500;
            margin-top: 10px;
          }

          .tile-cont {
            background-color: transparent;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .tile {
              margin: 10px;
              padding: 15px;
              border: 1px solid #bbb;
              border-radius: 5px;
              background-color: #fff;
              &:nth-child(1) {
                margin-left: 0px;
              }
              p {
                color: $darkRed;
                margin-top: 10px;
              }
              h3 {
                font-size: 16px;
              }
            }
          }
        }

        // For admin transactions
        .transact-cont {
          background-color: transparent;
          width: 100%;
          margin-top: 12px;

          &:last-child {
            margin-bottom: 40px;
          }

          .top-row {
            @include flexCenter(row);
            padding: 10px;
            border-bottom: 1px solid #bbb;
            p {
              flex: 1;
              color: #777;
            }
            button {
              padding: 8px 11px;
              background-color: $err;
              color: #fff;
              border: none;
              outline: none;
              border-radius: 50%;
              cursor: pointer;

              &:hover {
                background-color: $errHover;
              }
            }
          }
          .transact-no {
            border-bottom: 1px solid #bbb;
            padding: 10px;
            background-color: #fff;

            h2 {
              font-size: 20px;
            }
          }
          .bottom-row {
            border-bottom: 1px solid #bbb;
            padding: 10px;
            background-color: #fff;

            .items {
              width: 100%;
              background-color: #ddd;
              padding: 5px 10px 10px 10px;
              margin-bottom: 10px;
              .item {
                @include flexCenter(row);
                justify-content: start;
                color: #777;
                margin-top: 5px;
                p {
                  flex: 1;
                  font-size: 14px;
                }
                span {
                  font-size: 14px;
                }
              }
            }

            h3 {
              margin-top: 5px;
              font-size: 20px;
              color: $darkRed;
            }
          }
        }
      }
    }

    // Buyer account styles
    .buyer-account {
      margin-top: -20px;
      margin-bottom: 10px;

      @media screen and (max-width: 900px) {
        margin-top: -30px;
      }
    }

    .account-info-container {
      position: relative;
      width: 40%;
      height: 100%;
      background-color: transparent;
      @include flexCenter(column);
      justify-content: flex-start;
      padding: 0px 10px;
      overflow: hidden;

      @media screen and (max-width: 1400px) {
        width: 50%;
      }

      @media screen and (max-width: 900px) {
        width: 90%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      // account profile pic
      .info-img {
        position: relative;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: #eee;
        z-index: 10;
        border: 2px solid #bbb;

        @media screen and (max-height: 600px) {
          width: 200px;
          height: 200px;
        }

        @media screen and (max-width: 600px) {
          width: 200px;
          height: 200px;
        }
        @media screen and (max-width: 250px) {
          width: 160px;
          height: 160px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        // Upload button for admins
        .upload-btn {
          position: absolute;
          bottom: 22%;
          @media screen and (max-width: 600px) {
            bottom: 13%;
          }
          right: -2%;
          span {
            font-size: 16px;
            height: 45px;
            width: 45px;
            border-radius: 50px;
            background: $darkRed;
            @include flexCenter(row);
            border: 2px solid #bbb;

            input[type="file"] {
              @include flexCenter(row);
              display: none;
            }
            label {
              @include flexCenter(row);
              font-size: 16px;
              color: #fff;
              cursor: pointer;
              position: absolute;
              outline: none;
              border: none;
              background-color: transparent;
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }
        }
      }

      // The shop info (Shop name and email)
      .info-text {
        width: 100%;
        overflow: hidden;
        align-self: flex-start;
        @media screen and (max-width: 900px) {
          align-self: center;
        }
        .no-shopname {
          color: #bbb;
        }
        h2 {
          margin-top: 16px;
          font-size: 26px;
          color: #111;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          position: relative;

          @media screen and (max-width: 900px) {
            font-size: 24px;
          }

          @media screen and (max-width: 800px) {
            font-size: 22px;
          }

          // Verified badge
          .verified {
            color: #fff;
            background: $darkRed;
            padding: 2px;
            border-radius: 50%;
            font-size: 8px;
            margin-left: 2px;
            position: absolute;
            top: 0;
          }
        }
        h3 {
          font-size: 18px;
          color: #555;
          // margin-top: 5px;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;

          @media screen and (max-width: 800px) {
            font-size: 16px;
          }
        }

        .followers-whs {
          margin-top: 16px;
          width: 100%;
          display: flex;

          p.followers {
            flex: 1;
            position: relative;
            color: #777;
            display: flex;
            align-items: center;
            font-size: 14px;
            @media screen and (max-width: 900px) {
              text-align: center;
            }
            .dot {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $darkRed;
              margin-left: 5px;
              margin-right: 5px;
            }
          }
          .whs-btn {
            padding: 6px 10px;
            cursor: pointer;
            background-color: transparent;
            border: none;
            outline: none;
            color: $darkRed;
            border-radius: 5px;
            transition: all 120ms ease-in-out;
            border: 1px solid $darkRed;
            font-weight: bold;

            &:hover {
              background-color: $lightRed;
              color: #222;
            }

            i {
              margin-right: 6px;
            }
          }
        }
      }

      .line {
        width: 100%;
        height: 2px;
        background-color: #ddd;
        margin-top: 10px;
      }

      a {
        width: 80%;
        padding: 10px;
        font-size: 18px;
        color: #222;
        border: 1px solid #bbb;
        text-decoration: none;
        @include flexCenter(row);
        text-align: center;
        margin-top: 10px;
        transition: all 300ms ease-in-out;
        border-radius: 5px;

        @media screen and (max-width: 800px) {
          font-size: 16px;
        }

        background-color: $darkRed;
        color: #fff;

        &:hover {
          background-color: $darkRed;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          color: #fff;
        }
      }
      .logout {
        color: #777;
        background-color: transparent;
        &:hover {
          background-color: #ddd;
          box-shadow: none;
          color: #777;
        }
      }
    }

    .account-products-container {
      width: 60%;
      height: 85.5vh;
      background-color: transparent;
      padding: 0px 10px;
      overflow: auto;
      display: block;

      // Scroll bar
      &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
        margin-right: 12px;
      }
      &::-webkit-scrollbar-track {
        border: none;
        background-color: #ddd;
      }
      &::-webkit-scrollbar-thumb {
        background: #bbb;
        width: 2px;
      }

      @media screen and (max-width: 1400px) {
        width: 50%;
      }

      @media screen and (max-width: 900px) {
        width: 90%;
        border-left: none;
        align-self: center;
        height: 100%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      .line {
        width: 100%;
        height: 2px;
        background-color: #ddd;
        margin-top: 10px;
        display: none;

        @media screen and (max-width: 900px) {
          display: block;
        }
      }

      .no-items {
        margin-top: 15px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #bbb;
      }

      h2 {
        font-size: 24px;
        color: #777;
        font-weight: 500;

        @media screen and (max-width: 900px) {
          margin-top: 20px;
          font-size: 18px;
        }

        @media screen and (max-width: 800px) {
          font-size: 16px;
        }
        @media screen and (max-width: 380px) {
          padding: 0px 10px;
        }
      }

      // List card
      @import "../assets/list-card";

      .card {
        text-decoration: none;
        color: #000;
        width: 100%;
        height: fit-content;
        padding: 8px;
        background-color: #fff;
        @include cardShadow();
        border-radius: 5px;
        margin-top: 15px;
        transition: all 120ms ease-in-out;
        cursor: pointer;
        word-wrap: break-word;
        @include flexCenter(row);
        justify-content: flex-start;

        &:hover {
          box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.3);
        }

        &:last-child {
          margin-bottom: 15px;
        }

        @media screen and (max-width: 600px) {
          margin-top: 10px;
        }
        @media screen and (max-width: 380px) {
          margin-top: 15px;
          flex-direction: column;
          padding: 8px 10px;
          border-left: none;
          box-shadow: none;
          border-radius: 0px;
        }

        .card-img {
          position: relative;
          @include flexCenter(column);
          width: 110px;
          height: 110px;
          background-color: #ddd;
          z-index: 70;

          @media screen and (max-width: 380px) {
            width: 100%;
            height: 180px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .card-text {
          position: relative;
          flex: 1;
          @include flexCenter(column);
          align-self: flex-start;
          justify-content: flex-start;
          margin-left: 8px;
          overflow: hidden;
          @media screen and (max-width: 380px) {
            margin-left: 0px;
          }
          width: 100%;

          h1 {
            font-size: 20px;
            text-decoration: none;
            color: #222;
            text-align: left;
            width: 100%;
            text-overflow: ellipsis;
            font-weight: 500;
            overflow: hidden;
            display: block;
            white-space: nowrap;

            @media screen and (max-width: 800px) {
              font-size: 17px;
            }
            @media screen and (max-width: 720px) {
              font-size: 16px;
            }
            @media screen and (max-width: 380px) {
              display: -webkit-box;
              max-height: calc(2 * 1.6rem);
              -webkit-box-orient: vertical;
              white-space: normal;
              -webkit-line-clamp: 2;
              letter-spacing: normal;
              line-height: 1.4rem;
              max-height: 4.4rem;
            }
          }
          p {
            text-decoration: none;
            display: block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $darkRed;
            font-size: 14px;

            // @media screen and (max-width: 500px) {
            //   font-size: 15px;
            // }
          }
          .price {
            text-decoration: none;
            color: #222;
            font-size: 20px;
            font-weight: bolder;
            margin-top: 10px;
            @media screen and (max-width: 800px) {
              font-size: 17px;
            }
            @media screen and (max-width: 720px) {
              font-size: 16px;
            }
          }

          .likes-views {
            width: 100%;
            display: flex;
            margin-top: 16px;

            .likes {
              position: relative;
              color: #777;
              display: flex;
              align-items: center;
              font-size: 14px;
              flex: 1;

              // width: 100%;
              .dot {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $darkRed;
                margin-left: 5px;
                margin-right: 5px;
              }
            }
            .views {
              position: relative;
              color: #777;
              display: flex;
              align-items: center;
              font-size: 14px;
              padding: 4px;
              @media screen and (max-width: 380px) {
                padding: 0px;
              }

              .dot {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $darkRed;
                margin-left: 5px;
                margin-right: 5px;
              }
            }
          }
        }

        // Card buttons
        form {
          @include flexCenter(row);
          @media screen and (max-width: 380px) {
            width: 100%;
          }

          button {
            padding: 8px 12px;
            margin-left: 4px;
            cursor: pointer;
            background-color: $darkRed;
            border: none;
            outline: none;
            color: #fff;
            border-radius: 5px;
            transition: all 120ms ease-in-out;

            @media screen and (max-width: 380px) {
              width: 100%;
              margin-left: 0px;
              margin-top: 10px;
            }

            &:hover {
              background-color: #ddd;
              color: #222;
            }
          }
        }
      }
    }
  }
}

.buyer {
  margin-bottom: 40px;

  .msg {
    @include flexCenter(column);
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }
    .success-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(200, 255, 200);
      border-left: 3px solid green;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: green;
      }
    }
  }
  .msg {
    @include flexCenter(column);
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }
    .error-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 206, 206);
      border-left: 3px solid red;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: red;
      }
    }
  }
  .buyer-acc-cont {
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }

    .account-info-container {
      h2 {
        text-align: center;
      }
      h3 {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  .account-nav {
    @include mainWidth();
    margin-top: 20px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-evenly;

    p {
      font-size: 16px;
      color: #777;
      padding: 12px 40px;
      cursor: pointer;
      width: 50%;
      text-align: left;
      border-bottom: 1px solid #bbb;
      transition: all 300ms ease-in-out;

      @media screen and (max-width: 600px) {
        padding: 10px 20px;
      }

      @keyframes hoverAnimate {
        0% {
          background-color: #eee;
        }
        100% {
          background-color: #ddd;
        }
      }

      &:hover {
        animation: hoverAnimate 0.75s linear 1;
      }
    }
    .like {
      text-align: right;
    }
    .active {
      color: #222;
      border-bottom: 2px solid #222;
    }
  }

  .following-container {
    @include mainWidth();
    margin-top: 12px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    animation: animateCont 0.25s linear 1;

    @media screen and (max-width: 400px) {
      padding: 0px;
    }

    @keyframes animateCont {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .heading {
      width: 50%;
      @media screen and (max-width: 1000px) {
        width: 70%;
      }
      @media screen and (max-width: 800px) {
        width: 90%;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      @media screen and (max-width: 400px) {
        padding: 0px 10px;
      }

      h1 {
        font-size: 20px;
        color: #555;
        padding: 4px;
        // border-bottom: 1px solid rgba(60, 61, 67, 0.3);
        font-weight: 600;
        // @media screen and (max-width: 720px) {
        //   font-size: 24px;
        // }
      }
    }

    // Shop card
    @import "../assets/shop-card";
  }

  .liked-container {
    @include mainWidth();
    margin-top: 12px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    animation: animateCont 0.25s linear 1;
    display: none;
    @media screen and (max-width: 400px) {
      padding: 0px;
    }

    @keyframes animateCont {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .heading {
      margin-top: -20px;
      h1 {
        font-size: 20px;
        color: #555;
        padding: 4px;
        font-weight: 600;
      }
    }

    // Product card container
    @import "../assets/product-card-container";
  }
}

// Image upload popup
@import "./components/img-upload";
// Working hours
@import "./components/form-popup";
