.products {
  @include flexCenter(column);
  justify-content: flex-start;
  padding: 0px 24px;
  min-height: 94vh;
  width: 100%;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px;
  }

  // Banner Messages
  @import "../assets/msg";

  // In the product preview page
  .product-container {
    text-decoration: none;
    color: #000;
    background-color: #fff;
    height: fit-content;
    border-radius: 5px;
    margin-top: 15px;
    transition: all 120ms ease-in-out;
    word-wrap: break-word;
    @include flexCenter(row);
    align-items: flex-start;
    cursor: default;
    @include mainWidth();
    // &:hover {
    //   box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
    //     0 1px 3px 1px rgba(63, 51, 48, 0.15);
    // }

    &:last-child {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 900px) {
      width: 90%;
      margin-top: 0px;
      flex-direction: column;
    }
    @media screen and (max-width: 600px) {
      width: 80%;
      border-radius: 0px;
    }
    @media screen and (max-width: 500px) {
      width: 90%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      box-shadow: none;
      border-radius: 0px;
    }

    .prod-image {
      position: relative;
      width: 50%;
      height: 500px;
      background-color: #ddd;
      @include flexCenter(column);

      @media screen and (max-width: 900px) {
        width: 100%;
      }

      @media screen and (max-width: 600px) {
        height: 400px;
      }
      @media screen and (max-width: 480px) {
        height: 300px;
      }

      img {
        width: 100%;
        height: 100%;
      }
      p {
        color: #777;
      }
      .icon {
        position: absolute;
        bottom: 0;
        right: 0;
        i {
          color: #222;
          font-size: 16px;
          width: 36px;
          height: 36px;
          border-radius: 50px;
          background: #fff;
          @include flexCenter(row);
          border: 1px solid #bbb;
        }
      }
    }

    // Product info
    .prod-info {
      width: 100% - 50%;
      height: fit-content;
      padding: 10px 15px;
      @include flexCenter(column);
      align-items: flex-start;

      @media screen and (max-width: 900px) {
        width: 100%;
        padding: 10px;
      }

      // @media screen and (max-width: 750px) {
      //   width: 100%;
      //   padding: 10px 0px;
      // }

      // Product name
      .prod-name {
        font-size: 24px;
        font-weight: bolder;
        color: #222;

        @media screen and (max-width: 900px) {
          display: -webkit-box;
          max-height: calc(2 * 1.6rem);
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          -webkit-line-clamp: 2;
          font-weight: bold;
          letter-spacing: normal;
          font-size: 18px;
          line-height: 1.4rem;
          max-height: 4.4rem;
        }
        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
      // Shop name
      p {
        font-size: 16px;
        color: #777;
        position: relative;

        @media screen and (max-width: 500px) {
          font-size: 15px;
        }

        .shop-name-link{
          text-decoration: none;
          color: #777;
        }
        // Verified badge
        .verified {
          color: #fff;
          background: $darkRed;
          padding: 2px;
          border-radius: 50%;
          font-size: 8px;
          margin-left: 2px;
          position: absolute;
          top: 0;
        }
      }

      // Price and lkes/views
      .price-likes {
        display: flex;
        width: 100%;
        margin-top: 8px;
        margin-top: 11px;
        h1 {
          flex: 1;
          font-size: 24px;
          font-weight: bolder;
          color: $darkRed;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: 900px) {
            font-size: 18px;
          }
          @media screen and (max-width: 600px) {
            font-size: 16px;
          }
        }
        p.likes {
          margin-bottom: 5px;
          position: relative;
          color: #777;
          display: flex;
          align-items: center;
          font-size: 18px;
          @media screen and (max-width: 900px) {
            font-size: 16px;
          }
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
          .dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $darkRed;
            margin-left: 5px;
            margin-right: 5px;
          }
          .sep {
            margin-left: 8px;
            margin-right: 8px;
            height: 18px;
            width: 2px;
            background-color: transparent;
          }
        }
      }

      .line {
        width: 100%;
        height: 2px;
        background-color: #ddd;
        margin-top: 5px;
      }

      ul {
        margin-left: 20px;
        li {
          font-size: 16px;
          color: #555;

          @media screen and (max-width: 220px) {
            display: block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      // Options (e.g sizes, Colors) etc
      .options-cont {
        .options {
          @include flexCenter(row);
          p {
            margin: 2px;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #bbb;
            cursor: pointer;
          }
          .active {
            background-color: $lightRed;
            border: 1px solid $darkRed;
            color: $darkRed;
          }
        }
      }

      form {
        width: 100%;
        @include flexCenter(row);
        button {
          @include buttons();
          background-color: $darkRed;
          color: #fff;
          cursor: pointer;
          text-align: center;
          &:hover {
            box-shadow: 1px 2px 8px 0px rgba(63, 51, 48, 0.3);
            color: #fff;
          }

          @media screen and (max-width: 350px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  // Input field for checking location of shop,
  // against the user's location
  .check-location {
    @include mainWidth();
    background-color: transparent;
    // margin-top: 15px;
    @media screen and (max-width: 600px) {
      padding: 10px;
    }

    .inputs {
      width: 100%;
      display: flex;
      input {
        @include inputs();
        flex: 1;
      }
      button {
        @include buttons();
        width: 100px;
        background-color: $darkRed;
        color: #fff;
        border-radius: 0px 5px 5px 0px;
        cursor: pointer;
      }
    }

    h1 {
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
      span {
        font-weight: 500;
      }
    }
  }

  // Browse shops
  .browse {
    color: $darkRed;
  }

  // Related products
  .related-products{
    @extend .product-container;
    flex-direction: column;
    margin-top: 15px;
    padding: 10px;

    h2 {
      font-size: 16px;
      font-weight: bolder;
      color: #555;
      display: block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .embla {
      width: 100%;
      align-self: center;
      padding:10px;
      position: relative;

      @import '../assets/product-card-container';
      .prod-card-container{
        // flex: 1;
        // display: flex;
        // justify-content: flex-start;
        
        // Change the width of the container
        //  so that it matches the width you want for the card
        width: 220px;
        margin-top: 0;
        margin-bottom: 10px;

        .card{
          @include prodCardShadow();

          @media screen and (max-width: 600px) {
            margin: 6px;
            border: none;
          }
          @media screen and (max-width: 500px) {
            @include prodCardShadow();
            border: none;
          }

          .prod-img{
            height: 150px;
          }
        }
      }

      .embla__btns{
        position: absolute;
        left: 0;
        top: 0;
        background-color: transparent;
        width: 100%;
        height: 100%;
        @include flexCenter(row);
        pointer-events: none;

        .btn:first-child{
          flex: 1;
        }
        .btn{
          button{
            pointer-events: all;
            @include flexCenter(row);
            width: 36px;
            height: 36px;
            outline: none;
            border: 1px solid #d4d3d3;
            color: #222;
            border-radius: 50%;
            background-color: rgba(241, 240, 240, 0.65);
            cursor: pointer;
            color: #555;
          }
        }
      }
    }

    .link-container{
      width: 100%;
      @include flexCenter(row);
      margin-bottom: 10px;
      
      .link{
        @include links();
        color: $darkRed;
        min-width: 70%;
        max-width: 100%;
        background-color: transparent;
        text-align: center;
        border: 1px solid #ddd;
        &:hover{
          background-color: $lightRed;
        }
      }
    }
  }

  // Product details
  .product-details {
    @extend .product-container;
    flex-direction: column;
    margin-top: 15px;
    padding: 10px;

    h1 {
      font-size: 16px;
      font-weight: bolder;
      color: #555;
      display: block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      margin-top: 12px;
      font-size: 14px;
      display: block;
      white-space: pre-line;
    }
  }

  // Reviews
  .reviews {
    @extend .product-details;
    margin-bottom: 50px;

    .review-nav {
      @include flexCenter(row);
      width: 100%;
      h1 {
        flex: 1;
      }
      button {
        padding: 6px;
        color: #777;
        border: 1px solid #bbb;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        @include flexCenter(row);
        transition: all 300ms ease-in-out;
        border-radius: 5px;
        &:hover {
          background-color: $lightRed;
        }
      }
    }

    .not-found {
      font-size: 16px;
      color: #bbb;
    }

    .ratings {
      @include flexCenter(row);
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        margin: 6px 10px 0px 0px;
        font-size: 20px;

        i {
          font-size: 20px;
        }
      }
      .red {
        color: $err;
      }
      .green {
        color: $success;
      }
    }

    .review {
      @include flexCenter(row);
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 15px;

      .img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #ddd;
        border: 1px solid #bbb;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .content {
        width: 90%;
        padding: 0px 10px;
        height: fit-content;
        flex-wrap: wrap;
        display: block;

        h2 {
          font-size: 14px;
          font-weight: bold;
          color: $darkRed;
          color: #777;
          display: block;
          white-space: nowrap;
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p {
          margin-top: 5px;
          color: #777;
          font-size: 14px;
        }
      }
    }
  }
}

// Large image
.large-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  display: none;
  animation: slowFade 0.5s linear 1;
  @include flexCenter(column);
  @keyframes slowFade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .close-cont {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .close-container {
      font-size: 24px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 30px;
      @media screen and (max-width: 1200px) {
        margin: 10px;
      }

      &:hover {
        color: red;
      }
    }
  }

  img {
    width: 80vw;
    height: 80vh;
    @media screen and (max-width: 1200px) {
      height: 60vh;
    }
    @media screen and (max-width: 900px) {
      width: 100vw;
    }
  }
}
