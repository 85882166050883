// Page form
.pageForms {
  width: 100%;
  @include flexCenter(row);
  margin-bottom: 50px;
  padding: 10px;
  button {
    @include flexCenter(row);
    padding: 8px 20px;
    margin: 10px;
    border: 1px solid #bbb;
    font-size: 18px;
    outline: none;
    cursor: pointer;
    color: #777;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    @media screen and (max-width: 500px) {
      font-size: 16px;
    }
    @media screen and (max-width: 350px) {
      font-size: 12px;
    }

    &:hover {
      background-color: #ddd;
    }

    i.back {
      margin-right: 8px;
    }
    i.next {
      margin-left: 8px;
    }
  }
  button.back {
    justify-self: flex-start;
  }
  button.next {
    background-color: $lightRed;
    color: #777;
  }
}
