.orders {
  background-color: transparent;
  width: 100%;
  min-height: 90vh;
  margin-top: 0px;
  @include flexCenter(row);
  align-items: flex-start;

  .orders-card-container {
    position: relative;
    width: 60%;
    height: fit-content;
    @include flexCenter(column);
    justify-content: flex-start;
    align-items: flex-start;
    background-color: transparent;

    @media screen and (max-width: 1400px) {
      width: 80%;
    }

    @media screen and (max-width: 900px) {
      width: 100%;
      padding: 0px 10px;
    }

    @media screen and (max-width: 500px) {
      padding: 0px;
    }

    .note {
      width: 100%;
      padding: 10px 10px 0px 10px;
      @include flexCenter(row);
      p {
        text-align: center;
        color: $warn;
      }
    }

    // No orders text
    .no-orders {
      justify-self: center;
      align-self: center;
      margin-top: 20px;
      text-align: center;
      font-size: 24px;
      color: #bbb;

      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
    }

    // Delete all items in order
    .delete-all-form {
      margin-top: 12px;
      width: 100%;
      border-bottom: 2px solid #ddd;
      @include flexCenter(row);
      align-items: flex-end;
      justify-content: flex-end;
      padding: 6px;

      // Search button
      .search {
        margin-right: 5px;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 300ms ease-in-out;
        padding: 8px 10px;
        background-color: transparent;
        color: #777;
        font-size: 16px;
      }

      // Search popup
      @import "../assets/search-popup";

      .delete-all,
      select {
        background-color: $err;
        color: #fff;
        border: 1px solid #bbb;
        padding: 10px 20px;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: all 300ms ease-in-out;

        &:hover {
          background-color: $errHover;
        }
      }

      select {
        padding: 8px 10px;
        background-color: transparent;
        margin-right: 10px;
        color: #777;
        &:hover {
          background-color: transparent;
        }
      }
    }

    // Orders notifications
    .order-notif {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 19990;
      @include flexCenter(column);
      display: none;
      padding: 10px;
      animation: scaling 0.2s linear 1;
      transform-origin: top;
      @keyframes scaling {
        0% {
          transform: scale(0.1);
        }
        100% {
          transform: scale(1);
        }
      }

      h3 {
        justify-self: flex-start;
        color: #fff;
        background-color: $darkRed;
        padding: 10px 24px;
        @include flexCenter(column);
        border-radius: 5px;
      }
      button {
        padding: 10px 24px;
        color: $darkRed;
        font-size: 20px;
        border: none;
        background-color: transparent;
        outline: none;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    // Msgs
    .msg {
      align-self: center;
      @include flexCenter(column);
      margin-top: 20px;
      .success-msg {
        width: fit-content;
        padding: 6px 15px;
        border-radius: 5px;
        background-color: rgb(200, 255, 200);
        border-left: 3px solid green;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: 12px;
        p {
          font-size: 13px;
          font-weight: bold;
          color: green;
        }
      }
    }
    .msg {
      align-self: center;
      @include flexCenter(column);
      margin-top: 20px;
      .error-msg {
        width: fit-content;
        padding: 6px 15px;
        border-radius: 5px;
        background-color: rgb(255, 206, 206);
        border-left: 3px solid red;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: 12px;
        p {
          font-size: 13px;
          font-weight: bold;
          color: red;
        }
      }
    }

    // Order cards
    .card {
      text-decoration: none;
      color: #000;
      width: 100%;
      height: fit-content;
      padding: 8px;
      background-color: #fff;
      @include cardShadow();
      border-radius: 5px;
      margin-top: 20px;
      transition: all 120ms ease-in-out;
      word-wrap: break-word;
      @include flexCenter(column);
      align-items: flex-start;
      
      @media screen and (max-width: 500px) {
        border-radius: 0px;
        box-shadow: none;
        &:hover {
          box-shadow: none;
        }
      }

      &:hover {
        box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
          0 1px 3px 1px rgba(63, 51, 48, 0.15);
      }

      &:last-child {
        margin-bottom: 15px;
      }

      h2 {
        font-size: 20px;
        color: #222;
        span {
          color: $darkRed;
        }

        @media screen and (max-width: 800px) {
          font-size: 17px;
        }
      }

      .heading {
        width: 100%;
        padding: 10px;
        background-color: #ddd;
        margin-top: 15px;
        h2 {
          font-size: 20px;
          color: #777;
          span {
            color: $darkRed;
          }
          .orderStatus {
            color: #777;
            font-weight: 500;
            font-size: 16px;
          }

          @media screen and (max-width: 800px) {
            font-size: 17px;
          }
        }

        .order-ti{
          background-color: #fff;
          padding: 10px;
          position: relative;
          margin-top: 10px;

          .ti{
            display: flex;
            align-items: center;
            margin-top: 10px;

            &:first-child{
              margin-top: 0;
            }

            .dot{
              width: 15px;
              height: 15px;
              margin-right: 6px;
              border-radius: 50%;
              padding: 1px;
              display: flex;
              justify-content: center;
              align-items: center;

              div{
                width: 5px;
                height: 5px;
                border-radius: 50%;
              }
              // position: absolute;
              // left: 0;
              // top: 0;
            }
          }

          .active{
            color: $darkRed;
            font-size: 16px;

            .dot{
              border: 1px solid $darkRed;
              div{
                background-color:$darkRed;
            }
          }
          }
          .not-active{
            color: #bbb;
            font-size: 12px;

            .dot{
              width: 11px;
              height: 11px;
              border: 1px solid #bbb;
              div{
                background-color: #bbb;
            }
          }
          }
        }
      }

      .order-details {
        margin-top: 15px;
        p {
          margin-top: 5px;
          span {
            font-weight: bold;
          }
        }

        .order-address{
          font-weight: normal;
          cursor: pointer;
          &:hover{
            color: $darkRed;
            text-decoration: underline;
          }
        }
      }

      .items {
        width: 100%;

        .items-card {
          text-decoration: none;
          color: #000;
          width: 100%;
          height: fit-content;
          padding: 8px;
          // border-radius: 5px;
          border-bottom: 1px solid #ddd;
          background-color: #fff;
          margin-top: 15px;
          transition: all 120ms ease-in-out;
          cursor: pointer;
          word-wrap: break-word;
          @include flexCenter(row);
          justify-content: flex-start;

          &:hover {
            background-color: #eee;
          }

          &:last-child {
            border-bottom: 0px;
          }
          @media screen and (max-width: 600px) {
            margin-top: 10px;
          }

          .card-img {
            position: relative;
            @include flexCenter(column);
            width: 100px;
            height: 90px;
            background-color: #ddd;

            // @media screen and (max-width: 720px) {
            //   width: 60px;
            // }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .card-text {
            position: relative;
            flex: 1;
            @include flexCenter(column);
            align-self: flex-start;
            margin-left: 8px;
            overflow: hidden;

            h1 {
              text-decoration: none;
              color: #222;
              font-size: 20px;
              font-weight: 500;

              @media screen and (max-width: 800px) {
                font-size: 17px;
              }
              @media screen and (max-width: 720px) {
                font-size: 16px;
              }
              display: block;
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            p {
              text-decoration: none;
              display: block;
              white-space: nowrap;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $darkRed;

              @media screen and (max-width: 500px) {
                font-size: 15px;
              }
            }
            .price {
              text-decoration: none;
              color: #222;
              font-size: 20px;
              font-weight: bolder;
              margin-top: 20px;
              @media screen and (max-width: 800px) {
                font-size: 17px;
              }
              @media screen and (max-width: 720px) {
                font-size: 16px;
              }
            }
          }

          .qty {
            position: relative;
            @include flexCenter(column);
            margin-left: 5px;
            overflow: hidden;

            p {
              color: $darkRed;
              font-weight: bold;
            }
          }

          // Item status
          .istatus {
            position: absolute;
            top: 0;
            right: 0;
            padding: 6px;
            border-radius: 0 0 5px 5px;
            color: #fff;
            font-size: 11px;
          }
          // pending payment, delivery and complete
          .pp {
            background-color: $err;
          }
          .pd {
            background-color: $warn;
          }
          .cmp {
            background-color: $success;
          }
        }
      }

      // Selecting status
      .status-select {
        @include flexCenter(row);
        justify-content: space-evenly;
        width: 100%;
        margin-top: 15px;
        overflow: hidden;
        overflow-x: auto;

        .status-option {
          border: 1px solid #ddd;
          padding: 10px;
          width: 25%;
          border-radius: 5px;
          margin: 5px;
          cursor: pointer;
          color: #777;
          transition: all 250ms ease-in-out;

          @media screen and (max-width:570px) {
            min-width: 150px;
          }

          &:hover {
            background-color: #eee;
          }
        }
        .active {
          background-color: $lightRed;
          border: 1px solid $darkRed;
          color: $darkRed;
        }
      }
      // Deactivating the options when the status is pending payment
      .deactivate {
        display: none;
      }

      .total {
        font-size: 20px;
        color: #222;
        margin-top: 15px;
        font-weight: bolder;
        @include flexCenter(row);

        @media screen and (max-width: 800px) {
          font-size: 17px;
        }

        span {
          @include flexCenter(row);
          font-size: 16px;
          color: #fff;
          border: 1px solid $success;
          border-radius: 50%;
          // width: 24px;
          // height: 24px;
          background-color: $successHover;
          margin-left: 10px;
          cursor: pointer;
          @include cardShadow();
        }
        .undo {
          border: 1px solid $warnHover;
          background-color: $warn;
        }
      }

      .warn {
        color: $warn;
      }
      .success {
        color: $success;
      }

      form {
        width: 100%;
        @include flexCenter(row);
        .pay {
          background-color: $success;
          margin-right: 10px;

          &:hover {
            background-color: $successHover;
          }
        }
        .delete {
          background-color: $err;
          &:hover {
            background-color: $errHover;
          }
        }
        button {
          outline: none;
          border: none;
          color: #fff;
          padding: 10px;
          font-size: 20px;
          width: 100%;
          background-color: $darkRed;
          text-align: center;
          margin-top: 15px;
          border-radius: 5px;
          transition: all 120ms ease-in-out;
          cursor: pointer;

          // &:hover {
          //   box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.25);
          // }

          @media screen and (max-width: 800px) {
            font-size: 17px;
          }
        }
      }
    }

    .buyer-card {
      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
}

.buyer {
  margin-top: 60px;
}
