.shops {
  width: 100%;
  min-height: 94vh;
  margin-top: 20px;

  @include flexCenter(column);
  padding: 0px 10px;
  justify-content: flex-start;
  @media screen and (max-width: 400px) {
    padding: 0px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 0px;
  }

  // Heading
  @import "../assets/heading";
  .heading {
    @include shopsWidth();
  }

  // Messages
  .msg {
    @include flexCenter(column);
    .warning-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 227, 168);
      border-left: 3px solid rgb(185, 127, 0);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: rgb(185, 127, 0);
      }
    }
  }
  .msg {
    @include flexCenter(column);
    .error-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 206, 206);
      border-left: 3px solid red;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      margin-top: 10px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: red;
      }
    }
  }
  .msg {
    @include flexCenter(column);
    .success-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(200, 255, 200);
      border-left: 3px solid green;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: green;
      }
    }
  }

  // Shop card
  .shop-card {
    text-decoration: none;
    color: #000;
    width: 50%;
    height: fit-content;
    padding: 8px 12px;
    // border-left: 6px solid $darkRed;
    background-color: #fff;
    @include cardShadow();
    border-radius: 5px;
    transition: all 120ms ease-in-out;
    cursor: pointer;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-bottom: 50px;
    }

    @media screen and (max-width: 1000px) {
      width: 70%;
    }
    @media screen and (max-width: 800px) {
      width: 90%;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-top: 10px;
    }
    @media screen and (max-width: 400px) {
      margin: 0px;
      margin-top: 15px;
      padding: 8px 10px;
      box-shadow: none;
      border-radius: 0px;
      border-left: none;
    }

    .col-1 {
      flex: 1;

      // Top row
      .top-row {
        display: flex;
        flex-direction: row;

        .img {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          background-color: #ddd;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        // Shop name and location
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 60%;
          margin-left: 10px;
          h1 {
            font-size: 20px;
            font-weight: bolder;
            @media screen and (max-width: 720px) {
              font-size: 18px;
            }
            @media screen and (max-width: 600px) {
              font-size: 16px;
            }
            display: block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;

            // Verified badge
            .verified {
              color: #fff;
              background: $darkRed;
              padding: 2px;
              border-radius: 50%;
              font-size: 8px;
              margin-left: 2px;
              position: absolute;
              top: 0;
            }
          }
          .location {
            color: #777;
            font-size: 15px;
            display: block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 3px;

            @media screen and (max-width: 600px) {
              font-size: 14px;
            }
          }
        }
      }

      // Bottom-row
      .bottom-row {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        p.aboutShop {
          color: #000;
          font-weight: 500;
          display: -webkit-box;
          max-height: calc(2 * 1.6rem);
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          -webkit-line-clamp: 2;
          font-weight: 400;
          letter-spacing: normal;
          font-weight: 500;
          line-height: 1.4rem;
          max-height: 4.4rem;
          font-size: 20px;
          @media screen and (max-width: 720px) {
            font-size: 18px;
          }
          @media screen and (max-width: 600px) {
            font-size: 16px;
          }
        }
      }
      .whs {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        width: 100%;
        border-top: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        padding: 4px;

        .open {
          @include flexCenter(row);
          color: rgb(14, 136, 14);
          font-size: 14px;
          i {
            margin-right: 6px;
          }
          .text {
            color: #000;
          }
          .dot {
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: #777;
            @include flexCenter(row);
            margin: 0px 5px 0px 5px;
          }
        }
        .closed {
          @include flexCenter(row);
          color: rgb(255, 43, 43);
          font-size: 14px;
          i {
            margin-right: 6px;
          }
          .text {
            color: #000;
          }
          .dot {
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: #777;
            @include flexCenter(row);
            margin: 0px 5px 0px 5px;
          }
        }
      }
      .stats {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        width: 100%;

        p {
          position: relative;
          font-size: 14px;
          color: #777;
          display: flex;
          align-items: center;

          span.dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $darkRed;
            margin-left: 5px;
            margin-right: 5px;
          }
        }
        .visits {
          flex: 1;
        }
      }
    }

    .col-2 {
      @include flexCenter(row);
      margin-top: 12px;
      padding: 0px;

      form {
        @include flexCenter(row);
        width: 100%;

        button {
          padding: 8px 20px;
          width: 100%;
          margin-left: 0px;
          cursor: pointer;
          background-color: $darkRed;
          border: none;
          outline: none;
          color: #fff;
          border-radius: 5px;
          transition: all 120ms ease-in-out;
          font-size: 16px;
          @media screen and (max-width: 600px) {
            padding: 8px 12px;
            font-size: 14px;
          }

          &:hover {
            background-color: $lightRed;
            color: #222;
          }
        }
        .unfollow {
          background-color: transparent;
          color: #777;
          border: 1px solid #bbb;
          &:hover {
            background-color: #ddd;
            color: #777;
          }
        }
      }
    }
  }
}
