.register-login {
  form {
    input[type="file"] {
      @include flexCenter(row);
      display: none;
    }
    textarea {
      @include inputs();
      border: 3px solid $darkRed;
      resize: none;
      height: 100px;
    }
    label {
      @include flexCenter(row);
      width: 100%;
      height: 45px;
      margin-top: 10px;
      border: 1px solid #bbb;
      font-size: 18px;
      outline: none;
      cursor: pointer;
      color: #777;
      border-radius: 5px;
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
      @media screen and (max-width: 350px) {
        font-size: 12px;
      }
    }
  }
}
