// Variables
@import "./scss-components/abstracts/vars";
// Mixins
@import "./scss-components/abstracts/mixins";

// Basic styles

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: $grad;
  font-family: "Montserrat", sans-serif;
  @include flexCenter(column);
}

#root {
  width: 100%;
  height: 100%;
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 0px;
    @include screenWidth();

    //   Spinner
    @import "./scss-components/assets/in-spinner";

    // Spinner
    @import "./scss-components/assets/spinner";

    //   Popup
    @import "./scss-components/assets/popup";

    // Slide  Popup
    @import "./scss-components/assets/slide-popup";

    // Subscription
    @import "./scss-components/assets/sub";

    //   Messages
    @import "./scss-components/assets/msg";

    //   404 error
    @import "./scss-components/static/404";

    //   Navigation
    @import "./scss-components/assets/nav";

    @import "./scss-components/assets/search";

    // Forms
    @import "./scss-components/assets/forms";

    //   FAQ/Help
    @import "./scss-components/static/help";

    //   Welcome
    @import "./scss-components/home/welcome";

    //   About
    @import "./scss-components/static/about";

    // Footer
    @import "./scss-components/static/footer";

    // Shops
    @import "./scss-components/shop/shops";

    // Chat box
    @import "./scss-components/assets/chatBox";

    // Chat widget
    @import "./scss-components/static/chat";

    // Bottom navigation
    @import "./scss-components/assets/botton-nav";

    @import "./scss-components/assets/mobile-navLinks";

    // Shop things
    @import "./scss-components/shop/shop";

    // Float button
    @import "./scss-components/assets/float-button";

    // Products
    @import "./scss-components/shop/products";

    // Cart
    @import "./scss-components/shop/cart";

    // Admin
    @import "./scss-components/admin/admin";

    // Employee
    @import "./scss-components/employee/employee";

    //   Tile
    @import "./scss-components/shop/tile-container";
  }
}
