// Top Products
.top-products {
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 570px) {
    padding-top: 24px;
  }

  .heading {
    position: relative;
    display: flex;
    justify-content: center;

    span {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 0px);
      width: 120px;
      background-color: $lightRed;
      height: 10px;
    }
    h1 {
      font-size: 60px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 1300px) {
        font-size: 48px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 40px;
        text-align: center;
      }

      @media screen and (max-width: 650px) {
        font-size: 30px;
      }
      @media screen and (max-width: 260px) {
        font-size: 20px;
      }
    }
  }

  // Product card container
  .prods-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    margin-top: 40px;
    @media screen and (min-width: 800px) {
      margin-top: 60px;
    }

    .heading {
      padding: 18px 10px;
      width: 100%;
      border: 1px solid rgb(233, 229, 228);
      h2 {
        color: #777;
        font-weight: 500;
        font-size: 24px;
      }
    }

    .more {
      border: 1px solid #bbb;
      color: $darkRed;
      padding: 10px 36px;
      margin-top: 30px;
      text-decoration: none;
      width: fit-content;
      margin: 30px auto auto auto;
    }

    //    Product card container
    @import "../assets/product-card-container";
  }
}
