.shopname {
  @include flexCenter(row);
  padding: 0px 24px;
  min-height: 94vh;
  width: 100%;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 0px;
  }

  .shopname-container {
    @include mainWidth();
    @include flexCenter(column);
    align-items: flex-start;
    height: fit-content;
    background-color: transparent;

    .shop-description {
      background-color: #fff;
      padding: 10px;
      margin-bottom: 50px;
      width: 100%;

      h3 {
        color: #777;
        font-style: 24px;
        @media screen and (max-width: 600px) {
          font-size: 20px;
        }
      }
      p {
        margin-top: 8px;
      }
    }

    .shop {
      position: relative;
      background: transparent;
      height: 200px;
      width: 100%;
      @media screen and (max-width: 900px) {
        height: 100px;
      }
      @media screen and (max-width: 600px) {
        margin-top: -10px;
      }

      .opacity {
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(255, 184, 166, 0.8),
          rgba(255, 122, 90, 0.8)
        );
        height: 100%;
        width: 100%;
        z-index: 20;
        @include flexCenter(column);
        border-radius: 0px 0px 10px 10px;

        h1 {
          display: block;
          white-space: nowrap;
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          font-size: 40px;
          color: #fff;
          @media screen and (max-width: 900px) {
            font-size: 30px;
          }
          @media screen and (max-width: 600px) {
            font-size: 26px;
          }
        }
        p {
          color: #fff;
          font-weight: bold;
          margin-top: 10px;
          font-size: 20px;
          display: block;
          white-space: nowrap;
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          @media screen and (max-width: 900px) {
            font-size: 17px;
          }
          @media screen and (max-width: 600px) {
            font-size: 14px;
            margin-top: 6px;
          }
        }
      }

      .shop-img {
        position: relative;
        width: 100%;
        height: 200px;
        background: transparent;
        border-radius: 50px;
        margin-top: 60px;
        @include flexCenter(column);

        img {
          width: 50%;
          height: 100%;
        }
      }
    }

    .shop-info {
      width: 100%;
      height: fit-content;
      background: linear-gradient(
        to bottom,
        rgba(255, 184, 166, 0.8),
        rgba(255, 122, 90, 0.8)
      );
      @include flexCenter(column);
      padding: 10px;
      border-radius: 0px 0px 99px 99px;
      z-index: 70;
      @media screen and (max-width: 900px) {
        border-radius: 0px 0px 50px 50px;
      }

      // @include cardShadow();
      // &:hover {
      //   box-shadow: none;
      // }

      h2 {
        font-size: 18px;
        color: #555;
        display: block;
        white-space: nowrap;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      p {
        font-size: 14px;
        margin-top: 5px;
        color: #555;
        display: block;
        white-space: nowrap;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        @media screen and (max-width: 900px) {
          width: 80%;
        }
      }
    }

    // Product container
    .products-container {
      background-color: transparent;
      min-height: 94vh;
      width: 100%;
      flex: 1;
      padding: 0px;

      // Main Heading
      @import "../assets/heading";

      // Product card container
      .prod-card-container {
        width: 100%;
        // Sub Heading
        .sub-heading {
          font-size: 18px;
          color: #bbb;
          font-weight: 500;
        }
      }

      // Flash messages
      .msg {
        @include flexCenter(column);
        margin-top: 10px;
        .warning-msg {
          width: fit-content;
          padding: 6px 15px;
          border-radius: 5px;
          background-color: rgb(255, 227, 168);
          border-left: 3px solid rgb(185, 127, 0);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
          margin-bottom: 12px;
          p {
            font-size: 13px;
            font-weight: bold;
            color: rgb(185, 127, 0);
          }
        }
      }
      .msg {
        @include flexCenter(column);
        margin-top: 10px;
        .error-msg {
          width: fit-content;
          padding: 6px 15px;
          border-radius: 5px;
          background-color: rgb(255, 206, 206);
          border-left: 3px solid red;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
          margin-bottom: 12px;
          p {
            font-size: 13px;
            font-weight: bold;
            color: red;
          }
        }
      }
      .msg {
        @include flexCenter(column);
        margin-top: 10px;
        .success-msg {
          width: fit-content;
          padding: 6px 15px;
          border-radius: 5px;
          background-color: rgb(200, 255, 200);
          border-left: 3px solid green;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
          margin-bottom: 12px;
          p {
            font-size: 13px;
            font-weight: bold;
            color: green;
          }
        }
      }

      // Product card container
      @import "../assets/product-card-container";

      .no-pagination {
        margin-bottom: 50px;
      }
      .pagination {
        margin-bottom: 20px;
      }

      // Next back buttons
      @import "../assets/nextback";
    }
  }
}
