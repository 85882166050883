.pricing-container {
  padding: 100px 10px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    padding: 60px 10px;
  }

  #intro-head {
    font-size: 20px;
    font-weight: 500;
    color: #555;
    margin-bottom: 10px;
  }

  .heading {
    position: relative;
    display: flex;
    justify-content: center;

    span {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 0px);
      width: 120px;
      background-color: $lightRed;
      height: 10px;
    }
    h1 {
      font-size: 60px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 1300px) {
        font-size: 48px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 40px;
        text-align: center;
      }

      @media screen and (max-width: 650px) {
        font-size: 30px;
      }
      @media screen and (max-width: 260px) {
        font-size: 20px;
      }
    }
  }

  .pricing-content {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
    @media screen and (max-width: 800px) {
      margin-top: 40px;
    }
    .card {
      width: 300px;
      // border: 1px solid #bbb;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 15px;
      box-shadow: 0px 1px 3px rgba(124, 107, 101, 0.5);
      border-radius: 5px;
      background-color: #fff;
      @media screen and (max-width: 800px) {
        margin: 10px;
      }

      @media screen and (max-width: 330px) {
        width: 100%;
      }

      h2 {
        font-size: 36px;
        font-weight: 500;
        align-self: center;
      }

      p {
        font-style: 16px;
      }
      .price {
        margin-top: 10px;
        color: $darkRed;
        align-self: center;
      }
      .includes {
        margin-top: 20px;
      }

      ul {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        li {
          margin-left: 20px;
          color: #777;
          &:first-child {
            margin-top: 10px;
          }
        }
      }

      a {
        margin-top: 50px;
        @include links();
        @include buttonHover();
        padding: 12px 40px;
        text-align: center;
        background-color: $darkRed;
        color: #fff;
        font-size: 16px;

        @media screen and (max-width: 900px) {
          padding: 12px 36px;
        }

        @include cardShadow();
      }
    }
  }
}
