// Loader
.loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 101;
  @include flexCenter(column);

  .circle {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 4px solid $superDarkRed;
    border-top-color: $darkRed;
    border-radius: 50%;
    animation: spin1 1.2s linear infinite;

    @keyframes spin1 {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
