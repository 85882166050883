.register-login {
  width: 100%;
  min-height: 93vh;
  @include flexCenter(column);
  justify-content: flex-start;
  padding: 0px 10px;

  .form {
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.35);
    border-radius: 36px;
    width: 30%;
    margin-top: 10px;
    margin-bottom: 40px;
    @include shadows();
    @media screen and (max-width: 1500px) {
      width: 40%;
    }
    @media screen and (max-width: 1200px) {
      width: 50%;
    }
    @media screen and (max-width: 900px) {
      width: 60%;
    }
    @media screen and (max-width: 700px) {
      width: 70%;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    @media screen and (max-width: 260px) {
      padding: 15px;
    }

    // Package Image in order page
    img {
      width: 200px;
      width: 200px;
      margin-top: 12px;
    }

    // Navigation
    .account-nav {
      width: 100%;
      height: 45px;
      background: #ffffff;
      box-shadow: 1px 3px 24px rgba(0, 0, 0, 0.3);
      // @include cardShadow();
      border-radius: 36px;
      @include flexCenter(row);
      margin-bottom: 20px;

      a {
        width: 50%;
        height: 45px;
        background: #ffffff;
        border-radius: 36px;

        &:hover {
          background-color: #eee;
          text-decoration: none;
          color: $darkRed;
        }
      }
      .active {
        background-color: $darkRed;
        color: #fff;
        &:hover {
          color: $darkRed;
        }
        // box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.25);
      }
    }

    .heading {
      @include flexCenter(column);
      .avatar {
        width: 50px;
        height: 50px;
        margin-bottom: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      h1 {
        font-size: 24px;
        font-weight: 520;
        margin-bottom: 18px;
        @media screen and (max-width: 720px) {
          font-size: 21px;
        }
      }
    }

    // Form section
    form {
      @include flexCenter(column);
      .msg {
        @include flexCenter(column);
        .success-msg {
          width: fit-content;
          padding: 6px 15px;
          border-radius: 5px;
          background-color: rgb(200, 255, 200);
          border-left: 3px solid green;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
          margin-bottom: 12px;
          p {
            font-size: 13px;
            font-weight: bold;
            color: green;
          }
        }
      }
      .msg {
        @include flexCenter(column);
        .warning-msg {
          width: fit-content;
          padding: 6px 15px;
          border-radius: 5px;
          background-color: rgb(255, 227, 168);
          border-left: 3px solid rgb(185, 127, 0);
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
          margin-bottom: 12px;
          p {
            font-size: 13px;
            font-weight: bold;
            color: rgb(185, 127, 0);
          }
        }
      }
      .msg {
        @include flexCenter(column);
        .error-msg {
          width: fit-content;
          padding: 6px 15px;
          border-radius: 5px;
          background-color: rgb(255, 206, 206);
          border-left: 3px solid red;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
          margin-bottom: 12px;
          p {
            font-size: 13px;
            font-weight: bold;
            color: red;
          }
        }
      }
      input {
        @include inputs();
      }
      select {
        @include inputs();
      }
      label {
        width: 100%;
        text-align: center;
        @include flexCenter(row);
        flex-wrap: wrap;
        height: fit-content;
        padding: 5px;
        span {
          @include flexCenter(row);
          border: 1px solid $darkRed;
          border-radius: 5px;
          padding: 3px;
          margin: 3px;
          color: $darkRed;
        }
        .delete {
          @include flexCenter(row);
          width: 18px;
          height: 18px;
          border-radius: 50%;
          font-size: 10px;
          font-weight: bold;
          background-color: $err;
          color: #fff;
        }
        i {
          margin-right: 8px;
        }
      }

      // Drag and Drop on the label
      .drag-drop {
        height: 80%;
        @include flexCenter(column);
        cursor: pointer;
        background-color: #eee;
        border: 3px dotted $darkRed;
        width: 100%;
        padding: 10px;
        i {
          margin-bottom: 10px;
          font-size: 3em;
          color: $darkRed;
          pointer-events: none;
        }
      }

      .autocomplete {
        @include shadows();
        width: 100%;
        ul {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          width: 100%;

          li {
            width: 100%;
            list-style: none;
            padding: 10px;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background-color: $lightRed;
            }
          }
        }
      }
      .passwords {
        @include flexCenter(row);
        width: 100%;
        input {
          width: 100%;
          flex: 1;
        }
        i {
          width: 0%;
          transform: translate(-24px);
          font-size: 16px;
          color: #999;

          &:hover {
            color: $darkRed;
          }
        }
      }
      button {
        @include buttons();
        color: #fff;
        background-color: $darkRed;
        cursor: pointer;
        @include buttonHover();
        @include flexCenter(column);
        transition: all 300ms ease-in-out;
      }
      // Button animation on submit
      .btn-animate {
        background-color: #ddd;
        color: #bbb;
        animation: animate 1.25s alternate infinite;
        border: 3px solid #ddd;
        pointer-events: none;

        @keyframes animate {
          0% {
            border: 3px solid #ccc;
            background-color: #ccc;
            color: #aaa;
          }
          100% {
            border: 3px solid #ddd;
            background-color: #ddd;
          }
        }
      }
      .dry {
        width: 100%;
        background-color: transparent;
        border: 1px solid #bbb;
        color: #777;
        padding: 12px;
        border-radius: 5px;
        &:hover {
          color: #777;
          text-decoration: none;
        }
      }
      #success {
        background-color: $success;
        border: 3px solid $success;
        &:hover {
          background-color: $successHover;
        }
      }

      p {
        width: 100%;
        font-size: 13px;
        text-align: center;
        transition: all 120ms ease-in-out;
        font-weight: bold;
        @media screen and (max-width: 350px) {
          font-size: 11px;
        }
        padding: 5px;
      }
      #err {
        background-color: $errLight;
      }

      .confirm-no {
        width: 100%;
        background-color: transparent;
        border: 1px solid #bbb;
        color: #777;
        padding: 12px;
        border-radius: 5px;
        &:hover {
          color: #777;
          background-color: #eee;
          text-decoration: none;
        }
      }
    }

    // Expanding the form height for uploading
    .expand {
      height: 500px;
      @media screen and (max-width: 1500px) {
        height: 400px;
      }
      @media screen and (max-width: 900px) {
        height: 300px;
      }
      img {
        width: 100%;
        height: 60%;
        @media screen and (max-width: 900px) {
          height: 50%;
        }
      }
    }

    //   Link to login page
    p {
      font-size: 15px;
      color: #555;
      text-align: center;
    }
    a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      transition: all 300ms ease-in-out;
      color: $darkRed;
      @include flexCenter(column);
      @media screen and (max-width: 500px) {
        font-size: 16px;
      }
      @media screen and (max-width: 350px) {
        font-size: 12px;
      }

      &:hover {
        color: $darkRed;
        text-decoration: underline;
      }
    }
    .msg {
      @include flexCenter(column);
      .success-msg {
        width: fit-content;
        padding: 6px 15px;
        border-radius: 5px;
        background-color: rgb(200, 255, 200);
        border-left: 3px solid green;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: 12px;
        p {
          font-size: 13px;
          font-weight: bold;
          color: green;
        }
      }
    }
    .msg {
      @include flexCenter(column);
      .warning-msg {
        width: fit-content;
        padding: 6px 15px;
        border-radius: 5px;
        background-color: rgb(255, 227, 168);
        border-left: 3px solid rgb(185, 127, 0);
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: 12px;
        p {
          font-size: 13px;
          font-weight: bold;
          color: rgb(185, 127, 0);
        }
      }
    }
    .msg {
      @include flexCenter(column);
      .error-msg {
        width: fit-content;
        padding: 6px 15px;
        border-radius: 5px;
        background-color: rgb(255, 206, 206);
        border-left: 3px solid red;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: 12px;
        p {
          font-size: 13px;
          font-weight: bold;
          color: red;
        }
      }
    }
  }

  // Two columns in order page
  .two-col {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;

    .heading {
      width: 100%;
      padding: 10px;
      background-color: #ddd;
      font-size: 14px;
      display: flex;
      text-align: left;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: bold;
      margin-top: 10px;
    }
    h4 {
      font-size: 14px;
      display: flex;
      text-align: left;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: 300;
      color: #555;
      margin-top: 5px;
    }
  }
}

.login {
  margin-top: 10px;
}
