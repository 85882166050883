.search-bar {
  position: fixed;
  top: 63px;
  width: 100%;
  @include flexCenter(row);
  height: fit-content;
  background-color: transparent;
  padding: 0px 24px;
  @media screen and (max-width: 1000px) {
    top: 59px;
  }
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
    top: 70px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
    top: 60px;
  }
  z-index: 100;

  @media screen and (max-width: 900px) {
    display: none;
  }

  .search-container {
    @include flexCenter(row);

    background-color: transparent;
    @include mainWidth();

    form {
      @include flexCenter(row);
      justify-content: flex-end;
      width: 100%;
      input {
        width: 50%;
        padding: 10px;
        border: none;
        outline: none;
        @include shadows();
        // border-radius: 5px;
        border: 2px solid $darkRed;
        font-size: 16px;

        @media screen and (max-width: 1000px) {
          width: 60%;
        }

        @media screen and (max-width: 500px) {
          width: 70%;
        }
        @media screen and (max-width: 400px) {
          width: 75%;
        }
        @media screen and (max-width: 300px) {
          width: 80%;
        }
      }

      button {
        width: 8%;
        padding: 10px;
        background-color: $darkRed;
        border: none;
        border: 2px solid $darkRed;
        cursor: pointer;
        font-size: 16px;
        outline: none;
        @media screen and (max-width: 900px) {
          width: 10%;
        }
        @media screen and (max-width: 400px) {
          width: 14%;
        }
        @media screen and (max-width: 300px) {
          width: 16%;
        }
        i {
          color: #fff;
        }
      }
    }
  }
}
