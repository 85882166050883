.msg {
  margin-top: 10px;
  @include flexCenter(column);

  .warning-msg {
    width: fit-content;
    padding: 6px 15px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    background-color: rgb(255, 227, 168);
    border-left: 3px solid rgb(185, 127, 0);
    margin-bottom: 12px;
    p {
      font-size: 13px;
      font-weight: bold;
      color: rgb(185, 127, 0);
    }
  }
  .error-msg {
    width: fit-content;
    padding: 6px 15px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    background-color: rgb(255, 206, 206);
    border-left: 3px solid red;
    margin-bottom: 12px;
    p {
      font-size: 13px;
      font-weight: bold;
      color: red;
    }
  }
  .success-msg {
    width: fit-content;
    padding: 6px 15px;
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    background-color: rgb(200, 255, 200);
    border-left: 3px solid green;
    margin-bottom: 12px;
    p {
      font-size: 13px;
      font-weight: bold;
      color: green;
    }
  }
}
