// Variables
$darkGreen: #00aaa0;
$lightGreen: #8ed2c9;
$darkRed: #ff7a5a;
$superDarkRed: #53281d;
$lightRed: rgb(255, 223, 214);
$superLightRed: rgb(245, 236, 234);

$darkGreenRGB: rgb(0, 170, 160);
$ligtGreenRGB: rgb(142, 210, 201);
$lightRedRGB: rgb(255, 223, 214);
$darkRedRGB: rgb(255, 122, 90);

// Generic colors
$err: rgb(255, 43, 43);
$errHover: rgba(255, 43, 43, 0.835);
$errLight: rgb(255, 206, 206);
$success: rgb(14, 136, 14);
$successHover: rgba(14, 136, 14, 0.835);
$successLight: rgb(200, 255, 200);
$warn: rgb(185, 127, 0);
$warnHover: rgb(255, 227, 168);

// Gradients
$grad: linear-gradient(113.36deg, #eee 0%, rgba(255, 122, 90, 0.1) 112.98%);
