.in-spinner {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-radius: 50%;
  }

  .one {
    animation: spin2 1.2s linear infinite;
    border-top-color: $darkRed;
  }
  .two {
    animation: spin3 1.2s linear infinite;
    border-bottom-color: $darkRed;
  }

  @keyframes spin2 {
    0% {
      border-width: 8px;
      transform: rotate(0deg);
    }
    50% {
      border-width: 3px;
      transform: rotate(180deg);
    }
    100% {
      border-width: 8px;
      transform: rotate(360deg);
    }
  }
  @keyframes spin3 {
    0% {
      border-width: 3px;
      transform: rotate(0deg);
    }
    50% {
      border-width: 8px;
      transform: rotate(180deg);
    }
    100% {
      border-width: 3px;
      transform: rotate(360deg);
    }
  }
}
