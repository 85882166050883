.carts {
  @include flexCenter(column);
  justify-content: flex-start;
  padding: 0px 24px;
  margin-top: 50px;
  min-height: 94vh;
  width: 100%;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
    margin-top: 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }

  .heading {
    width: 50%;
    @media screen and (max-width: 1500px) {
      width: 70%;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }
    @media screen and (max-width: 600px) {
      width: 70%;
    }
    @media screen and (max-width: 500px) {
      width: 80%;
    }
    @media screen and (max-width: 400px) {
      width: 90%;
    }
    @media screen and (max-width: 350px) {
      width: 100%;
    }

    h1 {
      font-size: 20px;
      color: #555;
      padding: 4px;
      // border-bottom: 1px solid rgba(60, 61, 67, 0.3);
      font-weight: 600;
      @media screen and (max-width: 750px) {
        text-align: center;
      }
    }
  }

  .no-items {
    color: #bbb;
    margin-top: 15px;
  }

  .confirm-decline {
    width: 100%;
    @include flexCenter(column);
    justify-content: flex-start;
    .heading {
      @include flexCenter(column);
      margin-top: 10px;
      width: 50%;
      @media screen and (max-width: 1500px) {
        width: 70%;
      }

      @media screen and (max-width: 800px) {
        width: 90%;
      }
      @media screen and (max-width: 600px) {
        width: 70%;
      }
      @media screen and (max-width: 500px) {
        width: 80%;
      }
      @media screen and (max-width: 400px) {
        width: 90%;
      }
      @media screen and (max-width: 350px) {
        width: 100%;
      }

      h1 {
        font-size: 20px;
        color: #555;
        padding: 4px;
        // border-bottom: 1px solid rgba(60, 61, 67, 0.3);
        font-weight: 600;
        @media screen and (max-width: 750px) {
          text-align: center;
        }
      }
    }

    a {
      @include buttons();
      width: 200px;
      height: 40px;
      text-align: center;
      color: #fff;
      background-color: $darkRed;
      text-decoration: none;
    }
    .no {
      background-color: transparent;
      color: #777;
      border: 1px solid #bbb;
    }
  }

  .msg {
    @include flexCenter(column);
    margin-top: 10px;
    .warning-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 227, 168);
      border-left: 3px solid rgb(185, 127, 0);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: rgb(185, 127, 0);
      }
    }
  }
  .msg {
    @include flexCenter(column);
    margin-top: 10px;
    .error-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(255, 206, 206);
      border-left: 3px solid red;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: red;
      }
    }
  }
  .msg {
    @include flexCenter(column);
    margin-top: 10px;
    .success-msg {
      width: fit-content;
      padding: 6px 15px;
      border-radius: 5px;
      background-color: rgb(200, 255, 200);
      border-left: 3px solid green;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      margin-bottom: 12px;
      p {
        font-size: 13px;
        font-weight: bold;
        color: green;
      }
    }
  }

  .prod-cart-container {
    text-decoration: none;
    color: #000;
    width: 50%;
    height: fit-content;
    padding: 8px;
    background-color: #fff;
    @include cardShadow();
    border-radius: 5px;
    margin-top: 15px;
    transition: all 120ms ease-in-out;
    word-wrap: break-word;
    @include flexCenter(row);
    align-items: flex-start;
    cursor: default;

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
        0 1px 3px 1px rgba(63, 51, 48, 0.15);
    }

    &:last-child {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 1500px) {
      width: 70%;
    }
    @media screen and (max-width: 800px) {
      width: 90%;
    }
    @media screen and (max-width: 750px) {
      flex-direction: column;
      width: 60%;
    }
    @media screen and (max-width: 600px) {
      width: 70%;
      margin-top: 10px;
    }
    @media screen and (max-width: 500px) {
      width: 80%;
    }
    @media screen and (max-width: 400px) {
      width: 90%;
    }
    @media screen and (max-width: 350px) {
      width: 100%;
    }

    .prod-image {
      width: 20%;
      height: 120px;
      background-color: #ddd;
      border-radius: 5px;
      @include flexCenter(column);
      @media screen and (max-width: 750px) {
        width: 100%;
        height: 250px;
        border-radius: 5px 5px 0px 0px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        @media screen and (max-width: 750px) {
          border-radius: 5px 5px 0px 0px;
        }
      }
    }

    .prod-info {
      width: 60%;
      height: fit-content;
      padding: 15px;
      @media screen and (max-width: 750px) {
        width: 100%;
        padding: 10px 0px;
      }

      .name-price {
        width: 100%;
        @include flexCenter(row);
        align-items: flex-start;
        h1 {
          font-size: 18px;
          font-weight: bold;
          display: -webkit-box;
          max-height: calc(2 * 1.6rem);
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          -webkit-line-clamp: 2;
          letter-spacing: normal;
          font-size: 18px;
          line-height: 1.4rem;
          max-height: 4.4rem;
          color: #333;
          padding: 0px 5px 0px 0px;
          flex: 2;
          @media screen and (max-width: 750px) {
            font-size: 16px;
          }
        }
        .price {
          font-size: 18px;
          color: $darkRed;
          flex: 1;
          text-align: right;
          font-weight: bold;
          @media screen and (max-width: 750px) {
            font-size: 16px;
          }
        }
      }

      .shopName {
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #777;
        font-size: 14px;
      }
    }

    .prod-edit {
      width: 20%;
      height: auto;
      align-self: flex-end;
      @include flexCenter(column);
      background-color: transparent;
      position: relative;

      @media screen and (max-width: 750px) {
        width: 100%;
        flex-direction: column;
        margin-top: 10px;
      }

      form {
        width: 100%;
        @include flexCenter(column);

        p {
          width: 100%;
          position: relative;
          @include flexCenter(row);
          font-size: 14px;
          font-weight: bolder;
          margin-bottom: 10px;

          // @media screen and (max-width: 750px) {
          //   justify-content: flex-start;
          // }
          .qty-btn {
            border: none;
            margin-left: 10px;
            width: 60%;
            padding: 0px 4px;
            font-size: 20px;
            color: #fff;
            @include cardShadow();
            @media screen and (max-width: 750px) {
              width: 40%;
            }
            background-color: $darkRed;
            &:hover {
              background-color: $darkRed;
              box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.25);
            }
          }
          span {
            padding: 0px 5px;
            color: #777;
          }
        }

        button {
          width: 100%;
          padding: 6px;
          background: #fff;
          border: 1px solid #999;
          cursor: pointer;
          border-radius: 5px;
          color: #555;
          transition: all 300ms ease-in-out;
          // margin: 4px;
          outline: none;

          &:hover {
            background-color: #eee;
          }
        }
        // .update {
        //   background-color: $darkRed;
        //   border: 1px solid $darkRed;
        //   color: #fff;
        //   &:hover {
        //     background-color: $darkRed;
        //   }
        // }
      }
    }
  }

  .cart-summary {
    text-decoration: none;
    color: #000;
    width: 30%;
    height: fit-content;
    padding: 8px;
    background-color: #fff;
    @include cardShadow();
    border-radius: 5px;
    margin-top: 15px;
    transition: all 120ms ease-in-out;
    word-wrap: break-word;
    @include flexCenter(column);
    align-items: flex-start;
    cursor: default;
    margin-bottom: 50px;

    &:hover {
      box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
        0 1px 3px 1px rgba(63, 51, 48, 0.15);
    }
    @media screen and (max-width: 900px) {
      width: 50%;
    }
    @media screen and (max-width: 750px) {
      width: 60%;
    }
    @media screen and (max-width: 600px) {
      width: 70%;
      margin-top: 10px;
    }
    @media screen and (max-width: 500px) {
      width: 80%;
    }
    @media screen and (max-width: 400px) {
      width: 90%;
    }
    @media screen and (max-width: 350px) {
      width: 100%;
    }

    h1 {
      font-size: 20px;
      font-weight: bolder;
      @media screen and (max-width: 720px) {
        font-size: 16px;
      }
      display: block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #555;
    }

    .total {
      @include flexCenter(row);
      margin-top: 12px;
      width: 100%;
      p {
        flex: 1;
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #555;

        @media screen and (max-width: 500px) {
          font-size: 15px;
        }
      }
      .price {
        font-weight: bolder;
        color: rgb(14, 136, 14);
      }
    }

    a {
      text-decoration: none;
      @include flexCenter(row);
      @include buttons();
      background-color: rgb(14, 136, 14);
      color: #fff;
      cursor: pointer;
      transition: all 120ms ease-in-out;
      border: 3px solid rgb(14, 136, 14);

      &:hover {
        box-shadow: 1px 3px 3px rgba(14, 136, 14, 0.4);
        color: #fff;
      }

      @media screen and (max-width: 500px) {
        font-size: 15px;
      }
    }
  }
}
