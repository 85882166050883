// Playing
.tile-container {
  width: 100%;
  min-height: 83.5vh;
  background-color: transparent;
  margin-bottom: 40px;
  @include flexCenter(column);
  justify-content: flex-start;

  // Heading
  @import "../assets/heading";

  .tile-content {
    @include mainWidth();
    @include flexCenter(row);
    flex-wrap: wrap;
    justify-content: flex-start;
    @media screen and (max-width: 500px) {
      justify-content: center;
    }

    .tile {
      @include flexCenter(column);
      justify-content: flex-start;
      width: 120px;
      text-decoration: none;
      transition: all 300ms ease-in-out;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 10px;
      margin: 10px;
      background-color: #fff;
      @media screen and (max-width: 560px) {
        margin: 6px;
      }

      &:hover {
        background-color: transparent;
      }
      .img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $lightRed;
        @include flexCenter(row);

        p {
          font-size: 20px;
          color: $darkRed;
        }
      }
      .info {
        flex: 1;
        padding: 12px 0px;

        h2 {
          font-size: 15px;
          color: $darkRed;
          text-align: center;
        }
      }
    }
  }
}
