.chat {
  position: sticky;
  top: 0;
  width: 100%;
  //   background: linear-gradient(to bottom, #eee, rgb(255, 122, 90));
  background: linear-gradient(to bottom, #eee, rgba(255, 122, 90, 0.5));
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
  @include flexCenter(row);
  @media screen and (max-width: 900px) {
    display: flex;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 0px;
  }
  height: 100vh;
  // margin-top: 60px;

  z-index: 100;
  overflow: auto;

  .chat-container {
    position: relative;
    @include flexCenter(column);
    justify-content: flex-start;
    height: 80%;
    background-color: #fff;
    width: 50%;
    box-shadow: 0 1px 12px 0 rgba(63, 51, 48, 0.45);
    @media screen and (max-width: 1600px) {
      height: 90%;
    }
    @media screen and (max-width: 1500px) {
      width: 70%;
      height: 100%;
      box-shadow: none;
    }
    @media screen and (max-width: 1200px) {
      width: 80%;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
    }

    .leaving-room {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 90;
      @include flexCenter(column);
      display: none;
      padding: 10px;
      animation: scaling 0.2s linear 1;
      transform-origin: top;
      @keyframes scaling {
        0% {
          transform: scale(0.1);
        }
        100% {
          transform: scale(1);
        }
      }

      h3 {
        justify-self: flex-start;
        color: #fff;
        background-color: $darkRed;
        padding: 10px 24px;
        @include flexCenter(column);
        border-radius: 5px;
      }
      button {
        padding: 10px 24px;
        color: $darkRed;
        font-size: 20px;
        border: none;
        background-color: transparent;
        outline: none;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .chat-nav {
      position: relative;
      width: 100%;
      background-color: $superDarkRed;
      // background-color: #2e130d;
      height: 60px;
      @include flexCenter(row);
      justify-content: flex-start;
      padding: 10px;
      box-shadow: 0 1px 10px 0 rgba(63, 51, 48, 0.9);
      z-index: 85;

      .join {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include flexCenter(row);
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.9);
        display: none;
        z-index: 90;
        animation: scaling 0.2s linear 1;
        transform-origin: top;
        overflow: hidden;
        @keyframes scaling {
          0% {
            transform: scale(0.1);
          }
          100% {
            transform: scale(1);
          }
        }

        h3 {
          flex: 1;
          font-size: 14px;
          color: $darkGreen;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        button {
          font-size: 20px;
          outline: none;
          border: none;
          color: #fff;
          background-color: transparent;
          cursor: pointer;
          padding: 0px 10px;
        }
      }

      .user-img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: #eee;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50px;
        }
      }

      .about-user {
        flex: 1;
        background-color: transparent;
        @include flexCenter(column);
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px 10px;
        overflow: hidden;
        p {
          font-size: 18px;
          color: #fff;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 450;
          @media screen and (min-width: 900px) {
            font-weight: 550;
          }
        }
        .online-offline {
          font-size: 14px;
        }
        .offline {
          color: #fff;
        }
        .online {
          color: rgb(113, 255, 113);
        }
      }

      .chat-menu {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        // background-color: $darkRed;
        background-color: transparent;
        @include flexCenter(column);
        transition: all 300ms ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: #6d4d46;
        }

        p {
          font-size: 16px;
          color: #fff;
          font-weight: 450;
          pointer-events: none;
          @media screen and (min-width: 900px) {
            font-weight: 550;
          }
        }
      }
      .chat-menu-links {
        position: absolute;
        top: 104%;
        right: 3%;
        background-color: #6d4d46;
        box-shadow: 0px 3px 24px rgba(60, 61, 67, 0.9);
        //   width: 330px;
        height: fit-content;
        @include flexCenter(column);
        z-index: 90;
        display: none;
        animation: scaling 0.2s linear 1;
        transform-origin: top;
        @keyframes scaling {
          0% {
            transform: scale(0.1);
          }
          100% {
            transform: scale(1);
          }
        }

        span {
          position: absolute;
          top: -2%;
          right: 4%;
          background-color: #6d4d46;
          width: 30px;
          height: 30px;
          transform: rotate(60deg);
          z-index: 2;
        }
        a {
          padding: 15px 48px 15px 15px;
          color: #eee;
          text-decoration: none;
          background-color: transparent;
          word-wrap: none;
          word-break: keep-all;
          transition: all 300ms ease-in-out;
          z-index: 4;
          // border-radius: 5px;
          text-align: left;
          display: inline-flex;
          width: 100%;
          @include flexCenter(row);
          justify-content: flex-start;

          i {
            margin-right: 10px;
          }

          &:hover {
            background-color: #745f5b;
          }
        }
      }
    }

    .chat-msg-display {
      position: relative;
      width: 100%;
      padding: 10px;
      background-color: #fff;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      z-index: 80;

      &:nth-child(2) {
        border-top: 2px solid #ddd;
      }

      // Scroll bar
      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
        margin-right: 12px;
      }
      &::-webkit-scrollbar-track {
        border: none;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #7066646b;
        width: 2px;
      }

      // Messages
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;

        li {
          position: relative;
          max-width: 80%;
          display: flex;
          flex-direction: column;
          clear: both;
          color: #eee;
          margin-bottom: 6px;
          word-break: break-word;
          padding: 4px;
          font-size: 14px;
          font-weight: 450;
          box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
            0 1px 3px 1px rgba(63, 51, 48, 0.15);
          @media screen and (min-width: 900px) {
            font-weight: 550;
          }
          letter-spacing: 1px;

          h3 {
            position: relative;
            top: 0;
            left: 0;
            font-size: 15px;
            // color: #444;
            color: #eee;
            // @media screen and (max-width: 600px) {
            //   font-size: 15px;
            // }
            z-index: 4;
            width: 100%;
            margin-bottom: 6px;
            padding: 6px 10px 6px 4px;
            background-color: #6d4d46;
            border-radius: 0px 50px 30px 0px;
            border-left: 4px solid $darkRed;
            font-weight: 450;
            @media screen and (min-width: 900px) {
              font-weight: 550;
            }
          }
          p {
            padding: 0px 10px 6px 10px;
          }
          button {
            margin-top: 12px;
            background: red;
            border: none;
            outline: none;
            width: 100%;
            padding: 6px;
            color: #fff;
            border-radius: 5px;
          }
        }
        #me {
          float: right;
          background: $darkRed;
          border-radius: 30px 0px 30px 30px;
          // margin-right: 6px;
          padding: 10px 14px 10px 14px;
        }
        #them {
          float: left;
          background: $superDarkRed;
          border-radius: 0px 30px 30px 30px;
          // margin-left: 6px;
        }
        #them + #me {
          border-top-right-radius: 5px;
        }
        #me + #me {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        #me:last-of-type {
          border-bottom-right-radius: 30px;
        }
      }
    }

    .chat-msg-form {
      width: 100%;
      //   background-color: $superDarkRed;
      background-color: transparent;
      @include flexCenter(row);
      justify-content: flex-start;
      padding: 10px;

      form {
        width: 100%;
        @include flexCenter(row);

        textarea {
          flex: 1;
          height: 50px;
          padding: 16px;
          border-radius: 99px;
          resize: none;
          outline: none;
          border: none;
          background-color: $superDarkRed;
          @include fontFamily();
          color: #fff;
          font-weight: 450;
          font-size: 14px;
          letter-spacing: 1px;
          @media screen and (min-width: 900px) {
            font-weight: 550;
          }

          // Scroll bar
          &::-webkit-scrollbar {
            width: 5px;
            background: transparent;
            margin-right: 12px;
          }
          &::-webkit-scrollbar-track {
            border: none;
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: #7066646b;
            width: 2px;
          }
        }
        button {
          height: 50px;
          width: 50px;
          margin-left: 10px;
          border-radius: 50px;
          outline: none;
          border: none;
          background-color: $darkRed;
          box-shadow: 0 1px 2px 0 rgba(63, 51, 48, 0.3),
            0 1px 3px 1px rgba(63, 51, 48, 0.15);
          color: #fff;
          font-size: 20px;
          font-weight: 550;
          cursor: pointer;
          @media screen and (min-width: 900px) {
            font-weight: 550;
          }

          // .plane {
          //   transform: rotate(50deg);
          // }
        }
      }
    }
  }
}
