.nav-links {
  position: fixed;
  width: 100%;
  top: 0;
  // background: rgba(0, 0, 0, 0.7);
  background-size: cover;
  display: none;
  z-index: 100000;
  height: 100%;
  animation: animate-links 0.3s linear 1;
  overflow: auto;
  @keyframes animate-links {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .nav-links-container {
    position: absolute;
    @include flexCenter(column);
    align-items: flex-start;
    justify-content: flex-start;
    width: 65%;
    background-color: #eee;
    height: auto;
    animation: animateHamMenu 0.3s linear 1;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.8);
    transform-origin: left;

    @keyframes animateHamMenu {
      0% {
        // transform: translate(0, -100%) scaleX(0.1);
        transform: translateX(-100%);
      }
      100% {
        // transform: translate(0%) scaleX(1);
        transform: translateX(0%);
      }
    }
    @media screen and (max-width: 500px) {
      width: 80%;
    }

    .user-account {
      height: 15%;
      width: 100%;
      background: linear-gradient(
        113.36deg,
        #eee 0%,
        rgba(255, 122, 90, 0.1) 112.98%
      );
      @include flexCenter(column);
      align-items: flex-start;
      padding: 10px;
      overflow: hidden;

      .img {
        margin-top: 20px;
        background-color: transparent;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        @media screen and (max-width: 600px) {
          margin-top: 10px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
          border: 2px solid #bbb;
        }
      }

      .account-email {
        width: 100%;
        overflow: hidden;

        p {
          text-decoration: none;
          color: $darkRed;
          display: block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px 0px;
          margin-top: 2px;

          i {
            margin-right: 6px;
          }
        }
      }
    }

    .nav-links-wrapper {
      background-color: #fff;
      width: 100%;
      height: auto;

      a {
        display: block;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        transition: all 300ms ease-in-out;
        color: #777;
        text-align: left;
        margin-top: 10px 0px;
        display: block;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 15px 10px;
        border-bottom: 1px solid #ddd;

        &:last-of-type {
          border-bottom: none;
        }

        &:hover {
          background-color: $lightRed;
        }

        i {
          margin-right: 6px;
        }
      }
    }
    .add-button {
      cursor: pointer;
    }
  }
}
