.load {
  background-color: #ddd;
  color: #ddd;
  margin-top: 5px;
  overflow: hidden;
  &:first-child {
    margin-top: 0px;
  }
  span {
    color: #ddd;
  }
  &:nth-child(2) {
    .load {
      &:nth-child(2) {
        width: 80%;
      }
      &:nth-child(3) {
        h3 {
          color: #ddd;
        }
        width: 40%;
      }
    }
    form {
      button {
        width: 100%;
        padding: 10px 24px;
        background-color: #ddd;
        border: none;
      }
    }
  }
}
.slide-anime {
  background-color: rgba(255, 255, 255, 0.513);
  box-shadow: 24px 3px 36px rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 8%;
  animation: light-slide 2.5s linear infinite;

  @keyframes light-slide {
    0% {
      left: 0%;
      width: 8%;
      background-color: rgba(255, 255, 255, 0.513);
    }
    50% {
      left: 100%;
      width: 5%;
      background-color: rgb(255, 255, 255);
    }
    70% {
      width: 2%;
    }
    100% {
      left: 100%;
      width: 2%;
    }
  }
}
