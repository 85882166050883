.category-float {
  background-color: transparent;
  position: fixed;
  width: 100%;
  @media screen and (max-width: 700px) {
    bottom: 16px;
  }

  height: fit-content;
  @include flexCenter(row);
  display: none;
  justify-content: flex-end;
  padding: 0px 24px;
  @media screen and (max-width: 700px) {
    padding: 0px 16px;
    display: flex;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
    bottom: 10px;
  }
  z-index: 111;

  i {
    color: #fff;
    font-size: 18px;
  }

  .openNav {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: $darkRed;
    border: none;
    outline: none;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.3);

    @include flexCenter(column);
    justify-content: space-evenly;
    cursor: pointer;
    span {
      height: 3px;
      width: 12px;
      background-color: #fff;
    }
  }
}
