nav {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  @include flexCenter(row);
  @include navShadow();
  width: 100%;
  padding: 0px 24px;
  z-index: 1000000;

  @media screen and (max-width: 900px) {
    padding: 0px 16px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }

  //   Desktop navigation
  .desktop-nav {
    width: 60%;
    @include flexCenter(row);

    @media screen and (max-width: 900px) {
      display: none;
    }
    @include mainWidth();

    .logo {
      width: fit-content;
      background: transparent;
      @include flexCenter(row);
      justify-content: flex-start;

      a {
        text-decoration: none;
        @include flexCenter(row);
        font-size: 30px;
        font-weight: 300;
        span {
          margin-left: 4px;
          color: #000;
          @media screen and (max-width: 1200px) {
            display: none;
          }
        }
        img {
          width: 60px;
          height: 50px;
          // @media screen and (max-width: 600px) {
          //   width: 60px;
          // }
        }
      }
    }

    .links {
      flex: 1;
      background-color: transparent;
      @include flexCenter(row);
      justify-content: flex-end;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          display: inline-flex;
          margin: 10px 0px;

          span {
            margin: 10px 4px;
          }

          a {
            position: relative;
            text-decoration: none;
            @include links();
          }
          .inbox {
            background-color: $darkRed;
            border-radius: 50px;
            font-size: 11px;
            font-weight: bold;
            padding: 4px 8px;
            color: #fff;
          }
          .search {
            color: $darkRed;
          }
          .login-navLink {
            @include buttonHover();
            @media screen and (max-width: 1000px) {
              padding: 10px 24px;
            }
          }
        }
      }
    }
  }

  // Mobile Navigation
  .mobile-nav {
    width: 100%;
    @include flexCenter(row);
    @include screenWidth();
    display: none;
    @media screen and (max-width: 900px) {
      display: flex;
    }

    .ham-nav {
      width: 33.3%;
      @include flexCenter(row);
      justify-content: flex-start;
      background-color: transparent;

      .openNav {
        display: inline-block;
        cursor: pointer;
        .bar1,
        .bar2,
        .bar3 {
          background: $darkRed;
          width: 35px;
          height: 5px;
          margin: 6px 0;
          transition: 0.4s;
        }
      }

      .closeNav {
        display: block;
        cursor: pointer;
        .bar1,
        .bar2,
        .bar3 {
          width: 35px;
          height: 5px;
          margin: 6px 0;
          transition: 0.4s;
        }
        .bar1 {
          background: $darkRed;
          -webkit-transform: rotate(-45deg) translate(-9px, 6px);
          transform: rotate(-45deg) translate(-9px, 6px);
        }
        .bar2 {
          background: $darkRed;
          opacity: 0;
        }
        .bar3 {
          background: $darkRed;
          -webkit-transform: rotate(45deg) translate(-8px, -8px);
          transform: rotate(45deg) translate(-8px, -8px);
        }
      }
    }

    .logo {
      width: 33.3%;
      background: transparent;
      @include flexCenter(row);
      // justify-content: flex-start;

      a {
        text-decoration: none;
        @include flexCenter(row);
        font-size: 30px;
        font-weight: 700;
        @include links();
        @media screen and (max-width: 600px) {
          font-size: 24px;
        }
        span {
          margin-left: 4px;
          color: #000;
        }
        img {
          width: 60px;
          height: 50px;
          @media screen and (max-width: 600px) {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .search {
      background-color: transparent;
      width: 33.3%;
      @include flexCenter(row);
      justify-content: flex-end;

      button {
        border: none;
        outline: none;
        color: $darkRed;
        padding: 5px;
        font-size: 24px;
        // margin-right: 10px;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .search-form {
      position: absolute;
      left: 0;
      background-color: #fff;
      width: 100%;
      height: 100%;
      @include flexCenter(row);
      padding: 0px 10px;
      display: none;

      animation: scaling 0.1s linear 1;
      @keyframes scaling {
        0% {
          transform: scaleY(0);
        }
        100% {
          transform: scaleY(1);
        }
      }

      .close-search {
        @include flexCenter(row);
        width: 10%;
        padding: 10px;
        outline: none;
        border: none;
        color: #777;
        font-weight: bolder;
        background-color: #fff;
        font-size: 16px;
        cursor: pointer;
      }

      form {
        width: 100%;
        @include flexCenter(row);

        input {
          width: 100%;
          padding: 10px 35px 10px 10px;
          background-color: #eee;
          outline: none;
          border: none;
          border-radius: 99px;
          font-size: 15px;
        }
        button {
          position: absolute;
          right: 10px;
          @include flexCenter(row);
          padding: 10px 8px;
          outline: none;
          border: none;
          color: #777;
          background-color: transparent;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
