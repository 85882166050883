.slide-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11111111111;
  height: fit-content;
  animation: scaling 0.3s linear 1;
  transform-origin: top;
  @keyframes scaling {
    0% {
      transform: scaleY(0.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
  @include flexCenter(row);

  .content {
    padding: 12px 16px;
    background: $superDarkRed;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 30%;
    height: fit-content;
    @include shadows();
    display: block;
    @media screen and (max-width: 1300px) {
      width: 40%;
    }
    @media screen and (max-width: 900px) {
      width: 60%;
    }
    @media screen and (max-width: 700px) {
      width: 70%;
    }
    @media screen and (max-width: 600px) {
      width: 90%;
    }
    //   @media screen and (max-width: 480px) {
    //     width: 100%;
    //   }
    @media screen and (max-width: 260px) {
      padding: 15px;
    }

    overflow: auto;

    h2 {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      @include flexCenter(row);
      color: #fff;

      i {
        margin-right: 10px;
        color: $darkRed;
        font-size: 26px;
      }
    }
  }

  .close {
    padding: 12px 20px;
    background-color: green;
    span {
      color: #fff;
      background-color: $darkRed;
      cursor: pointer;
      @include buttonHover();
      @include flexCenter(column);
      transition: all 300ms ease-in-out;
    }
  }
}
