.bottom-nav {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 12px 36px rgba(0, 0, 0, 0.25);
  @include flexCenter(row);
  display: none;
  padding: 0px 24px;
  @media screen and (max-width: 900px) {
    padding: 0px 16px;
  }
  @media screen and (max-width: 600px) {
    padding: 0px 10px;
  }

  @media screen and (max-width: 900px) {
    display: flex;
  }
  z-index: 100;

  .nav-container {
    width: 40%;
    @include flexCenter(row);
    justify-content: space-evenly;
    background-color: transparent;

    @media screen and (max-width: 280px) {
      width: 35%;
    }

    a {
      text-decoration: none;
      border: none;
      outline: none;
      color: #999;
      padding: 5px 20px;
      @media screen and (max-width: 300px) {
        padding: 5px 10px;
      }
      @include flexCenter(column);
      background-color: transparent;
      p {
        font-size: 10px;
      }

      i {
        font-size: 15px;
        padding: 6px 0px;
      }
    }

    .inbox {
      position: absolute;
      top: -12px;
      background-color: $darkRed;
      border-radius: 50px;
      font-size: 11px;
      font-weight: bold;
      padding: 4px 8px;
      color: #fff;
    }

    .active {
      color: $darkRed;
    }
  }

  .mini-menu {
    position: absolute;
    right: 10px;
    top: -80%;
    background-color: #777;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.7);
    width: 200px;
    border-radius: 5px;
    z-index: 100;
    display: none;
    animation: scaling 0.1s linear 1;
    @keyframes scaling {
      0% {
        transform: scaleY(0);
      }
      100% {
        transform: scaleY(1);
      }
    }

    a {
      display: block;
      text-align: center;
      text-decoration: none;
      color: #ddd;
      padding: 10px;
      z-index: 100;
      border-bottom: 1px solid #999;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        color: $darkRed;
      }

      i {
        margin-right: 6px;
      }
    }
  }

  .products-results {
    top: -150%;
  }

  .button-container {
    @include flexCenter(row);
    background-color: transparent;
    // width: 20%;
    // @media screen and (max-width: 280px) {
    //   width: 30%;
    // }
    flex: 1;

    .float-button {
      transform: translate(0, -50%);
      text-decoration: none;
      // width: 50px;
      // height: 50px;
      padding: 10px 20px;
      border-radius: 50px;
      background-color: $darkRed;
      border: none;
      outline: none;
      box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.35);

      @include flexCenter(column);
      justify-content: space-evenly;
      cursor: pointer;

      @media screen and (max-width: 240px) {
        padding: 5px 15px;
      }

      .cart {
        @include flexCenter(column);
        align-items: space-evenly;
        justify-content: space-evenly;
        i {
          color: #fff;
          font-size: 16px;
          @media screen and (max-width: 280px) {
            font-size: 15px;
          }
        }

        .cart-num {
          color: #fff;
          font-size: 11px;
        }
      }
    }
  }
}
