.admin-nav {
  position: sticky;
  top: 0px;
  background-color: #fff;
  width: 100%;
  height: fit-content;
  @include flexCenter(row);
  z-index: 100;
  border-bottom: 1px solid #ddd;
  .nav-container {
    width: 60%;
    @include flexCenter(row);
    background-color: transparent;
    height: 100%;

    @media screen and (max-width: 1200px) {
      width: 80%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    a {
      flex-basis: 33.3%;
      display: inline-flex;
      margin: 0px 15px;
      text-decoration: none;
      color: #bbb;
      padding: 10px;
      @include flexCenter(row);
      text-align: center;
      font-size: 24px;
      transition: all 120ms ease-in-out;

      @media screen and (max-width: 800px) {
        font-size: 20px;
      }

      @media screen and (max-width: 500px) {
        font-size: 16px;
        font-weight: bold;
      }

      @media screen and (max-width: 600px) {
        font-weight: 600;
        padding: 10px 0px;
      }

      &:hover {
        background-color: rgb(246, 243, 241);
      }
    }
    .active {
      color: $darkRed;
      border-bottom: 3px solid $darkRed;
    }
  }
}
