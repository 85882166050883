.heading {
  margin-top: 12px;
  width: 100%;
  background-color: $lightRed;
  padding: 5px;
  border-radius: 5px;
  border-left: 5px solid $darkRed;

  @media screen and (max-width: 600px) {
    border-radius: 0px;
  }

  h1 {
    font-size: 20px;
    color: $darkRed;
    padding: 4px;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 720px) {
      font-size: 18px;
    }
  }
}
